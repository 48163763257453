import React, { useState } from "react";
import { IconButton, Button } from "@mui/material";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import HomeIcon from "@mui/icons-material/Home";

const stages = [
  { label: "GS", icon: null, active: false },
  { label: "At Restaurant", icon: <FastfoodIcon />, active: false },
  { label: "En Route", icon: <DirectionsCarIcon />, active: false },
  { label: "Delivered", icon: <HomeIcon />, active: false },
];

const OrderStatusSlider = () => {
  const [activeStage, setActiveStage] = useState(0);

  const handleNextStage = () => {
    setActiveStage((prevStage) =>
      prevStage < stages.length ? prevStage + 1 : prevStage
    );
  };

  const isStageActive = (index) => {
    return index <= activeStage;
  };

  const isLineActive = (index) => {
    return index < activeStage;
  };
  const line = (index) => {
    const halfFilled = (
      <div style={{ width: "50px", display: "flex", alignItems: "center" }}>
        <hr
          style={{
            width: "50%",
            borderWidth: "2px",
            borderColor: "black",
            margin: 0,
          }}
        />{" "}
        <hr
          style={{
            width: "50%",
            borderWidth: "2px",
            borderColor: "lightgrey",
            margin: 0,
          }}
        />
      </div>
    );
    const filled = (
      <div style={{ width: "50px", display: "flex", alignItems: "center" }}>
        <hr
          style={{
            width: "50%",
            borderWidth: "2px",
            borderColor: "black",
            margin: 0,
          }}
        />{" "}
        <hr
          style={{
            width: "50%",
            borderWidth: "2px",
            borderColor: "black",
            margin: 0,
          }}
        />
      </div>
    );
    const unfilled = (
      <div style={{ width: "50px", display: "flex", alignItems: "center" }}>
        <hr
          style={{
            width: "50%",
            borderWidth: "2px",
            borderColor: "lightgrey",
            margin: 0,
          }}
        />{" "}
        <hr
          style={{
            width: "50%",
            borderWidth: "2px",
            borderColor: "lightgrey",
            margin: 0,
          }}
        />
      </div>
    );
    //console.log("index: activeStage: ", index, activeStage);
    if (index === activeStage + 1) {
      return halfFilled;
    }
    if (index < activeStage + 1) {
      return filled;
    }
    return unfilled;
  };
  //console.log(activeStage);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "2rem",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {stages.map((stage, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div
                style={{ width: "50px", display: "flex", alignItems: "center" }}
              >
                {line(index)}
              </div>
            )}
            <IconButton
              style={{
                color: isStageActive(index) ? "black" : "lightgrey",
              }}
            >
              {stage.icon || (
                <span
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                  }}
                >
                  {stage.label}
                </span>
              )}
            </IconButton>
          </React.Fragment>
        ))}
      </div>
      <Button
        variant="contained"
        onClick={handleNextStage}
        style={{ marginTop: "2rem" }}
      >
        Next Stage
      </Button>
    </div>
  );
};

export default OrderStatusSlider;
