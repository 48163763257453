import React, { useState } from "react";

const InputField = ({ label, placeholder, type, password, height, width }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="mb-3">
      {!password ? (
        <span className="flex flex-col space-y-2">
          <label className="font-bold">{label}</label>
          <input
            style={{ height, width }}
            type={type}
            className={`bg-[#F7F7F7] p-2 rounded-md text-[#606060] xl:h-${height} xl:w-${width}`}
            placeholder={placeholder}
          />
        </span>
      ) : (
        <span className="flex flex-col space-y-2">
          <span className="flex justify-between">
            <label className="font-bold">{label}</label>
            <span className="text-sm text-gray-text font-medium">
              At least 10 characters
            </span>
          </span>
          <div className="relative bg-[#F7F7F7] rounded-md">
            <input
              type={showPassword ? "text" : "password"}
              className="p-2 bg-transparent active:bg-transparent active:outline-none focus:outline-none focus:bg-transparent text-[#606060]"
              placeholder={placeholder}
            />

            {showPassword ? (
              <span
                className="absolute right-2 top-2 cursor-pointer font-bold"
                onClick={() => setShowPassword(false)}
              >
                Hide
              </span>
            ) : (
              <span
                className="absolute right-2 top-2 cursor-pointer font-bold"
                onClick={() => setShowPassword(true)}
              >
                Show
              </span>
            )}
          </div>
        </span>
      )}
    </div>
  );
};

export default InputField;
