import {
  Button,
  TextField,
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material/";

const OptionPopup = ({
  title,
  description,
  acceptTxt,
  rejectTxt,
  onAccept,
  onReject,
  setIsModal,
}) => {
  return (
    <div>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
          <Button
            onClick={() => {
              onAccept();
            }}
          >
            {acceptTxt === undefined ? "Confirm" : acceptTxt}
          </Button>
          <Button
            onClick={() => {
              if (onReject != undefined) {
                onReject();
              }
              setIsModal(false);
            }}
          >
            {rejectTxt === undefined ? "Cancel" : rejectTxt}
          </Button>
        </Paper>
      </Modal>
    </div>
  );
};
export default OptionPopup;
