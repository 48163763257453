import { Link, json } from "react-router-dom";
import { useState, useEffect } from "react";
import { Paper, Button } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createSvgIcon } from "@mui/material/utils";
import { v4 as uuidv4 } from "uuid";

import { AddCategory } from "./AddCategory";
import { MenuCategory } from "./MenuCategory";

export function MenuEditor({ menu, setMenu }) {
  return (
    <div>{menu ? <MenuRenderer menu={menu} setMenu={setMenu} /> : null}</div>
  );
}
function MenuRenderer({ menu, setMenu }) {
  const s = {
    container: {},
    paperContainer: {
      padding: 20,
      backgroundColor: "#EEF4F1",
      textAlign: "center",
      marginBottom: "0%",
    },
  };
  const addCategory = (input) => {
    const categoryObj = {
      id: uuidv4(),
      categoryName: input,
      items: [],
    };
    menu.categories.push(categoryObj);
    setMenu({
      menuId: menu.menuId,
      menuTitle: menu.menuTitle,
      categories: menu.categories,
    });
  };
  const updateCategory = (e) => {
    let newMenu = { ...menu };

    // find the index of the category with the matching id
    const index = newMenu.categories.findIndex(
      (category) => category.id === e.id
    );

    if (index !== -1) {
      // replace the category at the found index with the new category
      newMenu.categories[index] = e;
      setMenu(newMenu);
    }
  };
  const deleteCategory = (index) => {
    let newMenu = { ...menu };
    newMenu.categories = newMenu.categories.filter((_, i) => i !== index);
    setMenu(newMenu);
  };
  const setTitle = (e) => {
    console.log("E is " + e);
    console.log("menu is" + JSON.stringify(menu));
    setMenu({ menuId: menu.menuId, menuTitle: e, categories: menu.categories });
  };
  return (
    <Paper style={s.paperContainer}>
      <Grid container spacing={1} columns={16}>
        <MenuTitle title={menu.menuTitle} setTitle={(e) => setTitle(e)} />
        {menu.categories.map((category, index) => {
          return (
            <MenuCategory
              key={category.id}
              category={category}
              updateCategory={(e) => updateCategory(e)}
              deleteCategory={() => deleteCategory(index)}
            />
          );
        })}
      </Grid>

      <AddCategory addCategory={addCategory} />
    </Paper>
  );
}
function MenuTitle({ title, setTitle }) {
  const s = {
    box: {
      backgroundColor: "#6de3a9",
      width: "100%",
      height: "100%",
    },
    innerTextField: {
      width: "93%",
      height: "93%",
      border: 0,
      borderRadius: "5px",
      outline: "none",
    },
    categoryItem: {
      marginTop: 8,
      width: "100%",
    },
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [isClicked, setIsClicked] = useState(false);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      const obj = document.getElementById("titleTag").value;
      setIsClicked(false);
      setTitle(obj);
    }
  };
  return (
    <Box style={s.box}>
      <Grid container spacing={1} columns={16} style={s.categoryContainer}>
        <Grid item style={{ paddingLeft: "37.5%" }}></Grid>
        <Grid item style={{ width: "25%" }}>
          <Item style={s.categoryItem} onClick={() => setIsClicked(true)}>
            {isClicked ? (
              <input
                style={s.innerTextField}
                onKeyDown={(e) => handleEnter(e)}
                id="titleTag"
              />
            ) : (
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  border: 0,
                  color: "Black",
                  fontSize: 15,
                  fontWeight: 800,
                }}
              >
                {title}
              </p>
            )}
          </Item>
        </Grid>
        <Grid item style={{ paddingRight: "37.5%" }}></Grid>
      </Grid>
    </Box>
  );
}
