import React, { useState } from "react";
import { TextField, Button, Paper, Grid } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const s = {
  background: {
    backgroundColor: "LightGrey",
    marginLeft: "30%",
    marginRight: "30%",
    padding: 30,
    margin: 50,
    width: "80%",
    height: "80%",
  },
  gridItem: {
    border: 0,
    padding: 5,
    borderRadius: "5px",
    outline: "none",
    backgroundColor: "white",
  },
};
function BusinessForm({ goBack, successfulSubmit, stateProp }) {
  const [formData, setFormData] = useState(
    stateProp == null
      ? {
          restaurantName: "",
          phoneNumber: "",
          restaurantDescription: "",
          streetLine1: "",
          streetLine2: "",
          city: "",
          state: "",
          zipCode: "",
          country: "",
        }
      : stateProp
  );

  const [errors, setErrors] = useState({});

  const validateFormData = () => {
    const errors = {};

    for (let field in formData) {
      if (!formData[field] || formData[field].trim() === "") {
        errors[field] = `Input Required`;
      }
    }

    setErrors(errors);
    return Object.keys(errors).length === 0; // return true if no errors
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateFormData()) {
      console.log("Data Submitted:", formData);
      successfulSubmit(formData);
    }
  };

  return (
    <Paper style={s.background}>
      <form noValidate autoComplete="off">
        <Grid container spacing={3}>
          {/* Business Name */}
          <Grid item xs={12}>
            <TextField
              style={s.gridItem}
              fullWidth
              size="small"
              label="Restaurant Name"
              variant="standard"
              rows={4}
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: 10,
                  fontSize: 17,
                  color: "grey",
                },
              }}
              value={formData.restaurantName}
              onChange={(e) =>
                setFormData({ ...formData, restaurantName: e.target.value })
              }
              error={Boolean(errors.businessName)}
              helperText={errors.businessName}
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12}>
            <TextField
              style={s.gridItem}
              fullWidth
              size="small"
              label="Phone Number"
              variant="standard"
              rows={4}
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: 10,
                  fontSize: 17,
                  color: "grey",
                },
              }}
              value={formData.phoneNumber}
              onChange={(e) =>
                setFormData({ ...formData, phoneNumber: e.target.value })
              }
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber}
            />
          </Grid>

          {/* Business Description */}
          <Grid item xs={12}>
            <TextField
              style={s.gridItem}
              fullWidth
              multiline
              label="Restaurant Description"
              variant="standard"
              rows={4}
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: 10,
                  paddingTop: 5,
                  fontSize: 17,
                  color: "grey",
                },
              }}
              value={formData.restaurantDescription}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  restaurantDescription: e.target.value,
                })
              }
              error={Boolean(errors.description)}
              helperText={errors.description}
            />
          </Grid>

          {/* Address Fields */}
          <Grid item xs={6}>
            <TextField
              style={s.gridItem}
              fullWidth
              size="small"
              label="Street Line 1"
              variant="standard"
              rows={4}
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: 10,
                  fontSize: 17,
                  color: "grey",
                },
              }}
              value={formData.streetLine1}
              onChange={(e) =>
                setFormData({ ...formData, streetLine1: e.target.value })
              }
              error={Boolean(errors.streetLine1)}
              helperText={errors.streetLine1}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              style={s.gridItem}
              fullWidth
              size="small"
              label="Street Line 2"
              variant="standard"
              rows={4}
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: 10,
                  fontSize: 17,
                  color: "grey",
                },
              }}
              value={formData.streetLine2}
              onChange={(e) =>
                setFormData({ ...formData, streetLine2: e.target.value })
              }
              error={Boolean(errors.streetLine2)}
              helperText={errors.streetLine2}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              style={s.gridItem}
              fullWidth
              size="small"
              label="City"
              variant="standard"
              rows={4}
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: 10,
                  fontSize: 17,
                  color: "grey",
                },
              }}
              value={formData.city}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              error={Boolean(errors.city)}
              helperText={errors.city}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              style={s.gridItem}
              fullWidth
              size="small"
              label="State"
              variant="standard"
              rows={4}
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: 10,
                  fontSize: 17,
                  color: "grey",
                },
              }}
              value={formData.state}
              onChange={(e) =>
                setFormData({ ...formData, state: e.target.value })
              }
              error={Boolean(errors.state)}
              helperText={errors.state}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              style={s.gridItem}
              fullWidth
              size="small"
              label="Zip Code"
              variant="standard"
              rows={4}
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: 10,
                  fontSize: 17,
                  color: "grey",
                },
              }}
              value={formData.zipCode}
              onChange={(e) =>
                setFormData({ ...formData, zipCode: e.target.value })
              }
              error={Boolean(errors.zipCode)}
              helperText={errors.zipCode}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={s.gridItem}
              fullWidth
              size="small"
              label="Country"
              variant="standard"
              rows={4}
              InputProps={{
                disableUnderline: true,
                color: "white",
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: 10,
                  fontSize: 17,
                  color: "grey",
                },
              }}
              value={formData.country}
              onChange={(e) =>
                setFormData({ ...formData, country: e.target.value })
              }
              error={Boolean(errors.country)}
              helperText={errors.country}
            />
          </Grid>

          {/* Submit Button */}
          <Grid container justifyContent="center" style={{ paddingTop: 10 }}>
            <Grid item xs={6} style={{ paddingLeft: "38%" }}>
              <Button
                style={{ backgroundColor: "#21b6ae", height: 35 }}
                variant="contained"
                endIcon={<ArrowBackIcon />}
                onClick={goBack}
              ></Button>
            </Grid>
            <Grid item xs={6} style={{ paddingRight: "50%" }}>
              <Button
                style={{ backgroundColor: "#21b6ae", height: 35 }}
                variant="contained"
                endIcon={<SendIcon />}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

export default BusinessForm;
