import { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

export const Category_Carousel_ICONS = ({ setCategory, active }) => {
  const sliderRef = useRef(null);

  const Icons = [
    {
      id: 1,
      img: "https://th.bing.com/th/id/R.a959ce9211765aae57111ea75a1d8ba2?rik=b7c4VlFKhP24PQ&riu=http%3a%2f%2fclipart-library.com%2fimages_k%2ftransparent-food-clipart%2ftransparent-food-clipart-10.png&ehk=h6J2VFGpq1VH%2fLgtQ%2fAbt2LWfl1Sz2bORmWjqD9s8dM%3d&risl=&pid=ImgRaw&r=0",
      name: "Healthy",
    },
    {
      id: 2,
      img: "https://th.bing.com/th/id/R.c0e8c66478d95d313dafcc60738bc629?rik=dN71r633%2bj952w&pid=ImgRaw&r=0",
      name: "Bubble Tea",
    },
    {
      id: 3,
      img: "https://th.bing.com/th/id/R.0dc7d1fb211113b4fba170b6e203a978?rik=nS8Jvk26hN7XJA&riu=http%3a%2f%2fimages.clipartpanda.com%2fsandwich-clipart-sandwich-parts.png&ehk=sU7OcwpISGcWzmPzF6tGolcpVThQJwXsDDkvIb2XL6U%3d&risl=&pid=ImgRaw&r=0",
      name: "Sandwitches",
    },
    {
      id: 4,
      img: "https://th.bing.com/th/id/OIP.WNn6KYPV-R8aNF0ISaj1wQHaHV?pid=ImgDet&rs=1",
      name: "Pizza",
    },
    {
      id: 5,
      img: "https://th.bing.com/th/id/R.6f9cc955ef0ba0ceeb629bf545db05a2?rik=veucYr%2biXGMd%2fQ&riu=http%3a%2f%2fpngimg.com%2fuploads%2fburger_sandwich%2fburger_sandwich_PNG96780.png&ehk=9lHxrrwdlpxHaHVnSHDztZZfWBKCUtIQimEzkCC5vl8%3d&risl=&pid=ImgRaw&r=0",
      name: "Burger",
    },
    {
      id: 6,
      img: "https://th.bing.com/th/id/R.155e6fd0ff42de3f3eb88ceca0d3670a?rik=Njqe6zYTt3O6aw&pid=ImgRaw&r=0",
      name: "Chinese",
    },
    {
      id: 7,
      img: "https://th.bing.com/th/id/R.0246a568403d33ff2f7fea99ec127d8f?rik=8rRlMAvUr3cXVQ&riu=http%3a%2f%2fclipartix.com%2fwp-content%2fuploads%2f2016%2f04%2fDownload-breakfast-clip-art-free-clipart-of-breakfast-food-3.png&ehk=hcNj8rW9WTS2BotjguRFYtZY6uVuIv6AfsimFfDlqiA%3d&risl=&pid=ImgRaw&r=0",
      name: "Breakfast",
    },
    {
      id: 8,
      img: "https://th.bing.com/th/id/OIP._t_GAa5iJQsLDDmB-FdvpAAAAA?pid=ImgDet&rs=1",
      name: "Desert",
    },
    {
      id: 9,
      img: "https://th.bing.com/th/id/R.ffacc4e8a20108ef3e0b0dc348d1c3ae?rik=KIxpKgtsglf4yg&pid=ImgRaw&r=0",
      name: "Smoothie",
    },
    {
      id: 10,
      img: "https://th.bing.com/th/id/R.34c9b104e99d4be1c80acb72b220be46?rik=teFRUzyuasfFKA&pid=ImgRaw&r=0",
      name: "Fast Food",
    },
    {
      id: 11,
      img: "https://th.bing.com/th/id/R.394d0286981e7693164a1da12bc9ccab?rik=IthaWRUZa8MGOQ&pid=ImgRaw&r=0",
      name: "Soup",
    },
    {
      id: 12,
      img: "https://th.bing.com/th/id/OIP.5F3xNapLI-0SgR8n9X77XgHaHv?pid=ImgDet&rs=1",
      name: "Vegan",
    },
    {
      id: 13,
      img: "https://th.bing.com/th/id/R.7594f224f864645056a554b2c26737df?rik=kFQduIgg7Y6GtA&pid=ImgRaw&r=0",
      name: "Acclaimed",
    },
    {
      id: 14,
      img: "https://clipground.com/images/clipart-cappuccino-5.jpg",
      name: "Coffee",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    vertical: false,
    speed: 500,
    slidesToShow: 12,
    slidesToScroll: 5,
    arrows: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: Icons?.length > 7 ? 8 : Icons?.length,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 2520,
        settings: {
          slidesToShow: Icons?.length > 5 ? 8 : Icons?.length,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1820,
        settings: {
          slidesToShow: Icons?.length > 4 ? 10 : Icons?.length,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: Icons?.length > 5 ? 10 : Icons?.length,
          slidesToScroll: 4,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Icons?.length > 5 ? 8 : Icons?.length,
          slidesToScroll: 4,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 824,
        settings: {
          slidesToShow: Icons?.length > 2 ? 3 : Icons?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: Icons?.length > 1 ? 2 : Icons?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: Icons?.length > 1 ? 3 : Icons?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: Icons?.length > 0 ? 3 : Icons?.length,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="">
      <section className="-mx-2 relative">
        <button
          className={`rounded-full absolute -left-3 z-10 top-1/4 p-2 hover:bg-[#F7F7F7] shadow-lg ${
            Icons?.length > settings.slidesToShow
              ? "text-black-primary  bg-white"
              : "text-black-primary  bg-white"
          }  text-xl cursor-pointer`}
          onClick={() => sliderRef.current.slickPrev()}
          disabled={Icons?.length > settings.slidesToShow ? false : true}
        >
          <BsChevronLeft className="text-sm font-semibold" />
        </button>
        <div>
          <Slider ref={sliderRef} {...settings}>
            {Icons?.map((item, index) => (
              <div key={index} className="px-2">
                {!item.duplicate && (
                  <div
                    key={index}
                    onClick={() => {
                      setCategory(item?.name);
                    }}
                    className="space-y-2 group cursor-pointer bg-white"
                  >
                    <div className="rounded-md overflow-hidden flex items-center justify-center">
                      <img
                        className="h-10 w-10 object-cover group-hover:scale-105 duration-1000"
                        src={item.img}
                        alt=""
                      />
                    </div>
                    <div className="text-center text-gray-text space-y-1">
                      <h1
                        className={`${
                          active === item?.name
                            ? "text-[#EB1700]"
                            : "text-black-primary"
                        } text-sm font-semibold cursor-pointer duration-1000`}
                      >
                        {item?.name?.length > 20
                          ? item?.name.substring(0, 20) + "..."
                          : item?.name}
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </Slider>
        </div>
        <button
          disabled={Icons?.length > settings.slidesToShow ? false : true}
          className={`rounded-full absolute right-0 top-1/4 z-10 p-2 hover:bg-[#F7F7F7] shadow-lg ${
            Icons?.length > settings.slidesToShow
              ? "text-black-primary bg-white"
              : "text-black-primary bg-gray-transparent bg-opacity-10"
          }  text-xl cursor-pointer`}
          onClick={() => sliderRef.current.slickNext()}
        >
          <BsChevronRight className="text-sm" />
        </button>
      </section>
    </div>
  );
};
