import React from "react";
import { useLocation } from "react-router";
import Navbar from "../../Components/navbar";
import RoundBtn from "../../Components/Button/roundBtn";
import { FaRegUser } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import RestaurantDetails from "../../Components/details";
import Switcher11 from "../../Components/Button/toggleBtn";
import { ASSETS } from "../../Assets/path";
import FeaturedItemCarousel from "./featuredItemCarousel";
import PopularItemCarousel from "./popularItems";
import { GoArrowUpRight } from "react-icons/go";
import { BsChevronDown } from "react-icons/bs";
import { MdOutlinePhone } from "react-icons/md";
import { BiTimeFive } from "react-icons/bi";

const OrderPage = () => {
  const location = useLocation();
  const data = location.state;

  const IconText = [
    {
      id: 1,
      icon: <IoLocationOutline />,
      text: "143 Grand Street",
      detail: "New York, NY",
      arrow: <GoArrowUpRight />,
    },
    {
      id: 2,
      icon: <BiTimeFive />,
      text: "Open",
      detail: "Accepting DoorDash orders all day",
      arrow: <BsChevronDown />,
    },
    {
      id: 2,
      icon: <MdOutlinePhone />,
      text: "2263811",
      detail: "",
      arrow: <GoArrowUpRight />,
    },
  ];
  return (
    <>
      <Navbar order={true} />
      {/* address field  */}
      <div className="pt-16 xs:pt-20 items-center justify-center flex xs:px-2 md:px-5 lg:px-4 xl:px-0">
        <div className="bg-[#494949] xs:rounded-xl w-[55rem] md:w-[72rem] lg:w-[63rem] 2xl:w-[72rem] p-3 sm:p-5">
          <div className="flex justify-between mb-2">
            <span className="text-sm text-white font-semibold">
              Get it delivered to your door
            </span>
            <div className="hidden md:flex">
              <RoundBtn
                text="Sign in for saved address"
                bgcolor="#E7E7E7"
                bgHover="#C4C4C4"
                icon={
                  <FaRegUser className="mr-2 text-sm font-bold" fill="black" />
                }
              />
            </div>
          </div>
          <div className="text-left w-full border dark:border-none flex items-center rounded-lg bg-white p-2 mb-1">
            <span className="text-xl sm:text-2xl ml-2">
              <IoLocationOutline />
            </span>
            <input
              className="text-md w-full ml-2 border-none font-semibold outline-none placeholder:text-gray-text bg-transparent"
              placeholder="Enter Your Address"
            />
          </div>
          <span className="text-sm text-[#767676] font-normal line-clamp-none">
            Use your exact address to find all available stores and their
            delivery times
          </span>
        </div>
      </div>

      {/* image and logo  */}
      <div className="flex items-center justify-center xs:px-2 md:px-5 lg:px-4 xl:px-0 xs:pt-5">
        <div className="relative">
          <img
            src={data?.image}
            alt=""
            className="sm:rounded-xl rounded-sm object-cover md:px-0 h-[250px] w-[55rem] md:w-[72rem] lg:w-[63rem] 2xl:w-[72rem] md:h-64"
          />
          <img
            src={data?.logo}
            alt=""
            className="absolute left-5 -bottom-12 shadow-lg mb-2 rounded-full w-20 h-20 border-2 border-white"
          />
        </div>
      </div>
      {/* details and maps  */}
      <div className="pt-10 flex flex-col justify-center items-center">
        <div className="flex md:flex-row flex-col space-y-2 justify-between w-full px-2 md:px-3 xl:px-0 xl:w-9/12">
          {/* details  */}
          <RestaurantDetails
            name={data?.name}
            shop={data?.shop}
            rating={data?.rating}
            rating2={data?.rating2}
            desc={data?.desc}
            distance={data?.distance}
          />

          {/* side div with buttons  */}
          <div className="p-2 md:p-0 space-y-3 xs:w-7/12 sm:w-full md:w-1/2 lg:w-auto">
            {/* toggle button  */}
            <div className="flex space-x-2 items-center">
              <Switcher11
                text1="Delivery"
                text2="Pickup"
                width={100}
                time1={data?.time}
                time2={data?.time}
              />
              <div>
                <div className="sm:flex hidden">
                  <RoundBtn
                    text="Group Order"
                    bgcolor="#E7E7E7"
                    icon={<img src={ASSETS.GROUP} alt="" className="mr-2" />}
                    width={130}
                    height={40}
                  />
                </div>
                <div className="rounded-full bg-[#E7E7E7] w-9 h-9 p-2 items-center sm:hidden flex">
                  <img src={ASSETS.USER} alt="" className="mr-2 " />
                </div>
              </div>
            </div>
            {/* distance delivery time  */}
            <div className="grid grid-cols-2 grid-flow-col sm:w-1/2 lg:w-96 md:w-full border-2 rounded-lg border-[#E7E7E7] py-1 px-1 md:px-3 mt-2">
              <div className="flex">
                <div className="bg-[#D7F5F5] flex flex-col rounded-lg py-1 lg:px-2 mr-1">
                  <span className="text-base text-center font-bold text-[#00838A]">
                    $0.00
                  </span>
                  <span className="text-sm text-center">
                    delivery fee, first order
                  </span>
                </div>
                <span className="border-[#E7E7E7] border-2 h-8 text-center mt-2"></span>
              </div>
              <div className="flex flex-col rounded-lg py-1">
                <span className="text-base text-center font-bold">16 min</span>
                <span className="text-sm text-center">delivery time</span>
              </div>
            </div>
          </div>
        </div>
        {/* map image  */}
        <div className="mt-6 flex flex-col mx-3 sm:flex-row border-2 rounded-lg border-[#E7E7E7] justify-start xs:w-1/2 sm:w-full xl:w-9/12">
          <img
            src="https://th.bing.com/th/id/OIP.3fPg0sTwYaEzYezsUxCNEwHaGm?pid=ImgDet&w=1536&h=1370&rs=1"
            alt=""
            className="lg:w-full lg:h-52 xl:h-52 sm:w-96 sm:h-52 md:w-1/2 md:h-56 rounded-lg xl:w-1/2"
          />
          <div className="flex flex-col md:w-1/2">
            {IconText?.map((item, index) => (
              <div key={index}>
                <div className="flex justify-between space-x-2 p-3">
                  <div className="flex space-x-2">
                    <span className="mt-1 text-xl">{item.icon}</span>{" "}
                    <div className="flex flex-col">
                      <span className="text sm font-semibold">{item.text}</span>
                      <span className="text-xs">{item.detail}</span>
                    </div>
                  </div>
                  <span className="ml-10 text-lg">{item.arrow}</span>
                </div>
                <hr className={`text-[#E7E7E7] ml-10 p-1 mr-2`}></hr>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* featured items carpousel  */}
      <div className="flex items-center justify-center">
        <div className="pt-10 pb-5 px-4 xl:px-0 w-full xl:w-9/12">
          <FeaturedItemCarousel heading="Featured Items" data={data} />
        </div>
      </div>
      {/* break point  */}
      <div className="flex items-center justify-center">
        <hr className="py-2 w-11/12 xl:w-9/12 text-[#E7E7E7]"></hr>
      </div>

      {/* popular items / full menu  */}
      <div className="flex items-center justify-center">
        <div className="pt-10 pb-5 px-2 md:px-5 xl:px-0 w-full xl:w-9/12">
          <PopularItemCarousel data={data} />
        </div>
      </div>
    </>
  );
};

export default OrderPage;
