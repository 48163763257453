import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MenuEditor } from "./Menu/MenuEditor";
import { MenuSelector } from "./Menu/MenuSelector";
import { Col, Container, Row } from "react-bootstrap";
import { useAuthUpdate, useToken, useUser } from "../AuthContext";
import Buttons from "../Components/Button/Buttons";
import {
  uploadmerchantPictures,
  setMerchantMenus,
  getMenusForMerchants,
} from "../Functions/APIs";
export default function MenuPage() {
  const [menus, setMenus] = useState([]);
  const [primaryId, setPrimaryId] = useState("");
  const savedMenu = localStorage.getItem("currentMenu");
  const [currentMenu, setCurrentMenu] = useState(
    savedMenu ? JSON.parse(savedMenu) : null
  );
  const s = {
    background: {
      background: "#BADECC",
      position: "absolute",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
      "overflow-y": "auto",
    },
    menuContainer: {
      background: "#fff",
      marginLeft: "auto",
      marginRight: "auto",
      width: "80%",
      height: "50%",
      position: "centered",
    },
    menuSelector: {},
    menuEditor: {},
  };
  const token = useToken();
  const merchant_id = useUser().username;

  useEffect(() => {
    // This function will fetch the menus from the API and set the states
    const fetchMenus = async () => {
      try {
        const response = await getMenusForMerchants(merchant_id, token);
        console.log("Fetching menus: " + JSON.stringify(response));
        const responseData = response.body.payload; // adjust according to your API response structure
        const fetchedMenus = responseData.menus.menus;
        setMenus(fetchedMenus);

        // If currentMenu is not in localStorage, set it to the first menu from the fetched list
        if (!currentMenu) {
          const primaryMenuId = responseData.menus.primaryMenu;
          const primaryMenu = fetchedMenus.find(
            (menu) => menu.menuId === primaryMenuId
          );
          setCurrentMenu(primaryMenu);
          localStorage.setItem("currentMenu", JSON.stringify(primaryMenu));
        }
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
    };

    fetchMenus();
  }, []);

  const setMenu = (menu) => {
    let menusObj = JSON.parse(JSON.stringify(menus));

    const index = menusObj.findIndex(
      (menuObj) => menuObj.menuId === menu.menuId
    );

    if (index !== -1) {
      menusObj[index] = menu;
      setMenus(menusObj);
    }
    setCurrentMenu(menu);
  };

  const createNewMenu = (json) => {
    setMenus([...menus, json]);
    setCurrentMenu(json);
  };
  const clearMenu = () => {
    const freshMenu = {
      menuId: currentMenu.menuId,
      menuTitle: "default title",
      categories: [],
    };
    const updatedMenus = menus.map((menu) =>
      menu.menuId === currentMenu.menuId ? freshMenu : menu
    );
    setMenus(updatedMenus);
    setCurrentMenu(freshMenu);
  };
  const uploadMenu = async () => {
    //Need to extract image_id's and image data
    const imageData = extractImagesAndIds(menus);
    console.log("image data: " + JSON.stringify(imageData));

    //Need to push imgData and recieve http address for each image
    const http_address = await uploadmerchantPictures(
      merchant_id,
      imageData,
      token
    );
    console.log("(http_address): " + JSON.stringify(http_address));
    let imageUrlArray = JSON.parse(
      JSON.parse(JSON.stringify(http_address.body))
    ).imageUrls;
    console.log("(http_address): " + JSON.stringify(http_address.body));

    //replace all img tags with the http address
    const menusObj = replaceImagesWithLinks(menus, imageUrlArray);
    console.log("Replaced Menus: " + JSON.stringify(menusObj));

    //Upload menu to database and remember to append menu set as primary
    const dbObj = {
      primaryMenu: primaryId,
      menus: menusObj,
    };
    const response = await setMerchantMenus(merchant_id, dbObj, token);
    console.log("setmenu response: " + JSON.stringify(response));
    setMenus(menusObj);
  };
  const setPrimaryMenu = () => {
    const id = currentMenu.menuId;
    setPrimaryId(id);
  };
  const extractImagesAndIds = (menus) => {
    const result = [];

    menus.forEach((menu) => {
      (menu.categories || []).forEach((category) => {
        (category.items || []).forEach((item) => {
          const { img, imageId } = item;
          result.push({ img, imageId });
        });
      });
    });

    return result;
  };
  const replaceImagesWithLinks = (menus, imageUrls) => {
    const urlMapping = {};

    imageUrls.forEach((url) => {
      // This regex matches the last segment between the last / and . (irrespective of the file extension)
      const match = url.match(/\/([^\/]+)\.[^\.]+$/);
      if (match) {
        urlMapping[match[1]] = url;
      }
    });

    menus.forEach((menu) => {
      menu.categories.forEach((category) => {
        category.items.forEach((item) => {
          if (urlMapping[item.imageId]) {
            item.img = urlMapping[item.imageId];
          }
        });
      });
    });
    return menus;
  };

  console.log("Menus: " + JSON.stringify(menus));
  return (
    <div style={s.background}>
      <Link to="/merchant">Merchant Home</Link>
      <h1>Menus Editor!</h1>
      <Paper style={s.menuContainer} elevation={4}>
        <MenuSelector
          style={s.menuSelector}
          menus={menus}
          setCurrentMenu={setMenu}
          createNewMenu={createNewMenu}
        />
        <MenuEditor style={s.menuEditor} menu={currentMenu} setMenu={setMenu} />
        <Container>
          <Row className="justify-center">
            <Col className="text-center xs:flex xs:flex-col xs:items-center gap-y-10">
              <Buttons
                ariaLabel="button"
                href="#"
                className="mx-[10px] font-medium font-serif rounded-none uppercase btn-flat"
                size="sm"
                themeColor="#232323"
                color="#fff"
                title="Clear Menu"
                onClick={() => {
                  clearMenu();
                }}
              />
              <Buttons
                ariaLabel="button"
                href="#"
                className="mx-[10px] font-medium font-serif rounded-none uppercase btn-flat"
                size="sm"
                themeColor="#232323"
                color="#fff"
                title="Save Edits"
                onClick={(e) => {
                  uploadMenu();
                }}
              />
              <Buttons
                ariaLabel="button"
                href="#"
                className="mx-[10px] font-medium font-serif rounded-none uppercase btn-flat"
                size="sm"
                themeColor={
                  currentMenu.menuId === primaryId ? "#65a483" : "#232323"
                }
                color="#fff"
                title="Set as Primary"
                onClick={() => {
                  setPrimaryMenu();
                }}
              />
            </Col>
          </Row>
        </Container>
      </Paper>
    </div>
  );
}
