import MAIN from "./img/main.webp";
import LOGO from "./img/doorDash.svg";
import HAMBURGER from "./icons/hamBurger.svg";
import CONVEINIENCE from "./img/convieniencs.svg";
import PAYMENT from "./icons/delivery.svg";
import ALCOHOL from "./img/alcohol.svg";
import BEAUTY from "./img/beauty.svg";
import CATERING from "./img/catering.svg";
import FLOWERS from "./img/flowers.svg";
import GIFTS from "./img/gifts.svg";
import GROCERY from "./img/grocery.svg";
import HEALTH from "./img/health.svg";
import OFFERS from "./img/offers.svg";
import PACKAGES from "./img/packages.svg";
import PETS from "./img/pets.svg";
import RETAIL from "./img/retail.svg";
import SHIPSNATIONWIDE from "./img/shipsNationWide.svg";
import GROUP from "./icons/groupUsers.svg";
import USER from "./icons/user.svg";
import LIST from "./icons/list.svg";
import PHONE from "./icons/phone.svg";
import SPARKLE from "./icons/sparkle.svg";
import CART from "./icons/cart.svg";

export const ASSETS = {
  MAIN,
  LOGO,
  HAMBURGER,
  GROUP,
  LIST,
  PHONE,
  SPARKLE,
  USER,
  CART,
  CAROUSEL: {
    CONVEINIENCE,
    PAYMENT,
    ALCOHOL,
    BEAUTY,
    CATERING,
    FLOWERS,
    GIFTS,
    GROCERY,
    HEALTH,
    OFFERS,
    PACKAGES,
    PETS,
    RETAIL,
    SHIPSNATIONWIDE,
  },
};
