import React from "react";

const Name = ({ name }) => {
  const abbreviateName = () => {
    const nameParts = name.trim().split(" ");
    if (nameParts.length > 1) {
      const lastNameInitial = nameParts[nameParts.length - 1][0] + ".";
      return `${nameParts[0]} ${lastNameInitial}`;
    }
    return name;
  };

  return <p>{abbreviateName()}</p>;
};

export default Name;
