import React, { useState } from "react";
import {
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const ShoppingCart = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cart, setCart] = useState(location.state.initCart);
  const [openDialog, setOpenDialog] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  const toFixedPrice = (price) => {
    const numberPrice = Number(price);
    return isNaN(numberPrice) ? "0.00" : numberPrice.toFixed(2);
  };

  const updateItemQuantity = (index, delta) => {
    const newQuantity = (cart.items[index].quantity || 0) + delta;
    if (newQuantity <= 0) {
      handleDialogOpen(index);
      return;
    }

    const newCart = { ...cart };
    newCart.items[index].quantity = newQuantity;
    newCart.currentPrice = newCart.items.reduce(
      (sum, item) => sum + parseFloat(item.currentPrice) * item.quantity,
      0
    );
    setCart(newCart);
  };

  const handleDialogOpen = (index) => {
    setItemToRemove(index);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const deleteItem = () => {
    const newCart = { ...cart };
    newCart.items.splice(itemToRemove, 1);
    newCart.currentPrice = newCart.items.reduce(
      (sum, item) => sum + parseFloat(item.currentPrice) * item.quantity,
      0
    );
    setCart(newCart);
    handleDialogClose();
  };

  const handleBack = () => {
    navigate("/menu-viewer", { state: { ...location.state, prevCart: cart } });
  };

  const renderSelectedOptions = (selectedOptions) => {
    return selectedOptions.map((option) =>
      option.selection
        .filter((item) => item.quantity > 0)
        .map((item, idx) => (
          <span key={idx} style={{ display: "block" }}>
            {` - ${item.name}${item.quantity > 1 ? ` ${item.quantity}x` : ""}`}
          </span>
        ))
    );
  };
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#fff0f0",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Button startIcon={<ArrowBackIcon />} onClick={handleBack}>
          Back
        </Button>
        <h1 style={{ color: "red", fontWeight: "bold" }}>Shopping Cart</h1>
        <div /> {/* Placeholder for alignment */}
      </div>
      {cart.items.map((item, index) => (
        <Paper
          key={index}
          sx={{
            marginBottom: "10px",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <Grid container spacing={2} columns={12} alignItems="center">
            <Grid item xs={12} sm={6}>
              <img
                src={item.img}
                alt={item.itemName}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  borderRadius: "5px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <h1 style={{ fontSize: 20 }}>{item.itemName}</h1>
              <p style={{ fontSize: 13, fontWeight: 500 }}>
                {renderSelectedOptions(item.selectedOptions)}
              </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={() => updateItemQuantity(index, -1)}>
                  <RemoveIcon color="error" />
                </IconButton>
                <span>{item.quantity || 0}</span>
                <IconButton onClick={() => updateItemQuantity(index, 1)}>
                  <AddIcon color="error" />
                </IconButton>
                <span
                  style={{
                    marginLeft: "auto",
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  ${toFixedPrice(item.currentPrice || item.price)} (
                  {toFixedPrice(
                    (item.currentPrice || item.price) * (item.quantity || 0)
                  )}
                  )
                </span>
              </div>
            </Grid>
          </Grid>
        </Paper>
      ))}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <h2>Total: ${toFixedPrice(cart.currentPrice)}</h2>
        <Button
          variant="contained"
          color="error"
          style={{ fontWeight: "bold" }}
          onClick={() =>
            navigate("/checkout", {
              state: { ...location.state, cart },
            })
          }
        >
          Proceed to Checkout
        </Button>
      </div>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{"Remove Item"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this item from your cart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteItem} color="primary">
            Yes, remove it
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShoppingCart;
