import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import { ASSETS } from "../../Assets/path";
import { useLocation, useNavigate } from "react-router-dom";

export const Carousel = ({ heading, slides, context }) => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: false,
    vertical: false,
    // autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: slides?.length > 7 ? 4 : slides?.length,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 2520,
        settings: {
          slidesToShow: slides?.length > 5 ? 6 : slides?.length,
          slidesToScroll: 2.5,
        },
      },
      {
        breakpoint: 1820,
        settings: {
          slidesToShow: slides?.length > 4 ? 3 : slides?.length,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slides?.length > 2 ? 3 : slides?.length,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slides?.length > 2 ? 3 : slides?.length,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 824,
        settings: {
          slidesToShow: slides?.length > 2 ? 3 : slides?.length,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: slides?.length > 1 ? 2 : slides?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: slides?.length > 1 ? 1 : slides?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: slides?.length > 0 ? 1 : slides?.length,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //console.log("CATEGORY JSON: " + JSON.stringify(slides));
  return (
    <section className="space-y-6 -mx-2">
      <div className="flex justify-between items-center px-2">
        <h1 className="text-lg md:text-2xl lg:text-3xl text-gray-dark text-left font-bold">
          {heading}
        </h1>

        <div
          className={`text-gray-dark flex space-x-4 items-center ${
            slides?.length > 3 ? "visible" : "hidden"
          }`}
        >
          <div className="font-semibold cursor-pointer text-xs md:text-sm hidden md:flex">
            See All
          </div>
          <div className="flex space-x-2">
            <button
              className={`rounded-full p-1 md:p-2 hover:bg-[#C4C4C4] shadow-lg ${
                slides?.length > settings.slidesToShow
                  ? "text-black-primary  bg-white"
                  : "text-gray-base bg-gray-transparent bg-opacity-10"
              }  md:text-xl text-lg cursor-pointer`}
              onClick={() => sliderRef.current.slickPrev()}
              disabled={slides?.length > settings.slidesToShow ? false : true}
            >
              <BsChevronLeft className="text-sm font-semibold" />
            </button>
            <button
              disabled={slides?.length > settings.slidesToShow ? false : true}
              className={`rounded-full p-2 hover:bg-[#C4C4C4] shadow-lg${
                slides?.length > settings.slidesToShow
                  ? "text-black-primary bg-[#E7E7E7]"
                  : "text-gray-base  bg-[#F7F7F7] bg-opacity-10"
              }  text-xl cursor-pointer`}
              onClick={() => sliderRef.current.slickNext()}
            >
              <BsChevronRight className="text-sm" />
            </button>
          </div>
        </div>
      </div>
      <div className="">
        <Slider ref={sliderRef} {...settings}>
          {slides?.length > 0 ? (
            slides?.map((slide, index) => {
              const item = {
                ...slide,
                context: context,
              };
              return (
                <div key={index} className="px-2">
                  {!item.duplicate && (
                    <div
                      key={index}
                      className="space-y-2 group cursor-pointer"
                      onClick={() => navigate("/menu-viewer", { state: item })}
                    >
                      <div className="rounded-md overflow-hidden">
                        <img
                          className="w-full h-44 object-cover group-hover:scale-105 duration-1000"
                          src={item.cardImage}
                          alt=""
                        />
                      </div>
                      <div className="text-left text-gray-text space-y-1">
                        <h1 className="text-sm text-black-primary md:text-base flex space-x-2 lg:text-lg font-semibold cursor-pointer duration-1000">
                          {item?.merchantName?.length > 20
                            ? item?.merchantName.substring(0, 20) + "..."
                            : item?.merchantName}
                          <img src={ASSETS.CAROUSEL.PAYMENT} alt="" />
                        </h1>
                        <div className="text-xs md:text-sm flex items-center space-x-2 font-normal duration-1000">
                          <span>
                            {/* {item?.distance?.toFixed(2) + " " + "mi" || 0} •{" "} */}
                            {item?.distance} • {item?.dist_time + " " + "•"}
                          </span>
                          <div className="text-xs md:text-sm font-normal duration-1000 flex items-center space-x-2">
                            <span>$0 delivery fee, first order</span>
                          </div>
                        </div>

                        <div className="flex space-x-1 items-center">
                          <span className="text-xs md:text-sm">
                            {item?.rating}
                          </span>
                          <span>
                            <AiOutlineStar />
                          </span>
                          <div className="mr-1 text-xs md:text-sm">
                            {"( " + item?.numRatings + " reviews )"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="text-center">
              <span className="">No data exist for selected category</span>
            </div>
          )}
        </Slider>
      </div>
    </section>
  );
};
