import React, { useState } from "react";

const Switcher11 = ({
  text1,
  text2,
  time1,
  time2,
  toggle1,
  toggle2,
  height,
  width,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <label className="themeSwitcherTwo shadow-card relative inline-flex rounded-full cursor-pointer select-none items-center justify-center bg-[#E7E7E7]">
        <input
          type="checkbox"
          className="sr-only"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <span
          onClick={() => {
            toggle1();
            setIsChecked(false);
          }}
          style={{ height, width }}
          className={`flex flex-col space-y-1 items-center space-x-[6px] rounded-full h-${height} w-${width} px-3 py-1 text-sm font-medium ${
            !isChecked ? "text-white bg-[#191919]" : "text-body-color"
          }`}
        >
          {text1}
          <span className="text-xs">{time1}</span>
        </span>
        <span
          onClick={() => {
            toggle2();
            setIsChecked(true);
          }}
          style={{ height, width }}
          className={`flex flex-col space-y-1 items-center space-x-[6px] rounded-full h-${height} w-${width} px-3 py-1 text-sm font-medium ${
            isChecked ? "text-white bg-[#191919]" : "text-body-color"
          }`}
        >
          {text2}
          <span className="text-xs">{time2}</span>
        </span>
      </label>
    </>
  );
};

export default Switcher11;
