import React, { useState } from "react";
import RoundBtn from "../../Components/Button/roundBtn";
import AddFood from "./addFood";
import { FullMenu } from "./fullMenu";
import { useEffect } from "react";
import ScrollSpy from "react-ui-scrollspy";

const PopularItemCarousel = ({ data, addToCart }) => {
  const [openModal, setOpenModal] = useState(false);
  const [foodDetails, setFoodDetails] = useState("");
  const [category, setCategory] = useState("Popular Items");
  const [selectedCategories, setSelectedCategories] = useState();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const categories = data?.dishes.filter(
      (item) => item?.menu === selectedCategories
    );
    setMenuItems(categories);
  }, [selectedCategories]);

  const onOpenModal = (item) => {
    setOpenModal(!openModal);
    setFoodDetails(item);
  };
  return (
    <div>
      <div className="sticky z-10 top-16 pt-4 bg-white overflow-hidden w-full">
        <FullMenu data={data} setMenu={setCategory} active={category} />
        <div className="flex items-center justify-center mt-2">
          <hr className="py-2 xl:w-9/12 w-full text-[#E7E7E7]"></hr>
        </div>
      </div>

      {data?.menu?.map((item, index) => (
        <section key={index} className="space-y-6">
          <div className="flex flex-col px-2 mt-12">
            <h1 className="text-xl md:text-2xl lg:text-3xl text-gray-dark text-left font-bold">
              {item?.text}
            </h1>
            {index === 0 && (
              <p className="text-sm text-gray-dark">
                The most commonly ordered items and dishes from this store
              </p>
            )}
          </div>

          <ScrollSpy
            scrollThrottle={100}
            onUpdateCallback={(id) => {
              console.log("id", id);
              setSelectedCategories(id);
            }}
            useBoxMethod={false}
            updateHistoryStack={false}
          >
            <div
              id={item?.text}
              className="grid grid-cols-1 md:grid-cols-2 gap-3"
            >
              {data?.dishes?.map((val, id) => (
                <div
                  key={id}
                  id={val?.menu}
                  className="md:border-2 border-[#E7E7E7] rounded-sm"
                >
                  {!val.duplicate && (
                    <>
                      <div
                        key={id}
                        className="flex justify-between mb-5 md:mb-0"
                      >
                        <div className="flex flex-col justify-center px-5 ">
                          <span className="text-md font-semibold">
                            {val?.name}
                          </span>
                          <span className="text-sm text-[#767676] font-medium">
                            {val?.desc?.length > 20
                              ? val?.desc.substring(0, 49) + "..."
                              : val?.desc}
                          </span>
                          <span className="text-sm text-[#767676] font-medium">
                            $ {val.price}
                          </span>
                        </div>
                        <div className="">
                          <div>
                            <div className="relative">
                              <img
                                className="w-40 h-28 lg:w-32 lg:h-32 sm:w-32 sm:h-32 md:w-60 md:h-28 group-hover:scale-105 duration-1000 rounded-sm"
                                src={val.img}
                                alt=""
                              />
                              <span
                                onClick={() => onOpenModal(val)}
                                className="absolute rounded-full shadow-md bottom-2 right-2 lg:bottom-2 lg:right-2 sm:right-5 sm:-bottom-2 md:right-2"
                              >
                                <RoundBtn
                                  text="Add"
                                  color="black"
                                  bgcolor="white"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className=" text-[#E7E7E7]"></hr>
                    </>
                  )}
                </div>
              ))}{" "}
            </div>
          </ScrollSpy>
        </section>
      ))}
      {openModal && (
        <AddFood
          onRequestClose={onOpenModal}
          data={foodDetails}
          addToCart={addToCart}
        />
      )}
    </div>
  );
};

export default PopularItemCarousel;
