//setter apis

export const setMerchantMenus = async (merchant_id, menus, token) => {
  const url =
    "https://54lpugh8ce.execute-api.us-east-1.amazonaws.com/prod/setmenusresource";

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      merchant_id: merchant_id,
      menus: menus,
      token: token,
    }),
  });
  const data = await response.json();
  return data;
};
export const setUserInfo = async (user_id, userInfo, token) => {
  console.log(
    "invoking setUserInfo with: " + user_id + " " + userInfo + " " + token
  );
  const url =
    "https://o8q7mx780e.execute-api.us-east-1.amazonaws.com/prod/setuserinfo";

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      user_id: user_id,
      user_info: userInfo,
      token: token,
    }),
  });
  const data = await response.json();
  return data;
};

export const updatemerchantInfo = async (merchant_id, merchantInfo, token) => {
  const url =
    "https://0pd13c59ii.execute-api.us-east-1.amazonaws.com/prod2/updatemerchantinfo";

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      merchant_id: merchant_id,
      merchantInfo: merchantInfo,
      token: token,
    }),
  });
  const data = await response.json();
  return data;
};

export const uploadmerchantPictures = async (
  merchant_id,
  imageDataArray,
  token
) => {
  /*
   * imageDataArray structure :
   * // http get example www.groupsnack.app/menus-images/menuId-{menu_id}/itemImage-{image_id}
   * [
   *   {
   *      image,
   *      image_id,
   *      image_location
   *        - merchants/{merchant_id}/menuImagesSrc/{image_id.jpg}
   *   }
   * ]
   */
  const url =
    " https://zzq8n7nv3l.execute-api.us-east-1.amazonaws.com/prod/uploadimagesresource";

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      merchant_id: merchant_id,
      imageDataArray: imageDataArray,
      token: token,
    }),
  });
  const data = await response.json();
  return data;
};

export const createGroupOrder = async (
  fullName,
  phoneNumber,
  deliveryAddress,
  email,
  cart,
  cardInfo,
  locationId,
  merchantId,
  group_id
) => {
  const url =
    "https://rqj2d3zi3a.execute-api.us-east-1.amazonaws.com/stage/resource";

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      fullName: fullName,
      phoneNumber: phoneNumber,
      deliveryAddress: deliveryAddress,
      email: email,
      cart: cart,
      cardInfo: cardInfo,
      locationId: locationId,
      merchantId: merchantId,
      group_id, group_id
    }),
  });
  const data = await response.json();
  return data;
};

//getter apis
export const orderManagerLogin = async (locationId, passcode) => {
  const url =
    "https://x0jp4loyz2.execute-api.us-east-1.amazonaws.com/stage/resource";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      locationId: locationId,
      password: passcode,
    }),
  });
  const data = await response.json();
  return data;
};

export const getUniquePlaceId = async (merchant_id, token) => {
  const url =
    "https://s7zyzhwkd4.execute-api.us-east-1.amazonaws.com/stage/locationId";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      merchant_id: merchant_id,
      token: token,
    }),
  });
  const data = await response.json();
  return data;
};

export const getUserInfo = async (user_id, token) => {};

export const getmerchantInfo = async (merchant_id, token) => {
  const url =
    "https://5xo3wzi6f3.execute-api.us-east-1.amazonaws.com/prod/getmerchantinformation";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      merchant_id: merchant_id,
      token: token,
    }),
  });
  const data = await response.json();
  return data;
};

export const getMenusForMerchants = async (merchant_id, token) => {
  const url =
    "https://zhf7fqv83f.execute-api.us-east-1.amazonaws.com/prod/getmerchantmenusresource";

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      merchant_id: merchant_id,
      token: token,
    }),
  });
  const data = await response.json();
  return data;
};
//need to fetch appropriate menu for day/time
export const getMenusForCustomer = async (query) => {
  const url =
    "https://wvcpc21sn8.execute-api.us-east-1.amazonaws.com/prod/getmenuforcustomerresource";

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      merchant_id: query.merchant_id,
    }),
  });
  const data = await response.json();
  return data;
};

export const getGroupObjects = async (query) => {
  /*
   * Returns GroupOrder objects and GroupPoll objects to be displayed
   */
  const url =
    "https://cgzbvbm52e.execute-api.us-east-1.amazonaws.com/prod/getGroupOrders";

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      query: query,
    }),
  });
  const data = await response.json();
  return data;
};
export const getRestaurants = async (query) => {
  /*
   * Returns restaurant objects
   */
  const url =
    "https://akre7qmg5g.execute-api.us-east-1.amazonaws.com/prod/getRestaurantsResource";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      query: query,
    }),
  });
  const data = await response.json();
  return data;
};
