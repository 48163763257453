import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography, Box } from "@mui/material";

function SelectionModal({ isOpen, onClose, title, selections = [] }) {
  // Default value added
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="paper"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        {selections.map((selection, idx) => (
          <Box key={idx} mb={2} overflow="auto">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">{selection.name}</Typography>
              <Typography variant="subtitle1">
                Min: {selection.min} / Max: {selection.max}
              </Typography>
            </Box>
            <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
              {selection.items.map((item, i) => (
                <Box
                  key={i}
                  display="flex"
                  alignItems="center"
                  border={1}
                  p={1}
                  borderRadius={2}
                >
                  <Typography variant="body1" mr={1}>
                    {item.name}:
                  </Typography>
                  <Typography
                    variant="body2"
                    color={item.modifier > 0 ? "green" : "red"}
                  >
                    {item.modifier > 0 ? "+" : ""}
                    {item.modifier}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
}

export function useSelectionModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [title, setTitle] = useState("");

  const showModal = (dataTitle, dataContent) => {
    setTitle(dataTitle);
    setCurrentData(dataContent);
    setIsOpen(true);
  };

  const ModalComponent = (
    <SelectionModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={title}
      selections={currentData} // changed 'data' prop to 'selections'
    />
  );

  return [showModal, ModalComponent];
}
