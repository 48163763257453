import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { ASSETS } from "../../Assets/path";
import { useNavigate } from "react-router";

export const Main = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen overflow-hidden relative w-full bg-red-700">
      <header className="flex items-center justify-center md:justify-start min-h-screen  overflow-hidden">
        <div
          className={`
       absolute z-30 bg-gray-transparent flex flex-col items-center w-full h-full`}
        >
          <div className="px-5  text-center w-full sm:w-8/12 lg:w-1/2 mx-auto space-y-8 h-full flex flex-col text-white items-center justify-center">
            <div className="text-xl md:text-3xl xl:text-4xl  font-bold Lato-Regular">
              Get more from your neighborhood
            </div>
            <div className="relative  flex items-center h-12 px-3 text-black-dark bg-white  justify-center rounded-3xl w-10/12">
              <HiOutlineLocationMarker className="text-gray-text text-2xl cursor-pointer mr-5" />{" "}
              <input
                placeholder="Enter delivery address"
                className="outline-none w-full placeholder-gray-text text-xs sm:text-sm md:text-base"
              />
              <div>
                <BsFillArrowRightCircleFill className="cursor-pointer text-red-base text-3xl" />
              </div>
            </div>

            <div
              onClick={() => navigate("/home")}
              className="text-sm bg-white cursor-pointer text-gray-text px-4 py-2 flex space-x-4 rounded-3xl items-center font-semibold"
            >
              <AiOutlineUser className="text-base" />
              <span>Sign in for saved address</span>
            </div>
          </div>
        </div>
        <img
          src={ASSETS.MAIN}
          className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
        />
      </header>
    </div>
  );
};
