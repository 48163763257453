import React, { useState } from "react";
import { ASSETS } from "../../Assets/path";
import { AiOutlineSearch } from "react-icons/ai";
import { MdLocalGroceryStore } from "react-icons/md";
import { BiChevronDown } from "react-icons/bi";
import RoundBtn from "../Button/roundBtn";

const Navbar = ({ order, onOpenSignIn }) => {
  return (
    <>
      <div className="fixed top-0 left-0 right-0 bg-white shadow-md z-10 overflow-hidden">
        {/* small screen navbar  */}
        <span className="text-2xl ml-2 md:hidden flex justify-between items-center pt-3 px-2">
          <div className="flex space-x-2 items-center">
            <img src={ASSETS.HAMBURGER} alt="" />
            <img src={ASSETS.CART} alt="" />
            <svg width="32" height="18" viewBox="0 0 99.5 56.5" fill="#FF3008">
              <path d="M95.64,13.38A25.24,25.24,0,0,0,73.27,0H2.43A2.44,2.44,0,0,0,.72,4.16L16.15,19.68a7.26,7.26,0,0,0,5.15,2.14H71.24a6.44,6.44,0,1,1,.13,12.88H36.94a2.44,2.44,0,0,0-1.72,4.16L50.66,54.39a7.25,7.25,0,0,0,5.15,2.14H71.38c20.26,0,35.58-21.66,24.26-43.16"></path>
            </svg>

            {/* <AiOutlineSearch /> */}
          </div>
          <div className="flex space-x-2 items-center">
            <RoundBtn
              text="Sign In"
              bgcolor="#EB1700"
              color="white"
              width={80}
              onClick={onOpenSignIn}
            />
            <span className="text-sm font-bold">Open in App</span>
          </div>
        </span>
        {/* large screen navbar  */}
        <div className="flex md:justify-between space-x-2 items-center p-3 xl:mx-10">
          <div className="flex md:space-x-6 items-center space-x-2">
            <span>
              <img src={ASSETS.HAMBURGER} alt="" className="hidden md:flex" />
            </span>
            <div className="items-center space-x-2 md:flex hidden">
              <svg
                width="32"
                height="18"
                viewBox="0 0 99.5 56.5"
                fill="#FF3008"
              >
                <path d="M95.64,13.38A25.24,25.24,0,0,0,73.27,0H2.43A2.44,2.44,0,0,0,.72,4.16L16.15,19.68a7.26,7.26,0,0,0,5.15,2.14H71.24a6.44,6.44,0,1,1,.13,12.88H36.94a2.44,2.44,0,0,0-1.72,4.16L50.66,54.39a7.25,7.25,0,0,0,5.15,2.14H71.38c20.26,0,35.58-21.66,24.26-43.16"></path>
              </svg>
              <span className="text-[#FF3008] font-bold tracking-widest text-lg lg:flex hidden">
                DOORDASH
              </span>
            </div>
            {order ? (
              <span className="font-bold text-sm items-center space-x-2 lg:flex hidden">
                Your Address <BiChevronDown />
              </span>
            ) : (
              <div className="md:flex space-x-3 items-center hidden">
                <RoundBtn
                  text="Delivery"
                  bgcolor="#191919"
                  bgHover="#606060"
                  color="white"
                />

                <span className="font-bold text-sm">Pickup</span>
                <div className="text-lg text-gray-text hidden lg:flex">|</div>
                <span className="font-bold text-sm items-center space-x-2 hidden lg:flex">
                  New York <BiChevronDown />
                </span>
              </div>
            )}
          </div>
          <div className="flex space-x-3 items-center xl:mr-20">
            {!order && (
              <div className="text-left xl:w-96 border dark:border-none hidden md:flex items-center rounded-full bg-[#F7F7F7] p-2">
                <span className="text-2xl ml-2">
                  <AiOutlineSearch />
                </span>
                <input
                  className="text-sm w-full ml-2 border-none font-semibold outline-none placeholder:text-black-primary bg-transparent"
                  placeholder={`Search stores, dishes, pro...`}
                />
              </div>
            )}
            <div className="md:flex space-x-2 hidden">
              <RoundBtn
                text="0"
                bgcolor="#EB1700"
                bgHover="#D91400"
                color="white"
                onClick={onOpenSignIn}
                icon={
                  <MdLocalGroceryStore fill="white" className="text-2xl mr-3" />
                }
              />
              <RoundBtn
                text="Sign In"
                bgcolor=""
                color="black"
                onClick={onOpenSignIn}
              />
              <RoundBtn text="Sign Up" bgcolor="#D6D6D6" color="black" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
