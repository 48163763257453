import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getRestaurants } from "../../Functions/APIs";
import CircularProgress from "@mui/material/CircularProgress";
import { Carousel } from "../../Components/carousel";
import Navbar from "../../Components/navbar";
import RoundBtn from "../../Components/Button/roundBtn";
import { MEALS_LIST } from "../../utils/constant/index";
import "../../index.css";

function RestaurantsHome() {
  const location = useLocation();
  const navigate = useNavigate();
  const lat = location.state?.lat;
  const lng = location.state?.lng;
  const address = location.state?.addressStr;

  const [loading, setLoading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [category, setCategory] = useState();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!lat || !lng || !address) {
      navigate("/");
    }
    sessionStorage.removeItem("isReloaded");
    const fetchData = async () => {
      try {
        const query = { latitude: lat, longitude: lng, radius: 5000 };
        const response = await getRestaurants(query);
        const body = response.body;
        console.log("response: " + JSON.stringify(response.body));
        if (body && body.foundObjects === true) {
          setApiData(body.categories); // Filter out null items
        } else {
          navigate("/", {
            state: { lat, lng, addressStr: address },
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lat, lng, address, navigate]);

  const onOpenSignIn = () => {
    setOpenModal(!openModal);
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    console.log("data: " + JSON.stringify(apiData));
    return (
      <>
        <div className="relative">
          <div className="">
            <Navbar onOpenSignIn={onOpenSignIn} />
          </div>
          <div className="h-screen mx-auto py-16">
            <div className="flex items-center justify-center py-3 mt-5">
              {!category ? (
                <div className="space-y-6 w-10/12">
                  {apiData.map((category, index) => (
                    <Carousel
                      key={index}
                      slides={category.restaurants} // Assuming each restaurant data is suitable for Carousel component
                      heading={category.categoryName}
                    />
                  ))}
                </div>
              ) : (
                // If a category is selected, show filtered data (implement logic based on your application's needs)
                <div className="w-10/12">
                  <div className="flex justify-between">
                    <span className="font-bold text-xl">
                      {/* Number of results here */}
                    </span>
                    <RoundBtn
                      text="Reset"
                      bgcolor="#E7E7E7"
                      onClick={() => setCategory(undefined)}
                    />
                  </div>
                  {/* Carousel with filtered data */}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RestaurantsHome;
