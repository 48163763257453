import React, { useState } from "react";
import PlusMinusBtn from "../Button/plusMinusBtn";
import { FiCheck } from "react-icons/fi";
import { HiOutlineExclamation } from "react-icons/hi";
import { useEffect } from "react";

const FoodOptions = ({
  title,
  select,
  Preferences,
  plusMinus,
  checkbox,
  toggle,
  text,
  price,
  status,
  onRadioChange,
  onToggleChange,
  id,
  min,
  max,
}) => {
  const [count, setCount] = useState(0);
  const [radioSelect, setRadioSelect] = useState(false);
  const [checkedList, setCheckedList] = useState([]);

  useEffect(() => {
    if (checkbox && checkbox.length > 0) {
      const initialList = checkbox.map((item) => ({
        name: item.opt,
        quantity: 0,
        price: item.price,
      }));
      if (!toggle && !plusMinus) {
        initialList[0].quantity = 1;
      }
      setCheckedList(initialList);
    }
  }, []); // Dependency array contains checkbox to re-initialize if checkbox changes
  useEffect(() => {
    if (text == "Add special instructions") {
      onRadioChange({
        id: "special instructions",
        min: 0,
        max: 1,
        selection: [{ name: "special selection", quantity: 0, price: 0 }],
      });
    } else {
      onRadioChange({ id, min: min, max: max, selection: checkedList });
    }
  }, [checkedList]);
  const updateQuantity = (index, shouldIncrement) => {
    setCheckedList((prevList) => {
      // Calculate the total quantity of all items
      const totalQuantity = prevList.reduce(
        (sum, item) => sum + item.quantity,
        0
      );
      var didChange = false;
      return prevList.map((item, idx) => {
        if (idx === index) {
          // Check if incrementing the quantity would exceed the max limit
          if (shouldIncrement && totalQuantity < max) {
            const newVal = { ...item, quantity: item.quantity + 1 };
            return newVal;
          } else if (!shouldIncrement) {
            // Decrementing is always allowed as long as quantity is above 0
            const newVal = {
              ...item,
              quantity: Math.max(0, item.quantity - 1),
            };
            return newVal;
          }
        }
        return item; // Return item as is if no changes are made
      });
    });
  };
  const handleCheckboxClick = (index) => {
    setCheckedList((prevList) => {
      // Calculate the total quantity of all items
      const totalQuantity = prevList.reduce(
        (sum, item) => sum + item.quantity,
        0
      );

      return prevList.map((item, idx) => {
        if (idx === index) {
          // Check if incrementing the quantity would exceed the max limit
          if (item.quantity === 0 && totalQuantity < max) {
            return { ...item, quantity: item.quantity + 1 };
          } else if (item.quantity === 1) {
            return { ...item, quantity: item.quantity - 1 };
          }
        }
        return item; // Return item as is if no changes are made
      });
    });
  };
  const handleRadioClick = (index) => {
    var initialList = checkbox.map((item) => ({
      name: item.opt,
      quantity: 0,
      price: item.price,
    }));
    initialList[index].quantity = 1;
    setCheckedList(initialList);
  };

  return (
    <div>
      {Preferences ? (
        <div className="py-2 space-y-4">
          <div className="flex justify-between">
            <span className="font-bold text-md text-gray-dark">{title}</span>
            <span className="text-sm text-gray-text font-semibold">
              {status}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="text-normal font-medium text-gray-dark">
              {text}
            </span>
            <span className="text-lg">{price}</span>
          </div>
        </div>
      ) : (
        <div className="">
          <span className="flex justify-between">
            <span className="">
              <h1 className="font-bold text-md text-gray-dark">{title}</h1>
              <span className="text-xs text-gray-text font-semibold">
                {select}
              </span>
            </span>
            {toggle || plusMinus ? (
              <strong className="text-xs text-gray-text">(Optional)</strong>
            ) : (
              <strong
                className={`text-xs  ${
                  radioSelect
                    ? "bg-[#E7FBEF] text-[#006A25]"
                    : "bg-[#FFFFA3] text-gray-base2"
                } h-5 rounded-md p-1 flex items-center`}
              >
                {radioSelect ? (
                  <FiCheck className="text-normal mr-1" />
                ) : (
                  <HiOutlineExclamation
                    className="text-normal mr-1"
                    fontWeight={500}
                  />
                )}
                Required
              </strong>
            )}
          </span>
          {checkbox?.map((val, index) => (
            <ul key={index} className="">
              {!plusMinus ? (
                <li className="flex justify-between py-3 items-center">
                  <span>
                    {toggle ? (
                      <input
                        checked={
                          checkedList[index]
                            ? checkedList[index].quantity != 0
                            : false
                        }
                        onClick={() => handleCheckboxClick(index)}
                        type="checkbox"
                        name={val?.opt}
                        className="accent-black-dark mr-4 h-4 w-4 border-2 border-black-dark"
                      />
                    ) : (
                      <input
                        type="radio"
                        name={id}
                        checked={
                          checkedList[index]
                            ? checkedList[index].quantity != 0
                            : false
                        }
                        onClick={() => handleRadioClick(index)}
                        className=" accent-black-dark mr-4 h-4 w-4"
                      />
                    )}
                    <span className="font-semibold text-sm text-gray-dark">
                      {val?.opt}
                    </span>
                  </span>
                  <span className="text-gray-text text-sm font-semibold">
                    +${val?.price}
                  </span>
                </li>
              ) : (
                <li className="flex justify-between py-3 items-center">
                  <span>
                    <span className="font-semibold text-sm text-gray-dark">
                      {val?.opt}
                    </span>
                  </span>
                  <span className="text-gray-text text-sm font-semibold">
                    <PlusMinusBtn
                      value={
                        checkedList[index] ? checkedList[index].quantity : 0
                      }
                      onChange={(e) =>
                        setCount(Math.max(0, parseInt(e.target.value) || 0))
                      }
                      onPlusClick={() => {
                        updateQuantity(index, true);
                      }}
                      onMinusClick={() => {
                        updateQuantity(index, false);
                      }}
                    />
                  </span>
                </li>
              )}
              <hr className="text-[#E7E7E7]"></hr>
            </ul>
          ))}
        </div>
      )}
    </div>
  );
};

export default FoodOptions;
