import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Box,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Edit,
  Save,
  Add,
  Delete,
} from "@mui/icons-material";

const Popup = ({ title, selections, selectionUpdate }) => {
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [openList, setOpenList] = useState({});
  const [data, setData] = useState(selections);

  useEffect(() => {
    setData(selections);
  }, [selections]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
    if (editMode == true) {
      selectionUpdate(data);
    }
  };

  const handleListClick = (i) => {
    setOpenList({ ...openList, [i]: !openList[i] });
  };

  const handleAddSelection = () => {
    setData([...data, { name: "", min: 0, max: 0, items: [] }]);
  };

  const handleFieldChange = (i, field, value) => {
    let newData = [...data];
    newData[i][field] = value;
    setData(newData);
  };

  const handleAddItem = (i) => {
    let newData = [...data];
    newData[i].items.push({ name: "", modifier: 0 });
    setData(newData);
  };

  const handleItemFieldChange = (i, j, field, value) => {
    let newData = [...data];
    newData[i].items[j][field] = value;
    setData(newData);
  };

  const handleDeleteSelection = (i) => {
    let newData = [...data];
    newData.splice(i, 1);
    setData(newData);
  };

  const handleDeleteItem = (i, j) => {
    let newData = [...data];
    newData[i].items.splice(j, 1);
    setData(newData);
  };
  const s = {
    text: {
      color: "Black",
      fontSize: 11,
      fontWeight: 800,
      padding: 2,
      textAlign: "center",
    },
  };
  return (
    <div>
      <Box style={{ ...s.text, padding: 1 }} onClick={handleClickOpen}>
        <p>{"View " + title}</p>
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          {title}
          <IconButton edge="end" onClick={handleEditMode}>
            {editMode ? <Save /> : <Edit />}
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {editMode && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleAddSelection}
            >
              <Add /> Add Selection
            </Button>
          )}
          <List>
            {data.map((selection, i) => (
              <div key={i}>
                <ListItem>
                  {editMode ? (
                    <>
                      <TextField
                        label="Name"
                        value={selection.name}
                        onChange={(e) =>
                          handleFieldChange(i, "name", e.target.value)
                        }
                      />
                      <TextField
                        label="Min"
                        type="number"
                        value={selection.min}
                        onChange={(e) =>
                          handleFieldChange(i, "min", Number(e.target.value))
                        }
                      />
                      <TextField
                        label="Max"
                        type="number"
                        value={selection.max}
                        onChange={(e) =>
                          handleFieldChange(i, "max", Number(e.target.value))
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={() => handleDeleteSelection(i)}
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </>
                  ) : (
                    <ListItemText
                      primary={selection.name}
                      secondary={`Min: ${selection.min}, Max: ${selection.max}`}
                      onClick={() => handleListClick(i)}
                    />
                  )}
                  {openList[i] ? (
                    <ExpandLess onClick={() => handleListClick(i)} />
                  ) : (
                    <ExpandMore onClick={() => handleListClick(i)} />
                  )}
                </ListItem>
                <Collapse in={openList[i]} timeout="auto" unmountOnExit>
                  <Box pl={4}>
                    <List component="div" disablePadding>
                      {selection.items.map((item, j) => (
                        <ListItem key={j}>
                          {editMode ? (
                            <>
                              <TextField
                                label="Item Name"
                                value={item.name}
                                onChange={(e) =>
                                  handleItemFieldChange(
                                    i,
                                    j,
                                    "name",
                                    e.target.value
                                  )
                                }
                              />
                              <TextField
                                label="Price Modifier"
                                type="number"
                                inputProps={{ step: 0.1 }}
                                value={item.modifier}
                                onChange={(e) =>
                                  handleItemFieldChange(
                                    i,
                                    j,
                                    "modifier",
                                    Number(e.target.value)
                                  )
                                }
                              />

                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  onClick={() => handleDeleteItem(i, j)}
                                >
                                  <Delete />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </>
                          ) : (
                            <ListItemText
                              primary={item.name}
                              secondary={`Modifier: ${item.modifier}`}
                            />
                          )}
                        </ListItem>
                      ))}
                      {editMode && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleAddItem(i)}
                        >
                          <Add /> Add Item
                        </Button>
                      )}
                    </List>
                  </Box>
                </Collapse>
              </div>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Popup;
