import React from "react";
import { ASSETS } from "../../Assets/path";
import { AiOutlineSearch } from "react-icons/ai";

export const FullMenu = ({ setMenu, active, data }) => {
  const onPress = (e) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );
    console.log("target", target);
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="flex flex-col items-center justify-center">
      {/* search bar  */}
      <div className="flex lg:flex-row flex-col justify-between space-y-3 w-full px-2 md:px-5 xl:px-0">
        <span className="">
          <h1 className="text-base font-semibold">Full Menu</h1>
          <h2 className="text-sm">all day</h2>
        </span>
        <div className="text-left lg:w-96 border dark:border-none flex items-center rounded-full bg-[#F7F7F7] p-2">
          <span className="text-2xl ml-2">
            <AiOutlineSearch />
          </span>
          <input
            className="text-md w-full ml-2 border-none font-semibold outline-none placeholder:text-gray-text bg-transparent"
            placeholder="Search stores menu"
          />
        </div>
      </div>

      {/* tabs code  */}
      <div className="flex w-9/12 mt-8 items-center space-x-5">
        <span className="">
          <img src={ASSETS.LIST} alt="" />
        </span>

        <div className="flex space-x-5">
          {data?.menu?.map((item, index) => (
            <a onClick={(e) => onPress(e)} href={`#${item?.text}`}>
              <div
                data-to-scrollspy-id={item?.text}
                onClick={() => setMenu(item?.text)}
                key={index}
                className={`cursor-pointer text-sm`}
              >
                {item.text}
                {/* {active === item?.text && (
                  <div className="rounded-t-xl mt-2 border-t-4 text-black-dark w-auto h-1"></div>
                )} */}
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
