import { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { ASSETS } from "../../Assets/path";

export const Category_Carousel = ({}) => {
  const sliderRef = useRef(null);

  const carousel = [
    { id: 1, icon: ASSETS.CAROUSEL.ALCOHOL, text: "Alcohol" },
    { id: 2, icon: ASSETS.CAROUSEL.BEAUTY, text: "Beauty" },
    { id: 3, icon: ASSETS.CAROUSEL.CATERING, text: "Catering" },
    { id: 4, icon: ASSETS.CAROUSEL.CONVEINIENCE, text: "Conveinience" },
    { id: 5, icon: ASSETS.CAROUSEL.FLOWERS, text: "Flowers" },
    { id: 6, icon: ASSETS.CAROUSEL.GIFTS, text: "Gifts" },
    { id: 7, icon: ASSETS.CAROUSEL.GROCERY, text: "Grocery" },
    { id: 8, icon: ASSETS.CAROUSEL.HEALTH, text: "Health" },
    { id: 9, icon: ASSETS.CAROUSEL.OFFERS, text: "Offers" },
    { id: 10, icon: ASSETS.CAROUSEL.PACKAGES, text: "Packages" },
    { id: 12, icon: ASSETS.CAROUSEL.PETS, text: "Pets" },
    { id: 13, icon: ASSETS.CAROUSEL.RETAIL, text: "Retail" },
    {
      id: 14,
      icon: ASSETS.CAROUSEL.SHIPSNATIONWIDE,
      text: "Ships Nation Wide",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    vertical: false,
    // autoplay: true,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 5,
    arrows: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: carousel?.length > 7 ? 8 : carousel?.length,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 2520,
        settings: {
          slidesToShow: carousel?.length > 5 ? 6 : carousel?.length,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1820,
        settings: {
          slidesToShow: carousel?.length > 4 ? 8 : carousel?.length,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: carousel?.length > 5 ? 10 : carousel?.length,
          slidesToScroll: 4,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: carousel?.length > 5 ? 5 : carousel?.length,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 824,
        settings: {
          slidesToShow: carousel?.length > 2 ? 3 : carousel?.length,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: carousel?.length > 1 ? 2 : carousel?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: carousel?.length > 1 ? 3 : carousel?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: carousel?.length > 0 ? 3 : carousel?.length,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className="">
      <section className="-mx-2 relative">
        <button
          className={`rounded-full absolute -left-3 z-10 top-2 md:top-1/4 p-2 text-black-primary bg-white hover:bg-[#F7F7F7] shadow-lg text-xl cursor-pointer`}
          onClick={() => sliderRef.current.slickPrev()}
          disabled={
            carousel?.length?.length > settings.slidesToShow ? false : true
          }
        >
          <BsChevronLeft className="text-sm font-semibold" />
        </button>
        <div>
          <Slider ref={sliderRef} {...settings}>
            {carousel?.map((item, index) => (
              <div key={index} className="px-2">
                {!item.duplicate && (
                  <div
                    key={index}
                    className="space-y-2 group cursor-pointer bg-white flex rounded-full space-x-2"
                  >
                    <div className="rounded-md overflow-hidden">
                      <img
                        className="h-10 w-10 object-cover group-hover:scale-105 duration-1000"
                        src={item.icon}
                        alt=""
                      />
                    </div>
                    <div className="text-left text-gray-text space-y-1">
                      <h1 className="text-sm text-black-primary font-semibold cursor-pointer duration-1000">
                        {item?.text?.length > 15
                          ? item?.text.substring(0, 10) + "..."
                          : item?.text}
                      </h1>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </Slider>
        </div>
        <button
          disabled={carousel?.length > settings.slidesToShow ? false : true}
          className={`rounded-full absolute right-0 top-2 md:top-1/4 z-10 p-2 hover:bg-[#F7F7F7] shadow-lg text-black-primary bg-white  
           text-xl cursor-pointer`}
          onClick={() => sliderRef.current.slickNext()}
        >
          <BsChevronRight className="text-sm" />
        </button>
      </section>
    </div>
  );
};
