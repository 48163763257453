import React, { memo } from "react";

// Libraries
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

// Components
import SocialIcons from "../SocialIcon/SocialIcons";
import FooterMenu, { Footer } from "./Footer";

//Data
import FooterData from "./FooterData";

const iconData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#828282",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#828282",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#828282",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];
const FooterStyle06 = (props) => {
  return (
    <Footer
      theme={props.theme}
      className={`${props.className}${props.theme ? ` ${props.theme}` : ""}`}
    >
      <div className="py-[5%] lg:py-[8%] md:py-[50px] sm:py-[50px]">
        <Container>
          <Row md={4} className="justify-center gap-y-10">
            <FooterMenu
              data={FooterData.slice(0, 4)}
              md={3}
              sm={6}
              className="xl:px-[15px] md:mb-0 xs:mb-[25px] last:mb-0"
              titleClass="uppercase"
            />
          </Row>
        </Container>
      </div>
    </Footer>
  );
};

FooterStyle06.defaultProps = {
  data: FooterData,
  logo: "/assets/img/webp/logo-white.webp",
};

FooterStyle06.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};

export default memo(FooterStyle06);
