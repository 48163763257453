import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

function HomePage() {
  let autocomplete;
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        {
          types: ["address"],
          componentRestrictions: { country: ["US"] },
          fields: ["place_id", "geometry", "name", "formatted_address"],
        }
      );
      autocomplete.addListener("place_changed", onPlaceChanged);
    }
  }, []);

  function onPlaceChanged() {
    var place = autocomplete.getPlace();
    if (!place.geometry) {
      document.getElementById("autocomplete").placeholder = "Enter a place";
    } else {
      console.log("Place Selected: " + JSON.stringify(place));
      setOptions([{ value: place.formatted_address }]);
    }
  }

  const handleSearch = (value) => {
    // Placeholder for search handling logic
  };

  return (
    <div className="App">
      <h1>Address Autocomplete Form</h1>
      <AddressAutocomplete />
    </div>
  );
}

export default HomePage;

const AddressAutocomplete = () => {
  const autocompleteInput = useRef(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [addressFields, setAddressFields] = useState({
    street_number: "",
    route: "",
    locality: "",
    administrative_area_level_1: "",
    postal_code: "",
    country: "",
  });

  useEffect(() => {
    if (!window.google) {
      console.error("Google Maps JavaScript API not loaded");
      return;
    }

    const autocompleteInstance = new window.google.maps.places.Autocomplete(
      autocompleteInput.current,
      { types: ["geocode"] }
    );

    autocompleteInstance.addListener("place_changed", () => {
      const place = autocompleteInstance.getPlace();
      fillInAddress(place);
    });

    setAutocomplete(autocompleteInstance);
  }, []);

  const fillInAddress = (place) => {
    const addressComponents = {};

    for (let component of place.address_components) {
      const addressType = component.types[0];
      addressComponents[addressType] = component.long_name;
    }

    setAddressFields({
      street_number: addressComponents.street_number || "",
      route: addressComponents.route || "",
      locality: addressComponents.locality || "",
      administrative_area_level_1:
        addressComponents.administrative_area_level_1 || "",
      postal_code: addressComponents.postal_code || "",
      country: addressComponents.country || "",
    });
  };

  return (
    <Box sx={{ "& .MuiTextField-root": { m: 1, width: "25ch" } }}>
      <TextField label="Enter your address" inputRef={autocompleteInput} />
      <TextField
        label="Street Number"
        value={addressFields.street_number}
        disabled
      />
      <TextField label="Street Name" value={addressFields.route} disabled />
      <TextField label="City" value={addressFields.locality} disabled />
      <TextField
        label="State"
        value={addressFields.administrative_area_level_1}
        disabled
      />
      <TextField
        label="Postal Code"
        value={addressFields.postal_code}
        disabled
      />
      <TextField label="Country" value={addressFields.country} disabled />
    </Box>
  );
};
