import React from "react";

const OrderTime = ({ unixTimestamp }) => {
  // Convert Unix timestamp to milliseconds
  const date = new Date(unixTimestamp);

  // Format the time in HH:MM AM/PM
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 24h to 12h format and handle midnight
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return <span>{`${formattedHours}:${formattedMinutes} ${ampm}`}</span>;
};

export default OrderTime;
