import React from "react";

const RoundBtn = ({
  text,
  bgcolor,
  color,
  icon,
  bordercolor,
  width,
  bgHover,
  height,
  onClick,
}) => {
  return (
    <div>
      <button
        onClick={onClick}
        style={{
          backgroundColor: bgcolor,
          color,
          width,
          height,
          borderColor: bordercolor,
          hover: bgHover,
        }}
        className={`rounded-full px-2.5 py-1.5 bg-${bgcolor} border-${bordercolor} h-${height} w-${width} hover:${bgHover} font-bold items-center text-xs xs:text-sm flex justify-center`}
      >
        <span className="space-x-2 items-center flex align-middle">
          {icon}
          {text}
        </span>
      </button>
    </div>
  );
};

export default RoundBtn;
