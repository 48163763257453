import React, { useState } from "react";
import Switcher11 from "../../Components/Button/toggleBtn";
import { ASSETS } from "../../Assets/path";
import RoundBtn from "../../Components/Button/roundBtn";
import { BsFacebook } from "react-icons/bs";
import { AiFillApple, AiOutlineClose } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import InputField from "../../Components/inputField";
import { MdCancel } from "react-icons/md";

const SignIn = ({ onRequestClose }) => {
  const [signInModal, setSignInModal] = useState(true);
  const [input, setInput] = useState("");
  const handleToggle = (type) => {
    if (type === "signIn") {
      setSignInModal(true);
    } else {
      setSignInModal(false);
    }
  };
  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 z-20 bg-opacity-50 flex justify-center items-center"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.65)",
      }}
    >
      <div className="absolute bg-white top-2 rounded-2xl shadow-lg w-80 xl:w-[34rem] overflow-y-scroll scroll-px-1 overflow-hidden">
        {/* heading  */}
        <div className="p-4 mt-2 rounded-lg space-y-2 bg-white overflow-hidden fixed w-80 xl:w-[30rem]">
          <AiOutlineClose
            className="text-xl cursor-pointer"
            fill="black"
            onClick={onRequestClose}
          />
          <h1 className="xl:text-3xl text-2xl font-bold">Sign in or Sign up</h1>
        </div>
        {signInModal ? (
          <>
            {/* sign in form  */}
            <div className="mt-24" style={{ maxHeight: "calc(130vh - 130px)" }}>
              {/* color div  */}
              <div className="bg-[#ECFCFC] mt-2 absolute right-0 left-0 flex items-center justify-center space-x-2 p-2 w-full">
                <span>
                  <img src={ASSETS.SPARKLE} alt="Sparkle" />
                </span>
                <span className="text-sm font-normal">
                  Sign in to access your credits and discounts
                </span>
              </div>
              <div className="mt-20 space-y-5 p-5">
                {/* toogle button  */}
                <div className="text-center mt-20">
                  <Switcher11
                    text1="Sign In"
                    text2="Sign Up"
                    height={32}
                    toggle1={() => handleToggle("signIn")}
                    toggle2={() => handleToggle("signUp")}
                  />
                </div>
                {/* socail media buttons  */}
                <div className="space-y-2 flex items-center justify-center flex-col">
                  <RoundBtn
                    text="Continue with Google"
                    bgcolor="#4285F6"
                    color="white"
                    width={350}
                    height={40}
                    icon={
                      <div className="rounded-full bg-white p-1 mr-2">
                        <FcGoogle className="text-lg" />
                      </div>
                    }
                  />
                  <RoundBtn
                    text="Continue with Facebook"
                    bgcolor="#3B5998"
                    color="white"
                    width={350}
                    height={40}
                    icon={<BsFacebook fill="white" className="text-lg mr-2" />}
                  />
                  <RoundBtn
                    text="Continue with Apple"
                    bgcolor="black"
                    color="white"
                    width={350}
                    height={40}
                    icon={<AiFillApple fill="white" className="text-xl mr-2" />}
                  />
                </div>
                {/* break  point*/}
                <div className="flex items-center justify-center my-3">
                  <hr className="text-[#C4C4C4] w-40"></hr>
                  <span className="text-sm mx-3 text-[#767676]">
                    or continue with email
                  </span>
                  <hr className="text-[#C4C4C4] w-40"></hr>
                </div>
                {/* email input  */}

                <div className="flex flex-col space-y-2">
                  <label className="font-bold text-base">Email</label>
                  <div className="relative rounded-md bg-[#F7F7F7]">
                    <input
                      type="email"
                      onChange={(e) => setInput(e.target.value)}
                      value={input}
                      className={`bg-transparent active:bg-transparent active:outline-none focus:outline-none focus:bg-transparent p-2 rounded-md text-[#606060]`}
                      placeholder="Required"
                    />
                    <span
                      onClick={() => setInput("")}
                      className="absolute right-2 top-3 cursor-pointer"
                    >
                      <MdCancel className="text-lg" />
                    </span>
                  </div>
                </div>
                {/* sign in button  */}
                <div className="space-y-2 pt-10">
                  <RoundBtn
                    text="Continue to Sign In"
                    bgcolor="#EB1700"
                    color="white"
                    width={500}
                    height={40}
                  />
                  <p className="text-sm text-[#767676] font-medium">
                    By continuing with the sign in process, we may send you a
                    one-time verification code via text message to the phone
                    number associated with your account. <br></br>Message and
                    data rates may apply.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* sign up form  */}
            <div style={{ maxHeight: "calc(100vh - 130px)" }}>
              <form className="space-y-4 mt-28 p-2">
                {/* toggle button  */}

                <div className="text-center mt-10">
                  <Switcher11
                    text1="Sign In"
                    text2="Sign Up"
                    height={32}
                    toggle1={() => handleToggle("signIn")}
                    toggle2={() => handleToggle("signUp")}
                  />
                </div>
                {/* input fields  */}
                <div className="p-3 mr-2">
                  <div className="flex space-x-2 items-center w-full">
                    <InputField label="First Name" type="text" width={245} />
                    <InputField label="Last Name" type="text" width={245} />
                  </div>
                  <span>
                    <InputField label="Email" type="email" />
                  </span>
                  <div className="flex space-x-2">
                    <InputField label="Country" />
                    <InputField label="Phone Number" />
                  </div>
                  <span>
                    <InputField
                      label="Password"
                      type="password"
                      password={true}
                    />
                  </span>
                </div>

                {/* paragraph  */}
                <p className="text-base font-medium p-2 mr-5">
                  By tapping “Sign Up” or “Continue with Google, Facebook, or
                  Apple,” you agree to DoorDash’s{" "}
                  <a className="text-[#3344DD] underline">
                    Terms and Conditions
                  </a>{" "}
                  and <a className="text-[#3344DD] underline">Privacy Policy</a>
                  .
                </p>
                {/* sign up button  */}
                <RoundBtn
                  text="Sign Up"
                  bgcolor="#EB1700"
                  color="white"
                  width={500}
                  height={40}
                />
              </form>
              {/* break point  */}
              <div className="flex items-center justify-center my-3">
                <hr className="text-[#C4C4C4] w-52"></hr>{" "}
                <span className="text-sm mx-4 text-[#767676]">or</span>
                <hr className="text-[#C4C4C4] w-52"></hr>
              </div>
              {/* socail media buttons  */}
              <div className="space-y-2 flex items-center justify-center flex-col mb-3">
                <RoundBtn
                  text="Continue with Google"
                  bgcolor="#4285F6"
                  color="white"
                  width={350}
                  height={40}
                  icon={
                    <div className="rounded-full bg-white p-1 mr-2">
                      <FcGoogle className="text-lg" />
                    </div>
                  }
                />
                <RoundBtn
                  text="Continue with Facebook"
                  bgcolor="#3B5998"
                  color="white"
                  width={350}
                  height={40}
                  icon={<BsFacebook fill="white" className="text-lg mr-2" />}
                />
                <RoundBtn
                  text="Continue with Apple"
                  bgcolor="black"
                  color="white"
                  width={350}
                  height={40}
                  icon={<AiFillApple fill="white" className="text-xl mr-2" />}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SignIn;
