import OrderStatusSlider from "./OrderStatusSlider";
import { useLocation, useNavigate } from "react-router-dom";

const OrderStatusPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(JSON.stringify(location.state));
  return (
    <div>
      <h1>OrderStatusPage</h1>
      <OrderStatusSlider />
    </div>
  );
};

export default OrderStatusPage;
