import {
  Paper,
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import SelectionsPopup from "./SelectionsPopup";
import { v4 as uuidv4 } from "uuid";

const initReqSelection = () => [
  {
    name: "Default Selection:",
    min: 0,
    max: 1,
    items: [
      { name: "Option 1", modifier: +1.5 },
      { name: "Option 2", modifier: -2.5 },
    ],
  },
];
const initOptSelection = () => [
  {
    name: "Default Selection:",
    min: 0,
    max: 1,
    items: [
      { name: "Option 1", modifier: +1.5 },
      { name: "Option 2", modifier: -2.5 },
    ],
  },
];

export function AddMenuItem({ handleNewItem }) {
  const [itemName, setItemName] = useState("");
  const [itemDesc, setItemDesc] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [reqSelction, setReqSelection] = useState(initReqSelection);
  const [optSelection, setOptSelection] = useState(initOptSelection);

  const [salesError, setSalesError] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openReq, setOpenReq] = useState(false);
  const [openOpt, setOpenOpt] = useState(false);

  const [image, setImage] = useState(null);
  const reinitializeStates = () => {
    setItemName("");
    setItemDesc("");
    setItemPrice("");
    setReqSelection(initReqSelection);
    setOptSelection(initOptSelection);
    setSalesError(false);
    //setOpen...
    setImage(null);
  };
  const s = {
    btn: {
      width: 90,
      height: 30,
      margin: "auto",
      border: 10,
    },
    text: {
      color: "Black",
      fontSize: 11,
      fontWeight: 800,
      padding: 2,
      textAlign: "center",
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 500,
      height: 330,
      backgroundColor: "white",
      border: "2px solid grey",
      p: 4,
      borderRadius: "10px",
    },
    bgBox: {
      backgroundColor: "#42f39a",
      border: "2px solid grey",
      padding: 10,
      borderRadius: "10px",
    },
    innerTextField: {
      height: "95%",
      width: "99%",
      border: 0,
      outline: "none",
      resize: "none",
      margin: "auto",
      padding: 5,
    },
    topRightBox: {
      backgroundColor: "#42f39a",
      border: "2px solid grey",
      borderRadius: "10px",
      fontSize: 14,
    },
  };
  const handleSubmit = () => {
    const isOnlyDigits = (string) => {
      for (let i = 0; i < string.length; i++) {
        var ascii = string.charCodeAt(i);
        if (ascii < 48 || ascii > 57) {
          if (ascii != 46) {
            return false;
          }
        }
      }
      return true;
    };
    if (isOnlyDigits(itemPrice) && itemName.length > 0) {
      handleClose();
      const item = {
        name: itemName,
        description: itemDesc,
        price: itemPrice,
        img: image,
        imageId: uuidv4(),
        requiredSelection: reqSelction,
        optionalSelection: optSelection,
      };
      handleNewItem(item);
    } else {
      setSalesError(true);
      setItemPrice("");
    }
    reinitializeStates();
  };
  const handleOptSelectionUpdate = (selections) => {
    setOptSelection(selections);
  };
  const handleReqSelectionUpdate = (selections) => {
    setReqSelection(selections);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <div style={s.btn}>
      <Paper onClick={handleOpen}>
        <p style={s.text}>Add Item</p>
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={s.modal}>
          <Grid container spacing={1} columns={16}>
            <Grid item xs={5.5}>
              <Box
                style={{
                  ...s.bgBox,
                  height: 130,
                  width: 130,
                  marginLeft: 10,
                  marginTop: 10,
                  marginRight: 10,
                }}
              >
                <input
                  type="file"
                  onChange={handleImageChange}
                  accept="image/*"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    width: 103,
                    height: 103,
                    cursor: "pointer",
                  }}
                />
                {image ? (
                  <img
                    src={image}
                    alt="Uploaded Preview"
                    style={{ cursor: "pointer", width: 103, height: 103 }}
                  />
                ) : (
                  <div
                    style={{
                      border: "1px dashed black",
                      width: 103,
                      height: 103,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Click to upload
                  </div>
                )}
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              <Grid
                container
                spacing={1}
                columns={16}
                style={{ paddingTop: 10, paddingRight: 10 }}
              >
                <Grid item xs={8}>
                  <Paper style={{ ...s.topRightBox }} onClick={handleSubmit}>
                    <p style={{ ...s.text, fontSize: 15 }}>Submit!</p>
                  </Paper>
                </Grid>
                <Grid item xs={8}>
                  <div style={{ paddingRight: 10 }}>
                    <FormControl
                      fullWidth
                      sx={{ m: 1, height: 40 }}
                      variant="filled"
                      value={itemPrice}
                      onChange={(e) => setItemPrice(e.target.value)}
                    >
                      {salesError ? (
                        <InputLabel
                          error
                          htmlFor="filled-adornment-amount"
                          sx={{ m: 1, height: 30 }}
                        >
                          Error
                        </InputLabel>
                      ) : (
                        <InputLabel
                          htmlFor="filled-adornment-amount"
                          sx={{ m: 1, height: 30 }}
                        >
                          Sales Price
                        </InputLabel>
                      )}

                      <FilledInput
                        id="filled-adornment-amount"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={16}>
                  <TextField
                    id="outlined-password-input"
                    label="Item Name"
                    fullWidth
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                    sx={{
                      "& .MuiInputBase-root": {
                        borderTop: 10,
                        borderColor: "white",
                        height: 40,
                        padding: 1,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: "grey",
                        fontSize: 13,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Box style={s.topRightBox}>
                    <Box style={{ ...s.text, padding: 1 }}>
                      <SelectionsPopup
                        key={"ReqPopup"}
                        selections={reqSelction}
                        title={"Required Selection"}
                        selectionUpdate={(e) => setReqSelection(e)}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box style={s.topRightBox}>
                    <Box style={{ ...s.text, padding: 1 }}>
                      <SelectionsPopup
                        key={"OptPopup"}
                        selections={optSelection}
                        title={"Optional Selection"}
                        selectionUpdate={(e) => setOptSelection(e)}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={16}>
              <Box
                style={{
                  ...s.bgBox,
                  height: 130,
                  marginLeft: 10,
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                <Paper style={{ height: "100%" }}>
                  <TextField
                    label="Item Description"
                    multiline
                    variant="standard"
                    rows={4}
                    style={s.innerTextField}
                    value={itemDesc}
                    InputProps={{
                      disableUnderline: true,
                      color: "white",
                    }}
                    shrink={true}
                    InputLabelProps={{
                      style: {
                        color: "grey",
                        paddingLeft: 10,
                        paddingTop: 5,
                        fontSize: 17,
                      },
                    }}
                    onChange={(e) => setItemDesc(e.target.value)}
                  />
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
