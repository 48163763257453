import React from "react";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

const PlusMinusBtn = ({
  onChange,
  onMinusClick,
  onPlusClick,
  total,
  text,
  addCart,
  addCartOnClick,
  value,
}) => {
  return (
    <div className="flex space-x-3 items-center justify-end">
      <div className="flex justify-between space-x-5 items-center">
        <span
          onClick={onMinusClick}
          className={`text-xl ${
            value > 1 ? "cursor-pointer" : "cursor-not-allowed text-gray-text"
          }`}
        >
          <FiMinusCircle />
        </span>
        <span>
          <input
            type="number"
            placeholder=""
            value={value}
            onChange={onChange}
            min="0"
            onInput={(e) => {
              let inputValue = e.target.value;
              if (inputValue === "" || parseInt(inputValue) === 0) {
                e.target.value = "";
              } else {
                e.target.value = Math.max(0, parseInt(inputValue) || 0);
              }
            }}
            style={{ appearance: "textfield" }}
            className="bg-[#F7F7F7] rounded-md p-2 w-16 text-center"
          />
        </span>
        <span className="text-xl cursor-pointer">
          <FiPlusCircle onClick={onPlusClick} />
        </span>
      </div>
      {addCart && (
        <div>
          <button
            className={`rounded-full px-5 py-2 text-white bg-[#EB1700] font-bold items-center text-md flex justify-between`}
            onClick={addCartOnClick}
          >
            <span className="mr-1">{text}</span>
            <span className="">- $ {total}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default PlusMinusBtn;
