const HeaderData = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Register",
    link: "/register",
  },
  {
    title: "Login",
    link: "/login",
  },
];

export default HeaderData;
