export const MEALS_LIST = {
  1: {
    CATEGORY: "Under $1 Delivery Fee",
    LIST: [
      {
        category: "Sandwitches",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/569c3087-2031-4f16-a02f-30328ca38ffe.jpg",
        name: "Village Quesadilla",
        distance: "0.7 mi",
        time: "36 min",
        desc: "Chocolate chip cookie, Salads, Onion Rings",
        rating: "4.4",
        rating2: "(19 ratings)",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "Appetizers", text: "Appetizers" },
          { id: "ColdDrink", text: "Cold Drink" },
          { id: "HotQuesadilla", text: "Hot Quesadilla" },
          { id: "Desert", text: "Desert" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/a586db31-35da-4408-9e61-73193e8c2f60.png",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/84f00197-dbe2-4703-943d-90754f65b845-retina-large.jpeg",
            name: "Chicken Quesadilla",
            desc: "Hot off the griddle, mouthwatering quesadilla made with Chicken, a fine blend of cheeses, peppers, and onions.",
            price: 20.99,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/637bb981-3e97-4955-b26b-5e93e7a751fe-retina-large.jpeg",
            name: "Steak Quesadilla",
            desc: "Hot off the griddle, mouthwatering quesadilla made with Steak, a fine blend of cheeses, peppers, and onions.",
            price: 20.99,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/4e1ae9b8-239e-4d35-a528-7d720040004d-retina-large.jpeg",
            name: "Chicken & Steak Combo Quesadilla",
            desc: "Hot off the griddle, mouthwatering quesadilla made with Chicken & Steak, a fine blend of cheeses, peppers, and onions.",
            price: 24.99,
          },
          {
            id: "Appetizers",
            menu: "Appetizers",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/be4faf44-53c5-4873-9b26-ba18964899f0-retina-large.jpeg",
            name: "Curly Fries",
            desc: "Curled Golden-crispy fries salted to perfection.",
            price: 10.99,
          },
          {
            id: "Appetizers",
            menu: "Appetizers",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/ae1248a5-3c02-4b7a-8f30-94686f60d6cb-retina-large.jpeg",
            name: "Spicy Steak Quesadilla",
            desc: "Hot off the griddle, mouthwatering quesadilla made with, Spicy Steak a fine blend of cheeses, peppers, and onions. Topped with a house special spicy sauce.",
            price: 21.99,
          },
          {
            id: "ColdDrink",
            menu: "Cold Drink",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/544ae263-cf26-4ca4-b191-c8ddfc085162-retina-large.jpeg",
            name: "Mozzarella Sticks",
            desc: "5 pieces of Melted mozzarella cheese sticks battered and fried to perfection. Served with celery and carrots.",
            price: 15.99,
          },
          {
            id: "ColdDrink",
            menu: "Cold Drink",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8d0db486-f307-4c4d-8528-48cba6ecaecb-retina-large.jpeg",
            name: "BBQ Chicken Quesadilla",
            desc: "Hot off the griddle, mouthwatering quesadilla made with BBQ chicken, a fine blend of cheeses, peppers, and onions. Topped with a house special BBQ sauce.",
            price: 21.99,
          },
          {
            id: "Hot Quesadilla",
            menu: "HotQuesadilla",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/5daf0251-bc5a-41aa-a4bf-a8317ef0a66f-retina-large.jpeg",
            name: "Cheese Quesadilla",
            desc: "Hot off the griddle, mouthwatering quesadilla made with a fine blend of cheeses, peppers, and onions.",
            price: 18.99,
          },
          {
            id: "Hot Quesadilla",
            menu: "Desert",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/9e739656-a6a1-4371-afca-c9c95b86c57b-retina-large.jpeg",
            name: "Chocolate Mousse",
            desc: "Smooth & Shiny melted chocolate dessert topped with whipped cream.",
            price: 15.99,
          },
          {
            id: "HotQuesadilla",
            menu: "Hot Quesadilla",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/df464509-b26d-474c-971a-e2131b42e0e2-retina-large.jpeg",
            name: "Veggie Quesadilla",
            desc: "Hot off the griddle, mouthwatering quesadilla made with a fine blend of cheeses, peppers, mushrooms, olives, and onions.",
            price: 20.99,
          },
          {
            id: "Desert",
            menu: "Desert",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/f18d8583-5811-483b-ae4f-ea1e6635bbb8-retina-large.jpeg",
            name: "Red Velvet Cake",
            desc: "A creamy layered red velvet cake made with real cream cheese.",
            price: 15.99,
          },
        ],
      },

      {
        category: "Healthy",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/8fe80b2b-bdf7-4221-89c5-7967d3de77f1.png",
        name: "Pasha's Halal Food",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/6a084f57-2f3f-418a-8c57-ded647cdd301.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "RicePlatters", text: "Rice Platters" },
          { id: "Gyros", text: "Gyros" },
          { id: "Extras", text: "Extras" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/35b4fd87-7eae-4134-b592-e75a63add355-retina-large.jpg",
            name: "Combo over Rice",
            desc: "A classic dish, grilled chicken and lamb, with basmati rice, cilantro, onions, and sauce.",
            price: 10.99,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/bcef55d5-2aac-4912-8664-e91684c7d5ab-retina-large.jpg",
            name: "Chicken over Rice",
            desc: "Our specialty grilled chicken topped with grilled onions & cilantro, on top of fresh basmati rice, and a side salad of lettuce, and red cabbage.",
            price: 9.99,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/photosV2/7d6b42bc-568b-4b9a-bc29-04598d506f64-retina-large.jpg",
            name: "Lamb over Rice",
            desc: "Grilled lamb, with basmati rice, cilantro, onions, and sauce.",
            price: 9.99,
          },
          {
            id: "RicePlatters",
            menu: "Rice Platters",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/35b4fd87-7eae-4134-b592-e75a63add355-retina-large.jpg",
            name: "Combo over Rice",
            desc: "A classic dish, grilled chicken and lamb, with basmati rice, cilantro, onions, and sauce.",
            price: 10.99,
          },
          {
            id: "RicePlatters",
            menu: "Rice Platters",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/bcef55d5-2aac-4912-8664-e91684c7d5ab-retina-large.jpg",
            name: "Chicken over Rice",
            desc: "Our specialty grilled chicken topped with grilled onions & cilantro, on top of fresh basmati rice, and a side salad of lettuce, and red cabbage.",
            price: 9.99,
          },
          {
            id: "RicePlatters",
            menu: "Rice Platters",
            img: "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/photosV2/7d6b42bc-568b-4b9a-bc29-04598d506f64-retina-large.jpg",
            name: "Lamb over Rice",
            desc: "Grilled lamb, with basmati rice, cilantro, onions, and sauce.",
            price: 9.99,
          },
          {
            id: "Gyros",
            menu: "Gyros",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/35b4fd87-7eae-4134-b592-e75a63add355-retina-large.jpg",
            name: "Combo over Rice",
            desc: "A classic dish, grilled chicken and lamb, with basmati rice, cilantro, onions, and sauce.",
            price: 10.99,
          },
          {
            id: "Gyros",
            menu: "Gyros",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/bcef55d5-2aac-4912-8664-e91684c7d5ab-retina-large.jpg",
            name: "Chicken over Rice",
            desc: "Our specialty grilled chicken topped with grilled onions & cilantro, on top of fresh basmati rice, and a side salad of lettuce, and red cabbage.",
            price: 9.99,
          },
          {
            id: "Gyros",
            menu: "Gyros",
            img: "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/photosV2/7d6b42bc-568b-4b9a-bc29-04598d506f64-retina-large.jpg",
            name: "Lamb over Rice",
            desc: "Grilled lamb, with basmati rice, cilantro, onions, and sauce.",
            price: 9.99,
          },
          {
            id: "Extras",
            menu: "Extras",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/35b4fd87-7eae-4134-b592-e75a63add355-retina-large.jpg",
            name: "Combo over Rice",
            desc: "A classic dish, grilled chicken and lamb, with basmati rice, cilantro, onions, and sauce.",
            price: 10.99,
          },
          {
            id: "Extras",
            menu: "Extras",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/bcef55d5-2aac-4912-8664-e91684c7d5ab-retina-large.jpg",
            name: "Chicken over Rice",
            desc: "Our specialty grilled chicken topped with grilled onions & cilantro, on top of fresh basmati rice, and a side salad of lettuce, and red cabbage.",
            price: 9.99,
          },
          {
            id: "Extras",
            menu: "Extras",
            img: "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/photosV2/7d6b42bc-568b-4b9a-bc29-04598d506f64-retina-large.jpg",
            name: "Lamb over Rice",
            desc: "Grilled lamb, with basmati rice, cilantro, onions, and sauce.",
            price: 9.99,
          },
        ],
      },
      {
        category: "Desert",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/3f0cd0f8-e251-4286-a423-0553aef1ef6f.jpg",
        name: "Stage Door Delicatessen",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/ab61207a-169f-4c54-9ffc-a8372b32e286.png",
        desc: "Stage Door Delicatessen is and will always be a New York City institution.",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },

      {
        category: "Coffee",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/160d7ff7-4f81-46ce-8ec8-8f06cbb8f472.jpg",
        name: "Blank Street Coffee",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/0e1f2807-8a58-4836-8c3a-420a6ed0a140.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
      },
      {
        category: "Smoothie",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/42ea23a8-a3a4-4c87-90af-8cc7b366cd03.jpg",
        name: "The Smoothie Squeeze",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/aa2cb96e-85b0-4c58-b2ad-fe763f804368.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },

      {
        category: "Pizza",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/1b91df9e-ad5b-42d5-aff5-4aa8187c62eb.jpg",
        name: "99 Cent Fresh Pizza",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/56be744f-fbe2-475b-979c-c8b26b1e07b6.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Breakfast",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/57ee4fcd-6878-4072-9e48-47a3fa96e552.JPG",
        name: "The Best of New York Food",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/e7e033f5-0105-46ab-8fe7-ba4a1219e9fc.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Pizza",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/2e649f70-91c8-4a07-813c-807d864fc621.jpg",
        name: "Little Italy Pizza",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/2539e8d0-073f-4809-b0f6-f84d032cc96f.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
      },
      {
        category: "Burger",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/14809e37-b14f-4f8b-9899-60c4303ab5f5.jpg",
        name: "Dirty Burger",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/2f32d7fd-bbcf-407c-bcac-8146bead1dec.jpg",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Fast Food",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/f2a10a55-7133-438a-bbb9-3a5c6d510d21.jpg",
        name: "Tribeca bagels",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/7579c31b-d730-455a-aa5d-13f1eb5fc992.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Pasta",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/d1e43781-6e36-44ea-a606-81f816958888.jpeg",
        name: "Pimp My Pasta",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/1de3108a-fee1-4cef-bd0c-1bfccd3e6d37.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Vegan",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/115749.jpg",
        name: "Empanada Mama",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/9595080b-abf5-4534-a667-469ee6ada7e3.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Pasta",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/b0e8cb4d-125d-4063-b709-4134bfc5a53a.png",
        name: "Benvenuto Cafe",
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/27aa0467-fdf5-4f2b-8178-c3d2dd3dd579.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Smoothie",
        image:
          "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/2b82a9b0-7556-4e84-ae1f-3ab9500d1d86-retina-large.jpg",
        name: "Green Blend",
        distance: "2.6 mi",
        time: "31 min",
        rating: 4.7,
        rating2: "(920+ ratings)",
        shop: "Salad Bar",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/7ccb8745-a736-48b4-8e71-25c589de639a.png",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/2b82a9b0-7556-4e84-ae1f-3ab9500d1d86-retina-large.jpg",
            name: "Create Your Own Smoothie",
            price: 10.95,
            desc: "Choose up to free 3 toppings",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
    ],
  },
  2: {
    CATEGORY: "Most Popular Local Restaurants",
    LIST: [
      {
        category: "Breakfast",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/92ecd866-e43c-4875-9dc8-aaa34850e6ed.jpg",
        name: "Toasty's Breakfast Burritos",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        distance: "0.2 mi",
        time: "13 min",
        rating: 4.7,
        rating2: "(460 ratings)",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/25a8e251-32b8-4034-8428-6921fad0d685.png",
        desc: "",
        open: "All Day",
        dishes: [
          {
            id: 1,
            menu: "Breakfast Burritos",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: 2,
            menu: "Breakfast Burritos",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: 3,
            menu: "Beverages",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: 4,
            menu: "Sides and More",
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: 5,
            menu: "Sides and More",
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: 6,
            menu: "Sides and More",
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            id: 7,
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            id: 8,
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: 9,
            menu: "Breakfast Burritos",
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: 10,
            menu: "Breakfast Burritos",
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: 11,
            menu: "Beverages",
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            id: 12,
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },

      {
        category: "Burger",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/3ed9a9df-f99f-40f6-93b9-ad59d51b46e9.JPG",
        name: "7th Street Burger",
        distance: 0.5,
        time: "13 min",
        rating: 5,
        rating2: "",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "Burgers", text: "Burgers" },
          // { id: "SidesandMore", text: "Sides and More" },
          // { id: "Beverages", text: "Beverages" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/30f78787-04eb-4798-bfc9-281a1bfbdf3c.jpg",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/1627ecdd-161e-4900-8fc0-e190cce8c38f-865524c6-7318-48ef-a274-9cde21fc4bec-retina-large.jpg",
            name: "Double Cheeseburger",
            desc: "Double patty, 100% fresh beef burger served with American cheese, grilled onions, pickles and house sauce on a Martin's potato bun",
            price: 11.21,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/c432cdc2-d64d-43b9-a163-2cb74566d870-02963947-1fb7-4ba1-8ffd-ab18a7a5633f-retina-large.jpg",
            name: "Fries",
            desc: "Grilled onions and house sauce served on fries",
            price: 5.31,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/f1464080-fecc-47ee-b41c-6b8ebc524c41-ec782898-0b09-458b-a3cd-9e13f63eb953-retina-large.jpg",
            name: "Cheeseburger",
            desc: "Single patty, 100% fresh beef burger served with American cheese, grilled onions, pickles and house sauce on a Martin's potato bun",
            price: 7.61,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/4598b2dd-e963-49c7-af51-c936e685b6a8-85aca9bb-c6cc-426b-9110-7240c6a42c21-retina-large.jpg",
            name: "Chopped Beef Fries",
            desc: "100% all beef burger chopped up with American cheese, grilled onions and house sauce served on fries",
            price: 5.31,
          },
          {
            id: "Burgers",
            menu: "Burgers",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/03c5b643-70fd-4dc2-9dd0-1274a6bc9a05-525918d1-6d50-4981-8be7-03fb828002c1-retina-large.jpg",
            name: "Impossible Burger (vegetarian)",
            desc: "Single Impossible patty served with American cheese, grilled onions, pickles and house sauce on a Martin's potato bun",
            price: 11.21,
          },
          {
            id: "Burgers",
            menu: "Burgers",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/36a0afad-0885-46ab-b4bf-5265a4f9fc16-32157fae-99b5-42ad-b7d5-dae06cb473d5-retina-large.JPG",
            name: "Spicy Chopped Beef Fries",
            desc: "100% all beef burger chopped up with American cheese, grilled onions and our spicy house sauce served on fries",
            price: 5.31,
          },
          {
            id: "Burgers",
            menu: "Burgers",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/f1464080-fecc-47ee-b41c-6b8ebc524c41-ec782898-0b09-458b-a3cd-9e13f63eb953-retina-large.jpg",
            name: "Cheeseburger",
            desc: "Single patty, 100% fresh beef burger served with American cheese, grilled onions, pickles and house sauce on a Martin's potato bun",
            price: 7.61,
          },
        ],
      },
      {
        category: "Desert",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/14ba6ba2-6e7f-43e3-b544-053c5624d009.58",
        name: "Levain Bakery",
        distance: 0.5,
        time: "13 min",
        rating: 5,
        rating2: "",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "Cookies", text: "Cookies" },
          { id: "Pastries", text: "Pastries" },
          // {id: "SavoryBreads", text: "Savory Breads" },
          // { id: "Beverages", text: "Beverages" },
          // {id: "Merchandise", text: "Merchandise" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/494da0ae-699d-4df0-a845-530a550f5a95.png",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photos/ee4282fe-fe73-41ef-a9d5-cf4750593815-retina-large-jpeg",
            name: "Chocolate Chip Walnut Cookie",
            desc: "Our signature cookie is packed with semi-sweet chocolate chips and chunks of walnuts so every single bite is as good as the one before. Huge, thick, and gooey but crisp on the outside.",
            price: 11.21,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1366,format=auto,quality=50/https://cdn.doordash.com/media/photos/8b584107-e4dd-4840-b8f8-613cc9652c0e-retina-large-jpeg",
            name: "Dark Chocolate Peanut Butter Chip Cookie",
            desc: "As if chocolate and peanut butter weren’t already the perfect combination, we mix our rich, extra-dark chocolate cookie dough with delectable nuggets of smooth peanut butter chips to create a cookie that is guaranteed to make your mouth water.",
            price: 5.31,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photos/b9a89da5-c95c-4901-bd13-e7c6c1380366-retina-large-jpeg",
            name: "Oatmeal Raisin Cookie",
            desc: "Huge mounds of oatmeal cookie – golden-brown on the outside yet delightfully soft on the inside – are crammed with plump baked raisins. Rich and buttery, these cookies are a decadent version of a traditional favorite.",
            price: 7.61,
          },
          {
            id: "Cookies",
            menu: "Cookies",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photos/68771548-8a5e-4300-8557-6f670aa51cbf-retina-large-jpeg",
            name: "Blueberry Muffin",
            desc: "Moist, sweet, and loaded with fresh blueberries, these muffins are among our most popular items.",
            price: 4.68,
          },
          {
            id: "Cookies",
            menu: "Cookies",
            img: "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1366,format=auto,quality=50/https://cdn.doordash.com/media/photos/8b584107-e4dd-4840-b8f8-613cc9652c0e-retina-large-jpeg",
            name: "Dark Chocolate Peanut Butter Chip Cookie",
            desc: "As if chocolate and peanut butter weren’t already the perfect combination, we mix our rich, extra-dark chocolate cookie dough with delectable nuggets of smooth peanut butter chips to create a cookie that is guaranteed to make your mouth water.",
            price: 5.31,
          },
          {
            id: "Cookies",
            menu: "Cookies",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photos/b9a89da5-c95c-4901-bd13-e7c6c1380366-retina-large-jpeg",
            name: "Oatmeal Raisin Cookie",
            desc: "Huge mounds of oatmeal cookie – golden-brown on the outside yet delightfully soft on the inside – are crammed with plump baked raisins. Rich and buttery, these cookies are a decadent version of a traditional favorite.",
            price: 7.61,
          },
          {
            id: "Cookies",
            menu: "Cookies",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photos/aa7fb5f5-b0a3-4334-8f4c-74a4b5291834-retina-large-jpeg",
            name: "Oatmeal Raisin Cookie",
            desc: "Amazingly delicious and moist, these treats are sure to make a scone lover out of anyone. An added bonus - these beauties are low in sugar!",
            price: 4.4,
          },
          {
            id: "Pastries",
            menu: "Pastries",
            img: "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1366,format=auto,quality=50/https://cdn.doordash.com/media/photos/8b584107-e4dd-4840-b8f8-613cc9652c0e-retina-large-jpeg",
            name: "Dark Chocolate Peanut Butter Chip Cookie",
            desc: "As if chocolate and peanut butter weren’t already the perfect combination, we mix our rich, extra-dark chocolate cookie dough with delectable nuggets of smooth peanut butter chips to create a cookie that is guaranteed to make your mouth water.",
            price: 5.31,
          },
          {
            id: "Pastries",
            menu: "Pastries",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photos/68771548-8a5e-4300-8557-6f670aa51cbf-retina-large-jpeg",
            name: "Blueberry Muffin",
            desc: "Moist, sweet, and loaded with fresh blueberries, these muffins are among our most popular items.",
            price: 4.68,
          },
          {
            id: "Pastries",
            menu: "Pastries",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photos/aa7fb5f5-b0a3-4334-8f4c-74a4b5291834-retina-large-jpeg",
            name: "Oatmeal Raisin Cookie",
            desc: "Amazingly delicious and moist, these treats are sure to make a scone lover out of anyone. An added bonus - these beauties are low in sugar!",
            price: 4.4,
          },
          {
            id: "Pastries",
            menu: "Pastries",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photos/ee4282fe-fe73-41ef-a9d5-cf4750593815-retina-large-jpeg",
            name: "Chocolate Chip Walnut Cookie",
            desc: "Our signature cookie is packed with semi-sweet chocolate chips and chunks of walnuts so every single bite is as good as the one before. Huge, thick, and gooey but crisp on the outside.",
            price: 11.21,
          },
        ],
      },

      {
        category: "Vegan",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/569b7b3f-6a80-4816-8138-5cdaf9b301ee.jpg",
        name: "Vegan Indian Company",
        distance: "3.6 mi",
        time: "45 min",
        rating: "4.3",
        rating2: "(459 ratings)",
        desc: "",
        shop: "Thai, Catering, Kid Friendly",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/49d4c6a7-557d-4b8c-9a68-c3b1ffcc4ee4.png",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/bf0897cc-243f-42f0-9da8-073432132e30-retina-large.jpeg",
            name: "Vegan Bread Pakora",
            price: 3.99,
            desc: "Vegan. Crispy bread fritters filled with tender potatoes and served with mint and tamarind chutneys.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/e4fedd51-f942-43ac-aaf5-41a62cb979c5-retina-large.jpg",
            name: "Vegan Vegetable Biryani w/ Bread & Drink",
            price: 20.99,
            desc: "Gluten free, vegan. Traditional Indian paneer cheese and hearty vegetables loaded onto fresh made spiced basmati rice. Choose your favorite roti/naan & drink to add with your meal!",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/36a1ab35-4a16-4e1e-8a7c-e428fd66286e-retina-large.jpeg",
            name: "Vegan Bhindi Masala",
            price: 15.99,
            desc: "Gluten free, vegan. Fresh okra sautéed with onions, tomatoes, and traditional ground spices and served with a side of vegan basmati rice.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/4e9c7b5f-9709-45bd-a5f4-df4f92368d32-retina-large.jpeg",
            name: "Vegan Aloo Gobhi",
            price: 14.99,
            desc: "Gluten free, vegan. Savory cauliflower stew prepared with tender potatoes and fresh ground spices and served with a side of vegan basmati rice.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/89eb6686-504d-4776-b029-09b263846461-retina-large.jpeg",
            name: "Vegan Aloo Paratha",
            price: 6.49,
            desc: "Thick and buttery flatbread filled with tender spiced potatoes served with a side of yogurt.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/5d6fa8e6-c506-4b73-81cc-3310b69c3b28-retina-large.jpg",
            name: "Vegan Yellow Dal (Gluten Free) w/ Bread & Drink",
            price: 20.99,
            desc: "Indulge in the exquisite flavors of our Vegan Yellow Dal, a comforting and wholesome dish that caters to both vegan and gluten-free diets. Made with love and care, our dal is a perfect blend of protein-rich lentils and aromatic spices, creating a harmonious symphony of taste in every spoonful. Choose your favorite roti/naan & drink to add with your meal!",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Soup",
        image:
          "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/e20581d1-93a9-48b0-878e-15c14320f879-59e55246-4a43-4837-b81d-bb0e7500f026-retina-large.jpg",
        name: "Veselka",
        distance: "1.5 mi",
        time: "19 min",
        rating: "4.8",
        rating2: "(3,300+ ratings) ",
        desc: "Since 1954, hungry New Yorkers have popped into Veselka in the East Village for affordable and scratch-made borscht, potato pancakes, stuffed cabbage, blintzes, chicken schnitzel and, of course, piero",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/4847cbde-cb31-43e2-a3ce-dfd7061c6f91.jpg",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/087b506e-c9c8-4d6a-9ab9-bbac21f6325c-6daaad76-6f22-4479-849b-488bd7c7f463-retina-large.JPG",
            name: "Ukrainian Borscht - Quart",
            price: 15.0,
            desc: "Traditional Ukrainian soup. Made with beet stock, roast pork, cabbage, potatoes and lima beans",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/17830cd6-032a-43a8-9ed3-5d98fb3b6b11-07184464-5c0e-4170-9c12-dd04b2d6ee1d-retina-large.JPG",
            name: "Mushroom Barley - Pint",
            price: 9.0,
            desc: "Our thick and creamy vegetarian mushroom barley soup.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/3f3a0cf6-7492-4a6e-a370-b747c0ccfbe3-6bdf3ec9-53ac-4398-9379-9b3795ba3844-retina-large.JPG",
            name: "Vegetable Soup - Quart",
            price: 15.0,
            desc: "Our delicious homemade vegetarian soup with cauliflower, carrots, potatoes and other vegetables. Vegan and gluten free.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/d97ca665-ff4e-4a51-813d-4397056510ab-9095c5b6-299a-4127-88b2-669be76483bc-retina-large.jpg",
            name: "Matzoh Ball - Quart",
            price: 15.0,
            desc: "Rich chicken stock with pulled chicken, vegetables and Matzoh dumplings",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },

      {
        category: "Bubble Tea",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/588b2426-947a-4639-9c27-127557a72b02.jpg",
        name: "HAWA SMOOTHIES & BUBBLE",
        distance: "1.8 mi        ",
        time: "21 min",
        rating: 5,
        rating2: "(22 ratings)",
        shop: "Juice Bars & Smoothies, Bubble Tea, Acai Bowls        ",
        logo: "",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/0ae977d8-ac31-4523-926d-5dd93abacfea-retina-large.JPG",
            name: "Acai Almond Butter Bowl",
            price: 13.95,
            desc: "Ingredients: acai, strawberries, banana, granola, mango, almond butter, almonds and chia seed.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/e845713e-f7f3-4b40-bf26-51c2eb2e971d-retina-large.JPG",
            name: "Strawberry Blueberry Banana Smoothie",
            price: 7.95,
            desc: "",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/50154c46-9028-47fa-82f3-03809eddc18b-retina-large.JPG",
            name: "Mango Pineapple Smoothie",
            price: 7.95,
            desc: "",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },

      {
        category: "Coffee",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/eb3a3f37-7a8b-49b6-ab0a-5c6f349024f1.jpg",
        name: "Dunkin'",
        distance: 0.5,
        time: "13 min",
        rating: 5,
        rating2: "",
        desc: "",
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/98851814-9e10-467f-8867-ec8586529624.png",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Desert",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/974b328c-6fb4-4353-a9bc-6f4df29b4c47.jpg",
        name: "Central Market New York",
        distance: 0.5,
        time: "13 min",
        rating: 5,
        rating2: "",
        desc: "Central Market New York has a proud, thirty-plus year history in Grand Central Terminal. With our fine-tuned knowledge of the commuter market, we are now pleased to refresh and refine the Central Mark",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/c7c56ebe-ad3d-484a-abb7-5c568f94ba23.png",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },

      {
        category: "Sandwitches",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/2595d9a0-7b80-4659-8034-37c9ffd5951b.jpg",
        name: "La Panineria",
        distance: "1.4 mi",
        shop: "Italian Sandwich Shop",
        time: "13 min",
        rating: "4.8",
        rating2: "(4,300+ ratings)",
        desc: "There’s just something about the food of Italy. Ice cream is transformed into magnificent gelato. Pizza morphs into a culinary art form. Even the humble sandwich is elevated to something just shy of miraculous. That’s the focus of La Panineria, an artisanal sandwich shop that channels the cobblestone streets of Rome.Every sandwich here is a beauty, whether you order it hot or cold. The Parma works magic with Prosciutto di Parma, Stracchino cheese, and fistfulls of spicy arugula. prosciutto cotto and fontina get together with fig jam and olive oil in the Portofino. The Americano turns the turkey sandwich sideways with tomatoes, fresh mozzarella, and a drizzle of really good extra virgin olive oil. Creamy burrata is a favorite, layered with Prosciutto di Parma, organic tomatoes, and arugula, glossed in grassy extra virgin olive oil. Beyond the sandwich board, they also serve a mean eggplant parm and a two-handed meatball hero, as well as pasta with Bolognese or pesto, and generous salads. It’s a taste of Italy you’ll love.",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/f5ab885d-1fc8-4077-b4e3-a49fce4883d7.ai-2",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Healthy",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/522c57d4-f611-4aa7-98ae-6dcddb65f5f9.jpg",
        name: "NAYA",
        distance: 0.5,
        time: "13 min",
        rating: 5,
        rating2: "",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/159d5b7b-3bd3-4dcb-b038-61447bbcdfe6.png",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/3e4087bd-b6c1-4a75-8e18-053421dbe085-a0bea9fc-5acc-4b60-b359-055f8cdf77c6-retina-large.JPG",
            name: "NAYA Bowl",
            desc: "Build-Your-Own: Vermicelli rice or ruby wild rice with your choice of protein, toppings and homemade sauces.",
            price: 0.0,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/bd92dcaa-f66e-44f8-8d98-52ebf1beb52a-efeb384e-2077-47e7-8b64-0ddc73a65bfc-retina-large.JPG",
            name: "NAYA Salad",
            desc: "Build-Your-Own: Romaine lettuce or Spinach, Arugula & Cabbage mix with your choice of protein, toppings and homemade sauces",
            price: 0.0,
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/3e4087bd-b6c1-4a75-8e18-053421dbe085-a0bea9fc-5acc-4b60-b359-055f8cdf77c6-retina-large.JPG",
            name: "NAYA Roll",
            desc: "Build-Your-Own: Daily baked pita bread with your choice of protein, toppings and homemade sauces.",
            price: 0.0,
          },
        ],
      },
      {
        category: "Bubble Tea",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/94962639-07b9-4b59-a651-dc7986f81803.jpeg",
        name: "Vivi Bubble Tea",
        distance: "0.2 mi",
        time: "13 min",
        rating: "4.7        ",
        rating2: "(278 ratings)",
        shop: "Bubble Tea",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/860e7de0-8aef-4434-951b-8f8068af17a5.png",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Chinese",
        image:
          "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/e20581d1-93a9-48b0-878e-15c14320f879-59e55246-4a43-4837-b81d-bb0e7500f026-retina-large.jpg",
        name: "Dos Toros Taqueria",
        distance: 0.5,
        time: "13 min",
        rating: 5,
        rating2: "",
        desc: "",
        logo: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
      {
        category: "Chinese",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/ebc9a84e-6468-42a7-8658-6e1183ab93bf.47",
        name: "Ming Wong Restaurant",
        distance: "0.5mi",
        time: "13 min",
        rating: "4.7",
        rating2: "(600+ ratings)",
        shop: "Congee, Fried Rice, Noodles",
        desc: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        logo: "https://img.cdn4dd.com/p/fit=contain,width=100,height=100,format=auto,quality=95/media/restaurant/cover_square/12ed95db-7fb7-4e3e-b847-4e4a513685af.jpg",
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/cdn-cgi/image/fit=contain,width=1920,format=auto,quality=50/https://cdn.doordash.com/media/photosV2/7e2f7f9f-8110-4fb4-838b-b7afd8239e86-retina-large.JPG",
            name: "Trip-Meat Combo Over Rice / 三拼飯",
            price: 15.0,
            desc: "",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/2232daa8-aeac-4c94-9147-262075d4e116-retina-large.JPG",
            name: "Double Combo Over Rice (雙拼飯)",
            price: 12.95,
            desc: "",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/0ac47171-dc85-463e-a9f2-52a17295c461-retina-large.JPG",
            name: "Shrimp Rice Noodle Roll / 鮮蝦腸粉",
            price: 5.0,
            desc: "",
          },
        ],
      },
      {
        category: "Acclaimed",
        image:
          "https://img.cdn4dd.com/cdn-cgi/image/fit=cover,width=1000,height=300,format=auto,quality=80/https://doordash-static.s3.amazonaws.com/media/store/header/34129.jpg",
        name: "Dos Toros Taqueria",
        distance: 0.5,
        time: "13 min",
        rating: 5,
        rating2: "",
        desc: "",
        logo: "",
        menu: [
          { id: "PopularItems", text: "Popular Items" },
          { id: "BreakfastBurritos", text: "Breakfast Burritos" },
          { id: "SidesandMore", text: "Sides and More" },
          { id: "Beverages", text: "Beverages" },
        ],
        dishes: [
          {
            id: "PopularItems",
            menu: "Popular Items",
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/33e4798e-33a2-463e-8eb0-7f97ff13ca53-retina-large.JPG",
            name: "Build Your Own Breakfast Burrito",
            price: 11.99,
            desc: "Build your burrito just how you like it.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 2,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/8f89a12e-fbdc-4a62-9cb3-51325dbc7421-retina-large.JPG",
            name: "Classic Breakfast Burrito",
            price: 10.99,
            desc: "Your choice of meat with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 3,
            img: "https://img.cdn4dd.com/p/fit=cover,width=600,height=300,format=auto,quality=50/media/photosV2/11cb9bb4-2672-4e55-ab78-8810ae82369f-retina-large.JPG",
            name: "Iced Coffee",
            price: 4.0,
            desc: "16 oz cup of fresh brewed iced coffee",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 4,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/d16d6cba-f83d-444a-a9ec-5172333f9628-retina-large.JPG",
            name: "Steak & Egg Breakfast Burrito",
            price: 13.99,
            desc: "Grilled steak, grilled onions, scrambled eggs, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 5,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/cadc09c5-585f-4393-be59-852975c236e8-retina-large.JPG",
            name: "Tater Tots",
            price: 3.99,
            desc: "Fresh and hot shredded potato tots",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 6,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/19718f52-6644-451d-b944-f46490b35969-retina-large.JPG",
            name: "Loaded Veggie Breakfast Burrito",
            price: 10.99,
            desc: "Grilled onions, mushrooms, and peppers with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 7,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/9311f918-2d0b-408b-8498-eca0f73d90d0-retina-large.JPG",
            name: "Spicy Chorizo Breakfast Burrito",
            price: 10.99,
            desc: "Scrambled eggs and chorizo, tots, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 8,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/46342990-a9f7-4008-a78c-32b792b92c45-retina-large.JPG",
            name: "Meat Eater Breakfast Burrito",
            price: 13.99,
            desc: "Bacon, ham, sausage with tots, scrambled eggs, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 9,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2c71a54b-fdc5-4b9b-8f96-6a622f5b93a9-retina-large.JPG",
            name: "Healthy Fresca Egg White Breakfast",
            price: 13.99,
            desc: "House-made Salsa Fresca, egg whites, fresh baby spinach, avocado, and grilled mushrooms.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 10,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/804ff535-30c8-4254-8924-08dfb2b49c92-retina-large.JPG",
            name: "Bagel Breakfast Sandwich",
            price: 8.99,
            desc: "Toasted bagel, egg, choice of meat, and cheese.",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 11,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/2971dde9-4d2d-4c92-a21c-52bdfbdfdbe5-retina-large.JPG",
            name: "Bottled Orange Juice",
            price: 3.5,
            desc: "Squeezed orange juice, bottled",
          },
          {
            id: "PopularItems",
            menu: "Popular Items",
            id: 12,
            img: "https://img.cdn4dd.com/p/fit=cover,width=150,height=150,format=auto,quality=50/media/photosV2/55f2f3d8-f684-4d04-a668-32fabd2d34ec-retina-large.JPG",
            name: "Bagel & Cream Cheese",
            price: 4.99,
            desc: "Toasted bagel and a thick smear of cream cheese",
          },
        ],
      },
    ],
  },
};
