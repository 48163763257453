import React from "react";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import PlusMinusBtn from "../Button/plusMinusBtn";

export const Popup = ({
  onRequestClose,
  content,
  heading,
  data,
  cartItem,
  addToCart,
}) => {
  const [addition, setAddition] = useState(data?.price);

  const [count, setCount] = useState(1);
  const onAddItems = (state) => {
    if (state === 1) {
      setAddition(addition + data?.price);
      setCount(count + 1);
    } else {
      setAddition(addition - data?.price);
      setCount(count - 1);
    }
  };
  function verifyCart() {
    // Check if every required selection has at least one item selected
    const hasAllRequired = () => {
      const isValid = cartItem.selectedOptions.every((option) => {
        // If the minimum requirement is 1, check if at least one item has a quantity > 0
        if (option.min === 1) {
          return option.selection.some((item) => item.quantity > 0);
        }
        // If the minimum requirement is not 1, no need to check the quantity
        return true;
      });

      return isValid;
    };

    if (hasAllRequired()) {
      console.log("Item looks good to me");
      // Add the quantity attribute to the cartItem
      const updatedCartItem = { ...cartItem, quantity: count };
      addToCart(updatedCartItem);
      onRequestClose();
    } else {
      console.log("Item doesn't look good to me");
    }
  }

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 z-20 bg-opacity-50 flex justify-center items-center"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.65)",
      }}
    >
      <div
        className="absolute bg-white top-2 rounded-t-2xl shadow-lg sm:w-[35rem] overflow-hidden"
        style={{ maxHeight: "calc(150vh - 130px)" }}
      >
        {/* heading  */}
        <div className="px-4 pt-3 mt-2 rounded-lg flex space-x-5 items-center bg-white overflow-hidden fixed sm:w-[35rem]">
          <AiOutlineClose
            className="text-xl cursor-pointer"
            fill="black"
            onClick={onRequestClose}
          />
          <h1 className="text-xl font-bold">{heading}</h1>
        </div>

        {/* form  */}

        <div
          className="mt-16 overflow-y-scroll scroll-px-1"
          style={{ maxHeight: "calc(95vh - 130px)" }}
        >
          {content}
        </div>
        {/* button  */}
        <div
          className="mt-16 overflow-y-scroll scroll-px-1 border-t-2 border-[#E7E7E7] bg-white p-5 rounded-b-2xl"
          style={{ marginTop: 0 }}
        >
          <PlusMinusBtn
            onChange={(e) =>
              setCount(Math.max(0, parseInt(e.target.value) || 0))
            }
            onMinusClick={() => {
              if (count >= 2) {
                onAddItems(0);
              }
            }}
            onPlusClick={() => onAddItems(1)}
            value={count}
            text="Add to cart"
            total={count * cartItem.currentPrice}
            addCart={true}
            addCartOnClick={verifyCart}
          />
        </div>
      </div>
    </div>
  );
};
