import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material/";
import Autocomplete from "@mui/lab/Autocomplete";
import { useToken, useUser } from "../AuthContext";
import Buttons from "../Components/Button/Buttons";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getmerchantInfo,
  updatemerchantInfo,
  uploadmerchantPictures,
  getUniquePlaceId,
} from "../Functions/APIs";

function MerchantPage() {
  const token = useToken();
  const user = useUser();
  const navigate = useNavigate();
  const addressInputRef = useRef(null);

  const [displayAddAddress, setDisplayAddAddress] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [description, setDescription] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [restaurantName, setRestaurantName] = React.useState("");
  const [bannerImage, setBannerImage] = React.useState(null);
  const [restaurantIconImage, setRestaurantIconImage] = React.useState(null);
  const [restaurantCardImage, setRestaurantCardImage] = React.useState(null);
  const [currentDay, setCurrentDay] = useState(null);
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [currentMenu, setCurrentMenu] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [orderManagerPwrd, setOrderManagerPwrd] = useState("0000");
  const [locations, setLocations] = useState([]);

  const [selectedDays, setSelectedDays] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });

  const dummyMenuNames = [
    "Breakfast Menu",
    "Lunch Menu",
    "Dinner Menu",
    "Special Menu",
  ];
  const categoryOptions = [
    "Pizza",
    "Burgers",
    "Fast Food",
    "Chicken",
    "Sushi",
    "Pho",
    "Chinese",
    "Mexican",
    "Italian",
    "Indian",
    "American",
    "Japanese",
    "Thai",
    "Mediterranean",
    "African",
    "Middle Eastern",
    "Greek",
    "Caribbean",
  ];
  function formatTime(timeString) {
    const [hours, minutes] = timeString
      .split(":")
      .map((str) => parseInt(str, 10));
    if (!hours && !minutes) return "";
    return `${
      hours > 12 || hours === 0 ? (hours === 0 ? 12 : hours - 12) : hours
    }:${minutes.toString().padStart(2, "0")} ${hours >= 12 ? "PM" : "AM"}`;
  }
  const openMenuSetter = (day) => {
    setCurrentDay(day);
    setModalOpen(true);
  };

  const addMenuForDay = (day) => {
    const uniqueId = Date.now();
    setSelectedDays((prev) => ({
      ...prev,
      [day]: [
        ...prev[day],
        { id: uniqueId, openTime, closeTime, menu: currentMenu },
      ],
    }));
    setOpenTime("");
    setCloseTime("");
    setCurrentMenu("");
    setModalOpen(false);
  };

  const deleteEntry = (day, entryId) => {
    setSelectedDays((prev) => ({
      ...prev,
      [day]: prev[day].filter((entry) => entry.id !== entryId),
    }));
  };
  const deleteLocation = (locationIndex) => {
    setLocations((prevLocations) =>
      prevLocations.filter((_, index) => index !== locationIndex)
    );
  };
  const addLocation = async (place) => {
    const response = await getUniquePlaceId(user.username, token);
    if (response && response.statusCode === "200") {
      setLocations((prevLocations) => [
        ...prevLocations,
        {
          ...place,
          gs_location_id: response.body.uniqueId,
        },
      ]);
    } else {
      console.error("Failed to get unique location ID", response);
    }
  };
  const handleImageChange = (e, setImage) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const getInfoAPI = async () => {
    const result = await getmerchantInfo(user.username, token);
    //console.log("Get Merchant Info call: " + JSON.stringify(result));

    if (result && result.statusCode == "200") {
      const data = result.body.payload.merchantInformation;
      if (data) {
        if (data.restaurantName) {
          setRestaurantName(data.restaurantName);
        }
        if (data.restaurantDescription) {
          setDescription(data.restaurantDescription);
        }
        if (data.restaurantCardImage) {
          setRestaurantCardImage(data.restaurantCardImage);
        }
        if (data.iconImage) {
          setRestaurantIconImage(data.iconImage);
        }
        if (data.bannerImage) {
          setBannerImage(data.bannerImage);
        }
        if (data.restaurantCategories) {
          setCategories(data.restaurantCategories);
        }
        if (data.restaurantAddresses) {
          setLocations(data.restaurantAddresses);
        }
        if (data.orderManagerPwrd) {
          setOrderManagerPwrd(data.orderManagerPwrd);
        }
        if (data.menuTimes) {
          setSelectedDays(data.menuTimes);
        }
      }
    }
  };
  const saveChangesAPI = async () => {
    console.log("Clicked Save Changes!");

    // Step 1: Extract Image Data
    let imagesToUpload = [];
    const getImageData = (imgSource, identifier) => {
      const currentDate = new Date(Date.now());
      const readableDate = `${currentDate.getFullYear()}-${
        currentDate.getMonth() + 1
      }-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;
      console.log(readableDate);
      // Outputs something like: "2023-10-25_13-45-30"

      if (imgSource && imgSource.startsWith("data:image")) {
        imagesToUpload.push({
          img: imgSource,
          imageId: user.username + readableDate + identifier,
        });
      }
    };
    getImageData(bannerImage, "-restaurantBanner");
    getImageData(restaurantCardImage, "-restaurantCard");
    getImageData(restaurantIconImage, "-restaurantIcon");

    // Step 2: Upload images and get http addresses
    let imageUrlArray = [];
    if (imagesToUpload.length > 0) {
      const response1 = await uploadmerchantPictures(
        user.username,
        imagesToUpload,
        token
      );
      imageUrlArray = JSON.parse(response1.body).imageUrls;
    }

    // Step 3: Replace local URIs with HTTP addresses
    const replaceWithHTTP = (imgId) => {
      const url = imageUrlArray.find((url) => url.includes(imgId));
      return url
        ? url
        : imgId.includes("Banner")
        ? bannerImage
        : imgId.includes("Card")
        ? restaurantCardImage
        : restaurantIconImage;
    };

    const updatedBannerImage = replaceWithHTTP("Banner");
    const updatedCardImage = replaceWithHTTP("Card");
    const updatedIconImage = replaceWithHTTP("Icon");

    // Step 4: Upload the updated information
    const data = {
      menuTimes: selectedDays,
      restaurantCategories: categories,
      restaurantName: restaurantName,
      restaurantDescription: description,
      restaurantAddresses: locations,
      orderManagerPwrd: orderManagerPwrd,
      bannerImage: updatedBannerImage,
      restaurantCardImage: updatedCardImage,
      iconImage: updatedIconImage,
    };
    console.log("Invoking merchant update with: " + JSON.stringify(data));
    const response2 = await updatemerchantInfo(user.username, data, token);

    // Optional: Update state once after all operations
    setBannerImage(updatedBannerImage);
    setRestaurantCardImage(updatedCardImage);
    setRestaurantIconImage(updatedIconImage);
  };

  useEffect(() => {
    getInfoAPI();

    if (!window.google) {
      console.error("Google Maps JavaScript API not loaded");
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
      addressInputRef.current
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      addLocation(place);
    });
  }, []);
  //console.log("locations: " + JSON.stringify(locations));
  return (
    <div>
      <h1>Merchant Page!</h1>
      <p style={{ fontSize: 10 }}>{JSON.stringify(token)}</p>
      <p style={{ fontSize: 10 }}>{JSON.stringify(user.username)}</p>

      <Typography variant="h5" gutterBottom>
        Restaurant Information
      </Typography>
      <Divider />
      {Object.entries(selectedDays).map(([day, menus]) => (
        <Card
          onClick={() => openMenuSetter(day)}
          style={{ margin: "10px", display: "inline-block", width: "200px" }}
        >
          <CardContent>
            <Typography variant="h5">{day}</Typography>
            {menus.map(({ id, openTime, closeTime, menu }) => (
              <div key={id}>
                <span>
                  {menu}: {formatTime(openTime)} - {formatTime(closeTime)}
                </span>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteEntry(day, id);
                  }}
                >
                  Delete
                </Button>
              </div>
            ))}
          </CardContent>
        </Card>
      ))}

      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <div
          style={{
            padding: "20px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
          }}
        >
          <h2>Set Menu for {currentDay}</h2>
          <TextField
            label="Open Time"
            type="time"
            value={openTime}
            onChange={(e) => setOpenTime(e.target.value)}
            inputProps={{ step: 900 }}
          />
          <TextField
            label="Close Time"
            type="time"
            value={closeTime}
            onChange={(e) => setCloseTime(e.target.value)}
            inputProps={{ step: 900 }}
          />
          <Autocomplete
            options={dummyMenuNames}
            value={currentMenu}
            onChange={(event, newValue) => setCurrentMenu(newValue)}
            renderInput={(params) => <TextField {...params} label="Menu" />}
          />
          <Button onClick={() => addMenuForDay(currentDay)}>Add Menu</Button>
        </div>
      </Modal>

      <Box marginTop={2}>
        <Button
          onClick={() => {
            navigate("/merchant/menus");
          }}
          variant="outlined"
        >
          Update Menu
        </Button>
      </Box>
      <Box marginTop={2}>
        <Autocomplete
          key={categoryOptions.length}
          multiple
          id="tags-outlined"
          options={categoryOptions}
          onChange={(event, newValue) => setCategories(newValue)}
          getOptionLabel={(option) => option}
          filterSelectedOptions
          value={categories} // This line binds the component's value to the categories state
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Restaurant Category"
              placeholder="Choose categories"
            />
          )}
        />
      </Box>

      <Box marginTop={2}>
        <TextField
          fullWidth
          variant="outlined"
          label="Restaurant Name"
          value={restaurantName}
          onChange={(e) => setRestaurantName(e.target.value)}
        />
      </Box>

      <Box marginTop={2}>
        <TextField
          fullWidth
          variant="outlined"
          label="Restaurant Description"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Box>
      <h1>Restaurant Locations:</h1>
      <TableContainer component={Paper}>
        <Table aria-label="addresses table">
          <TableHead>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell align="right">GS Location ID</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locations.map((location, index) => (
              <TableRow key={location.gs_location_id}>
                <TableCell component="th" scope="row">
                  {location.formatted_address}
                </TableCell>
                <TableCell align="right">{location.gs_location_id}</TableCell>
                <TableCell align="right">
                  <DeleteIcon onClick={() => deleteLocation(index)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box marginTop={2}>
        <TextField
          fullWidth
          variant="outlined"
          label="Address"
          inputRef={addressInputRef} // Use the ref here
          value={address}
          sx={{ display: displayAddAddress ? "block" : "none" }}
          onChange={(e) => setAddress(e.target.value)}
        />
      </Box>

      <Button
        onClick={() => {
          setDisplayAddAddress(!displayAddAddress);
        }}
      >
        Add Location
      </Button>
      <h3>Order Manager Password</h3>
      <Box marginTop={2}>
        <TextField
          fullWidth
          variant="outlined"
          label="Password"
          value={orderManagerPwrd}
          onChange={(e) => setOrderManagerPwrd(e.target.value)}
        />
      </Box>
      <Button
        onClick={() => {
          navigate("/order-manager");
        }}
      >
        Order Manager Page
      </Button>
      <Box marginTop={2}>
        <h1>Upload Images:</h1>

        <Box marginTop={2}>
          <Typography variant="subtitle2">
            Banner Image (1000px:300px)
          </Typography>
          <input
            type="file"
            onChange={(e) => handleImageChange(e, setBannerImage)}
            accept="image/*"
          />
          {bannerImage && (
            <img
              src={bannerImage}
              alt="Banner Preview"
              style={{ width: "1000px", height: "300px" }}
            />
          )}
        </Box>
        <Box marginTop={2}>
          <Typography variant="subtitle2">
            Restaurant Card Image (300px:168px)
          </Typography>
          <input
            type="file"
            onChange={(e) => handleImageChange(e, setRestaurantCardImage)}
            accept="image/*"
          />
          {restaurantCardImage && (
            <img
              src={restaurantCardImage}
              alt="Card Image Preview"
              style={{ width: "300px", height: "168px" }}
            />
          )}
        </Box>
        <Box marginTop={2}>
          <Typography variant="subtitle2">
            Restaurant Icon (100px:100px)
          </Typography>
          <input
            type="file"
            onChange={(e) => handleImageChange(e, setRestaurantIconImage)}
            accept="image/*"
          />
          {restaurantIconImage && (
            <img
              src={restaurantIconImage}
              alt="Icon Preview"
              style={{ width: "100px", height: "100px" }}
            />
          )}
        </Box>

        <Box marginTop={2}>
          {" "}
          <Buttons
            ariaLabel="link for swiper img"
            href="#"
            className="btn-fill font-medium font-serif rounded-none btn-shadow"
            size="lg"
            themeColor="#65a483"
            color="#cffbe2"
            title="Save Changes"
            onClick={() => {
              saveChangesAPI();
            }}
          />
        </Box>
      </Box>
    </div>
  );
}

export default MerchantPage;
