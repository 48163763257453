import React, { useState } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { createGroupOrder } from "../../Functions/APIs";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import EditIcon from "@mui/icons-material/Edit";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import { useLocation, useNavigate } from "react-router-dom";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [creditCard, setCreditCard] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editField, setEditField] = useState(null);

  const handleDialogOpen = (field) => {
    setEditField(field);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSave = () => {
    // Save logic goes here
    handleDialogClose();
  };

  const handleCreateGroupOrder = async () => {
    const result = await createGroupOrder(
      name,
      phoneNumber,
      address,
      email,
      location.state.cart,
      creditCard,
      location.state.gs_location_id || location.state.locationId,
      location.state.merchant_id,
      location.state.group_id
    );
    console.log("order response: " + JSON.stringify(result));
    //navigate("/order-status", { state: { ...location.state } });
  };

  const DialogContentSwitch = () => {
    switch (editField) {
      case "address":
        return (
          <TextField
            fullWidth
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        );
      case "phone":
        return (
          <TextField
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        );
      case "creditCard":
        return (
          <TextField
            fullWidth
            label="Credit Card"
            value={creditCard}
            onChange={(e) => setCreditCard(e.target.value)}
          />
        );
      case "name":
        return (
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        );
      case "email":
        return (
          <TextField
            fullWidth
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        );
      default:
        return null;
    }
  };
  console.log("Name: ", name);
  console.log("Location at checkout: " + JSON.stringify(location));
  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h6" gutterBottom>
        Checkout
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <PersonIcon />
        </Grid>
        <Grid item xs={8}>
          <Typography>{name || "Enter your name"}</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handleDialogOpen("name")}>
            <EditIcon />
          </IconButton>
        </Grid>

        <Grid item xs={2}>
          <EmailIcon />
        </Grid>
        <Grid item xs={8}>
          <Typography>{email || "Enter your email address"}</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handleDialogOpen("email")}>
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <HomeIcon />
        </Grid>
        <Grid item xs={8}>
          <Typography>{address || "Enter your delivery address"}</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handleDialogOpen("address")}>
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <PhoneIcon />
        </Grid>
        <Grid item xs={8}>
          <Typography>{phoneNumber || "Enter your phone number"}</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handleDialogOpen("phone")}>
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <CreditCardIcon />
        </Grid>
        <Grid item xs={8}>
          <Typography>
            {creditCard || "Enter your credit card information"}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={() => handleDialogOpen("creditCard")}>
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
      <h1>Apply Coupon: [xyz123]... 20% off!</h1>
      <h1> - - - - - - Order receipt - - - - - -</h1>
      <h2>Order details:</h2>
      <h2>(subtotal)... </h2>
      <h2>(tax)...</h2>
      <h2>(additional)...</h2>
      <h3>Shipping and fees: (api call)</h3>
      <h1>Total: $24.35</h1>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        style={{ marginTop: 20 }}
        onClick={() => {
          handleCreateGroupOrder();
        }}
      >
        Create group order
      </Button>
      <p>
        You will receive an email at order completion with a coupon for your
        savings! Thank you for choosing GroupSnack!
      </p>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent>{DialogContentSwitch()}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDialogSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CheckoutPage;
