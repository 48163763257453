import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, TextField, Paper } from "@mui/material/";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

const s = {
  outerPaper: {
    backgroundColor: "LightGrey",
    marginLeft: "30%",
    marginRight: "30%",
    padding: 20,
  },
  text: {
    marginLeft: 5,
    textAlign: "left",
    color: "Black",
    fontSize: 14,
    fontWeight: 800,
    backgroundColor: "LightGrey",
    height: 17,
  },
  outerTextBox: {
    width: "98%",
    height: "98%",
  },
  innerTextField: {
    width: "93%",
    height: "93%",
    border: 0,
    borderRadius: "5px",
    outline: "none",
  },
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function RegisterTablet({ stateProp, successfulNextPage }) {
  const [acctInfo, setAcctInfo] = React.useState(
    stateProp == null
      ? {
          fname: "",
          lname: "",
          businessEmail: "",
          countryCode: "",
          phoneNumber: "",
          password: "",
        }
      : stateProp
  );
  const verifyAcctInfo = () => {
    const errors = JSON.parse(JSON.stringify(acctInfo));
    var count = 0;
    for (let field in acctInfo) {
      if (!acctInfo[field] || acctInfo[field].trim() === "") {
        errors[field] = `error`;
        count = count + 1;
      }
    }

    setAcctInfo(errors);
    return count === 0; // return true if no errors
  };
  const verifyAndNext = () => {
    if (verifyAcctInfo()) {
      successfulNextPage(acctInfo);
    }
  };
  return (
    <>
      <Paper style={s.outerPaper}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1} columns={16}>
            <Grid item xs={8}>
              <Box style={s.text}>First Name</Box>
            </Grid>
            <Grid item xs={8}>
              <Box style={s.text}>Last Name</Box>
            </Grid>
            <Grid item xs={8}>
              <Item>
                <input
                  style={s.innerTextField}
                  value={acctInfo.fname}
                  onChange={(e) =>
                    setAcctInfo({ ...acctInfo, fname: e.target.value })
                  }
                />
              </Item>
            </Grid>
            <Grid item xs={8}>
              <Item>
                <input
                  style={s.innerTextField}
                  value={acctInfo.lname}
                  onChange={(e) =>
                    setAcctInfo({ ...acctInfo, lname: e.target.value })
                  }
                />
              </Item>
            </Grid>
            <Grid item xs={16}>
              <Box style={s.text}>
                <p>Business Email</p>
              </Box>
            </Grid>
            <Grid item xs={16}>
              <Item>
                <input
                  style={s.innerTextField}
                  value={acctInfo.businessEmail}
                  onChange={(e) =>
                    setAcctInfo({ ...acctInfo, businessEmail: e.target.value })
                  }
                />
              </Item>
            </Grid>
            <Grid item xs={4}>
              <Box style={s.text}>
                <p>Country</p>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box style={s.text}>
                <p>Mobile Number</p>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Item>
                <input
                  style={s.innerTextField}
                  value={acctInfo.countryCode}
                  onChange={(e) =>
                    setAcctInfo({ ...acctInfo, countryCode: e.target.value })
                  }
                />
              </Item>
            </Grid>
            <Grid item xs={12}>
              <Item>
                <input
                  style={s.innerTextField}
                  value={acctInfo.phoneNumber}
                  onChange={(e) =>
                    setAcctInfo({ ...acctInfo, phoneNumber: e.target.value })
                  }
                />
              </Item>
            </Grid>
            <Grid item xs={16}>
              <Box style={s.text}>
                <p>Password</p>
              </Box>
            </Grid>
            <Grid item xs={16}>
              <Item>
                <input
                  style={s.innerTextField}
                  value={acctInfo.password}
                  onChange={(e) =>
                    setAcctInfo({ ...acctInfo, password: e.target.value })
                  }
                />
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div style={{ marginTop: 10 }}>
        <Button
          style={{ backgroundColor: "#21b6ae" }}
          variant="contained"
          endIcon={<NavigateNextRoundedIcon />}
          onClick={verifyAndNext}
        >
          Next Page
        </Button>
      </div>
    </>
  );
}
