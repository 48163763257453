import React, { useContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";

const UserContext = React.createContext();
const AuthContext = React.createContext();
const AuthUpdateContext = React.createContext();
const TokenContext = React.createContext();

export function useToken() {
  return useContext(TokenContext);
}

export function useUser() {
  return useContext(UserContext);
}

export function useAuth() {
  return useContext(AuthContext);
}

export function useAuthUpdate() {
  return useContext(AuthUpdateContext);
}

export function AuthProvider({ children }) {
  const [authorized, setAuthorized] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [token, setToken] = useState(null);

  async function authorizeUser({ email, password }) {
    console.log("email: " + email + " password: " + password);
    try {
      const user = await Auth.signIn(email, password);
      setAuthorized(true);
      setUserInfo(user);

      console.log("Login Successful!");

      const response = await getToken(user.username);
      //console.log("User info: " + JSON.stringify(user));
      //console.log("GetTokenReponse: " + JSON.stringify(response));
      const statusCode = response.statusCode;
      const tokenResponse = response.body.authorizationToken;
      const userId = response.body.event.userID;
      console.log(
        " statusCode: " +
          statusCode +
          "\n userId: " +
          response.body.event.userID +
          "\n token: " +
          tokenResponse
      );
      if (statusCode == 200) {
        console.log("Recieved Authorization Token!");
        setToken(tokenResponse);
      } else {
        console.log(response);
      }

      return { userId: response.body.event.userID, token: tokenResponse };
    } catch (error) {
      console.log("error signing in", error);
      setAuthorized(false);
      setUserInfo(null);
      return false;
    }
  }

  return (
    <UserContext.Provider value={userInfo}>
      <AuthContext.Provider value={authorized}>
        <AuthUpdateContext.Provider value={authorizeUser}>
          <TokenContext.Provider value={token}>
            {children}
          </TokenContext.Provider>
        </AuthUpdateContext.Provider>
      </AuthContext.Provider>
    </UserContext.Provider>
  );
}

async function getToken(userID) {
  let adr =
    "https://69gsz8ewm2.execute-api.us-east-1.amazonaws.com/default/CreateToken";
  var result = "blank";

  const response = await fetch(adr, {
    method: "POST",
    body: JSON.stringify({
      userID: userID,
    }),
  });
  const data = await response.json();
  return data;
}
