import React from "react";
import { List, ListItem, ListItemText, Typography, Paper } from "@mui/material";

const CartReceipt = ({ cart }) => {
  // Function to format the quantity display
  const formatQuantity = (quantity) => {
    return quantity > 1 ? `${quantity}x` : "";
  };

  return (
    <List style={{ paddingLeft: "15%" }}>
      {cart.items.map((item) => (
        <React.Fragment key={item.itemName}>
          <ListItem
            style={{ padding: "0px", paddingLeft: "10px", height: "20px" }}
          >
            <ListItemText
              primary={`${formatQuantity(item.quantity)} ${item.itemName}`}
            />
          </ListItem>
          {item.selectedOptions.map((option) =>
            option.selection.map(
              (sel) =>
                sel.quantity > 0 && (
                  <ListItem
                    key={sel.name}
                    style={{
                      paddingLeft: "20px",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      height: "12px",
                    }}
                  >
                    <ListItemText
                      secondary={`- ${formatQuantity(sel.quantity)} ${
                        sel.name
                      } `}
                    />
                  </ListItem>
                )
            )
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default CartReceipt;
