import React, { useRef } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Slider from "react-slick";
import RoundBtn from "../../Components/Button/roundBtn";
import AddFood from "./addFood";
import { useState } from "react";

const FeaturedItemCarousel = ({ heading, data }) => {
  const [openModal, setOpenModal] = useState(false);
  const [foodDetails, setFoodDetails] = useState("");
  const onOpenModal = (item) => {
    setOpenModal(!openModal);
    setFoodDetails(item);
  };
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    vertical: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 3,
    arrows: false,
    draggable: false,
    responsive: [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: data?.dishes?.length > 7 ? 4 : data?.dishes?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 2520,
        settings: {
          slidesToShow: data?.dishes?.length > 5 ? 6 : data?.dishes?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1820,
        settings: {
          slidesToShow: data?.dishes?.length > 7 ? 8 : data?.dishes?.length,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: data?.dishes?.length > 6 ? 8 : data?.dishes?.length,
          slidesToScroll: 3,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: data?.dishes?.length > 5 ? 6 : data?.dishes?.length,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 824,
        settings: {
          slidesToShow: data?.dishes?.length > 2 ? 4.5 : data?.dishes?.length,
          slidesToScroll: 1.5,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: data?.dishes?.length > 1 ? 4 : data?.dishes?.length,
          slidesToScroll: 1.5,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: data?.dishes?.length > 1 ? 2 : data?.dishes?.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: data?.dishes?.length > 0 ? 2 : data?.dishes?.length,
          slidesToScroll: 1.5,
        },
      },
    ],
  };

  return (
    <div>
      <section className="space-y-6 -mx-2">
        <div className="flex justify-between items-center px-2">
          <h1 className="text-lg md:text-2xl lg:text-3xl text-gray-dark text-left font-bold">
            {heading}
          </h1>

          <div className="text-gray-dark space-x-4 lg:flex hidden items-center">
            <div className="flex space-x-2">
              <button
                className={`rounded-full p-2 shadow-lg ${
                  data?.dishes?.length > settings.slidesToShow
                    ? "text-black-primary bg-white hover:bg-[#C4C4C4] cursor-pointer"
                    : "text-gray-base  bg-gray-disable bg-opacity-10 cursor-not-allowed"
                }  text-xl `}
                onClick={() => sliderRef.current.slickPrev()}
                disabled={
                  data?.dishes?.length > settings.slidesToShow ? false : true
                }
              >
                <BsChevronLeft className="text-sm font-semibold" />
              </button>
              <button
                disabled={
                  data?.dishes?.length > settings.slidesToShow ? false : true
                }
                className={`rounded-full p-2 shadow-lg ${
                  data?.dishes?.length > settings.slidesToShow
                    ? "text-black-primary hover:bg-[#C4C4C4] bg-[#E7E7E7] cursor-pointer"
                    : "text-gray-base  bg-gray-disable bg-opacity-10 cursor-not-allowed"
                }  text-xl`}
                onClick={() => sliderRef.current.slickNext()}
              >
                <BsChevronRight className="text-sm" />
              </button>
            </div>
          </div>
        </div>
        <div className="mx-2">
          <Slider ref={sliderRef} {...settings}>
            {data?.dishes?.map((val, id) => (
              <div key={id} className="space-x-2">
                {!val.duplicate && (
                  <div
                    className={`${
                      data?.dishes.length > 6 ? "border-0" : "border-2"
                    } space-y-2 group cursor-pointer border-[#E7E7E7] md:rounded-md mr-2`}
                  >
                    <div className="md:rounded-md flex">
                      <div className="relative">
                        <img
                          className={`${
                            data?.dishes.length > 6
                              ? "w-40 h-36"
                              : "w-full h-48"
                          } rounded-sm`}
                          src={val?.img}
                          alt=""
                        />
                        <span
                          onClick={() => onOpenModal(val)}
                          className="absolute shadow-md bottom-2 right-2 rounded-full"
                        >
                          <RoundBtn text="Add" color="black" bgcolor="white" />
                        </span>
                      </div>
                    </div>
                    <div className="text-left space-y-1 p-1">
                      <h1 className=" text-black-dark text-base flex space-x-2 lg:text-md font-bold cursor-pointer duration-1000">
                        {val?.name?.length > 20
                          ? val?.name.substring(0, 20) + "..."
                          : val?.name}
                      </h1>
                      <span className="text-sm font-semibold text-gray-base2">
                        $ {val?.price}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </Slider>
        </div>
      </section>
      {openModal && <AddFood onRequestClose={onOpenModal} data={foodDetails} />}
    </div>
  );
};

export default FeaturedItemCarousel;
