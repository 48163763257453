import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getGroupObjects, getRestaurants } from "../../Functions/APIs";
import { TextField, Paper } from "@mui/material/";
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
} from "@mui/material/";

import CircularProgress from "@mui/material/CircularProgress";
import GroupOrderCard from "./GroupOrderCard";

function QueryPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const lat = location.state.lat;
  const lng = location.state.lng;
  const address = location.state.addressStr;

  const [loading, setLoading] = useState(true);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    // Check if lat, lng, or address is null or undefined
    if (lat == null || lng == null || address == null) {
      navigate("/");
    }

    const fetchData = async () => {
      try {
        // Replace this URL with your actual API endpoint
        const query = {
          latitude: lat,
          longitude: lng,
          radius: 5000, //in meters
        };
        console.log("Query:  ", JSON.stringify(query));
        const response = await getGroupObjects(query);
        console.log("GetGroupOrders response: ", JSON.stringify(response));

        const body = response.body;
        console.log("foundObjects: ", JSON.stringify(body.foundObjects));
        if (body.foundObjects === true) {
          setApiData(body);
        } else {
          navigate("/restaurants", {
            state: {
              lat: lat, // Call the function to get the value
              lng: lng, // Call the function to get the value
              addressStr: address,
            },
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [lat, lng, address, navigate]);

  console.log("lat, lng, ady: ", lat, lng, address);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    return (
      <div>
        <h2>QueryPage!</h2>
        <Grid container spacing={2}>
          {apiData.groupOrders.map((order) => (
            <div
              onClick={() => {
                navigate("/menu-viewer", {
                  state: {
                    merchant_id: order.merchantInfo.merchant_id,
                    group_id: order.group_id,
                    locationId: order.groupInfo.locationId,
                  },
                });
              }}
            >
              <GroupOrderCard order={order} />
            </div>
          ))}
        </Grid>
        <Button
          onClick={() => {
            navigate("/restaurants", {
              state: {
                lat: lat, // Call the function to get the value
                lng: lng, // Call the function to get the value
                addressStr: address,
              },
            });
          }}
        >
          Go to Restaurants
        </Button>
      </div>
    );
  }
}
export default QueryPage;
