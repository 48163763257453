import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Paper, Button } from "@mui/material";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createSvgIcon } from "@mui/material/utils";
export function AddCategory({ addCategory }) {
  const [text, setText] = useState("");
  const s = {
    text: {
      marginLeft: 5,
      textAlign: "left",
      color: "Black",
      fontSize: 14,
      fontWeight: 800,
    },
    innerTextField: {
      width: "93%",
      height: "93%",
      border: 0,
      borderRadius: "5px",
      outline: "none",
    },
    categoryContainer: {
      backgroundColor: "lightgrey",
      padding: 10,
    },
    categoryItem: {
      height: "45%",
    },
    button: {
      backgroundColor: "white",
      outline: "black",
      height: "100%",
      width: "100%",
    },
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const PlusIcon = createSvgIcon(
    // credit: plus icon from https://heroicons.com/
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );
  return (
    <div>
      <Grid container spacing={1} columns={16} style={s.categoryContainer}>
        <Grid item xs={12}>
          <Item style={(s.categoryItem, s.text)}>
            <Box> Add New Category </Box>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item style={(s.categoryItem, s.text)}>
            <PlusIcon
              onClick={() => {
                addCategory("");
              }}
            />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
}
