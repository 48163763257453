const ProcessStepData01 = [
  {
    icon: "line-icon-Idea-5",
    title: "Project Research",
    content: "Lorem ipsum is simply dummy text the printing industry",
  },
  {
    icon: "line-icon-Fountain-Pen",
    title: "Design Sketches",
    content: "Lorem ipsum is simply dummy text the printing industry",
  },
  {
    icon: "line-icon-Loading-2",
    title: "Selected Concept",
    content: "Lorem ipsum is simply dummy text the printing industry",
  },
  {
    icon: "line-icon-Juice",
    title: "Client Presentation",
    content: "Lorem ipsum is simply dummy text the printing industry",
  },
];

const ProcessStepData02 = [
  {
    title: "Project research",
    content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
  },
  {
    title: "Sketches and design",
    content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
  },
  {
    title: "Seleted concept",
    content: "Lorem ipsum amet consectetur do eiusmod tempor incididunt",
  },
];

const ProcessStepData03 = [
  {
    title: "RESEARCH",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
  {
    title: "SKETCHES",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
  {
    title: "CONCEPT",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
  {
    title: "PRESENTATION",
    content: "Lorem ipsum dolor consectetur adipiscing eiusmod tempor",
  },
];

const ProcessStepData04 = [
  {
    title: "Cheaper dining",
    content:
      "With GroupSnack, you pay a fraction of the service costs other delivery apps offer.",
  },
  {
    title: "Easier way to order with friends",
    content:
      "Using a link or QR code, instantly share the order with friends. We'll manage the order details for you.",
  },
  {
    title: "Better hourly rates",
    content:
      "Drivers make consistently higher and more predictable wages with GroupSnack.",
  },
  {
    title: "Larger Orders",
    content:
      "With more people ordering from your restaurant, expect more revenue!",
  },
];

const ProcessStepData05 = [
  {
    title: "Start market research",
    content:
      "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
  },
  {
    title: "Discussion of the idea",
    content:
      "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
  },
  {
    title: "Production planning",
    content:
      "Lorem ipsum is simply text of the printing and typesetting industry lorem ipsum",
  },
];

const ProcessStepData06 = [
  {
    title: "Innovative business",
    content: "Easy to customize",
  },
  {
    title: "Expertly marketing",
    content: "High quality services",
  },
  {
    title: "Engaging audiences",
    content: "Build perfect websites",
  },
];

export {
  ProcessStepData01,
  ProcessStepData02,
  ProcessStepData03,
  ProcessStepData04,
  ProcessStepData05,
  ProcessStepData06,
};
