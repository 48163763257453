import { Button, TextField, Paper } from "@mui/material/";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { useAuthUpdate, useUser } from "../AuthContext";

function LoginTablet() {
  const [data, setData] = useState(null);
  const styles = {
    LoginBubble: {
      margin: 30,
      display: "flex",
      flexDirection: "column",
    },
    Title: {
      padding: 15,
    },
    TextField: {
      padding: 10,
    },
    LoginButton: {
      margin: 3,
      background: "limegreen",
    },
  };

  const navigate = useNavigate();
  const authFunction = useAuthUpdate();
  async function signIn() {
    const email = document.getElementById("login-field").value;
    const password = document.getElementById("password-field").value;
    const value = await authFunction({ email, password });

    if (value) {
      navigate("/merchant");
    }

    console.log("Post login auth state: " + value);
  }

  return (
    <Paper elevation={6}>
      <div className="LoginBubble" style={styles.LoginBubble}>
        <p className="Title" style={styles.Title}>
          GroupSnack!
        </p>
        <TextField
          style={styles.TextField}
          id="login-field"
          label="Username"
          variant="outlined"
        />
        <TextField
          style={styles.TextField}
          id="password-field"
          label="Password"
          variant="outlined"
        />
        <Button onClick={signIn} style={styles.LoginButton} variant="contained">
          Login
        </Button>
      </div>
    </Paper>
  );
}

export default LoginTablet;
