import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { v4 as uuidv4 } from "uuid";

export function MenuSelector({ menus, setCurrentMenu, createNewMenu }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const addMenuItem = () => {
    //handleClick();
    createNewMenu({
      menuId: uuidv4(),
      menuTitle: "Menu Title " + menus.length,
      categories: [],
    });
  };
  return (
    <List
      sx={{ width: "100%", bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Select the menu you would like to update
        </ListSubheader>
      }
    >
      <ListItemButton onClick={handleClick} style={{ textAlign: "center" }}>
        <ListItemIcon></ListItemIcon>
        <ListItemText primary="List of Menus" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menus.map((menu, index) => {
            return (
              <li key={menu.menuId}>
                <ListItemButton
                  style={{ textAlign: "center" }}
                  sx={{ pl: 0 }}
                  onClick={() => {
                    setCurrentMenu(menu);
                    handleClick();
                  }}
                >
                  <ListItemText primary={menu.menuTitle} />
                </ListItemButton>
              </li>
            );
          })}
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => {
              addMenuItem();
            }}
          >
            <ListItemText primary="Click to create a new menu!" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
