import { useState } from "react";
import { Carousel } from "../../Components/carousel";
import { Category_Carousel } from "../../Components/carousel/category_carousel";
import { Category_Carousel_ICONS } from "../../Components/carousel/iconsCarousel";
import Navbar from "../../Components/navbar";
import { MEALS_LIST } from "../../utils/constant/index";
import SignIn from "../auth/signIn";
import { useEffect } from "react";
import RoundBtn from "../../Components/Button/roundBtn";

export const Home = () => {
  const [openModal, setOpenModal] = useState(false);
  const [category, setCategory] = useState();
  const [selectedCategories, setSelectedCategories] = useState();

  useEffect(() => {
    const categories = MEALS_LIST[1].LIST.filter(
      (item) => item?.category === category
    );
    const categories2 = MEALS_LIST[2].LIST.filter(
      (item) => item?.category === category
    );

    setSelectedCategories([...categories, ...categories2]);
  }, [category]);

  const onOpenSignIn = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <div className="relative">
        <div className="">
          <Navbar onOpenSignIn={onOpenSignIn} />
        </div>

        <div className="h-screen mx-auto py-16">
          <div className="flex items-center justify-center md:py-3 bg-[#F7F7F7]">
            <div className="space-y-4 w-10/12 pt-3">
              <Category_Carousel />
            </div>
          </div>
          <div className="flex items-center justify-center md:py-3 mt-2">
            <div className="space-y-4 w-10/12">
              <Category_Carousel_ICONS
                setCategory={setCategory}
                active={category}
              />
            </div>
          </div>
          <div className="flex items-center justify-center py-3 mt-5">
            {!category ? (
              <div className="space-y-6 w-10/12">
                <Carousel
                  slides={MEALS_LIST[2].LIST}
                  heading={MEALS_LIST[2].CATEGORY}
                />
                <Carousel
                  slides={MEALS_LIST[1].LIST}
                  heading={MEALS_LIST[1].CATEGORY}
                />
                <Carousel
                  slides={MEALS_LIST[2].LIST}
                  heading={MEALS_LIST[2].CATEGORY}
                />
                <Carousel
                  slides={MEALS_LIST[1].LIST}
                  heading={MEALS_LIST[1].CATEGORY}
                />
                <Carousel
                  slides={MEALS_LIST[2].LIST}
                  heading={MEALS_LIST[2].CATEGORY}
                />
              </div>
            ) : (
              <div className="w-10/12">
                <div className="flex justify-between">
                  <span className="font-bold text-xl">
                    {selectedCategories?.length} result
                    {selectedCategories?.length !== 1 ? "s" : ""}
                  </span>
                  <RoundBtn
                    text="Reset"
                    bgcolor="#E7E7E7"
                    onClick={() => setCategory(!category)}
                  />
                </div>
                <Carousel slides={selectedCategories} heading="" />
              </div>
            )}
          </div>
        </div>
        <div className="">
          {openModal && (
            <SignIn isOpen={openModal} onRequestClose={onOpenSignIn} />
          )}
        </div>
      </div>
    </>
  );
};
