import React, { useState } from "react";
import { Button, Paper } from "@mui/material";
import RegisterTablet from "./RegisterTablet";
import UserPool from "../UserPool";
import BusinessInfoForm from "./Register/BusinessInfoForm";
import EmailAuthPage from "./Register/EmailAuthPage";
import { Auth } from "aws-amplify";
import { useAuthUpdate, useToken, useUser } from "../AuthContext";
import { setUserInfo, updatemerchantInfo } from "../Functions/APIs";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useHistory,
  useLocation,
  useNavigate,
} from "react-router-dom";
const s = {
  wholePage: {},
  button: {},
};
async function signUp(email, password) {
  try {
    const { user } = await Auth.signUp({
      username: email,
      password: password,
    });
    console.log("Successful signup: " + JSON.stringify(user));
    return true;
  } catch (error) {
    console.error("error signing up:", error);
    return false;
  }
}
function RegisterPage() {
  const [acctInfo, setAcctInfo] = useState(null);
  const [busInfo, setBusInfo] = useState(null);
  const [displayAcctForm, setDisplayAcctForm] = useState(true);
  const [displayVerify, setDisplayVerify] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    console.log("Went Back!!!!!!!!!!!!!!!!!!! I WENT BACK");
    setDisplayAcctForm(true);
  };
  const successfulSubmit = async (businessData) => {
    setBusInfo(businessData);
    console.log("DidSubmit");

    const didSignup = await signUp(acctInfo.businessEmail, acctInfo.password);
    if (didSignup) {
      setDisplayVerify(true);
    } else {
    }
  };
  const successfulNextPage = (accountData) => {
    setAcctInfo(accountData);
    setDisplayAcctForm(false);
  };

  const authFunction = useAuthUpdate();
  const token = useToken();
  const SuccessfulVerification = async () => {
    //login, recieve token, then push data, then go to merchant page
    const email = acctInfo.businessEmail;
    const password = acctInfo.password;
    const value = await authFunction({ email, password }); //should always work
    console.log("Login result: " + JSON.stringify(value));
    console.log("Token within regPage: " + value.token);
    console.log("UserInfo: " + value.userId);

    let apiReturn = await setUserInfo(value.userId, acctInfo, value.token);
    console.log(apiReturn);
    apiReturn = await updatemerchantInfo(value.userId, busInfo, value.token);
    console.log(apiReturn);

    navigate("/merchant");
  };
  console.log("acctInfo: " + JSON.stringify(acctInfo));
  console.log("businessInfo: " + JSON.stringify(busInfo));
  return (
    <div style={s.wholePage}>
      <Link to="/">Home</Link>
      <h1>Register your business here!</h1>
      {displayVerify ? (
        <div>
          <EmailAuthPage
            emailProp={acctInfo}
            successfulVerification={SuccessfulVerification}
          />
        </div>
      ) : (
        <div>
          {displayAcctForm ? (
            <div>
              <p>Account Information</p>
              <RegisterTablet
                stateProp={acctInfo}
                successfulNextPage={successfulNextPage}
              />
            </div>
          ) : (
            <div>
              <p>Business Information</p>
              <BusinessInfoForm
                goBack={goBack}
                successfulSubmit={successfulSubmit}
                stateProp={busInfo}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default RegisterPage;
