import { Box, Paper, Typography, Dialog, DialogContent } from "@mui/material";
import { useSelectionModal } from "./ViewSelection";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";

export function MenuItem({ index, item, deleteItem }) {
  const [showSelection, SelectionComponent] = useSelectionModal();
  const [showImage, ImageModalComponent] = useImageModal();
  const s = {
    allPaper: {
      padding: 2,
      margin: 5,
    },
    allText: {
      fontWeight: 700,
      padding: 1,
    },
  };
  return (
    <Grid
      container
      columns={16}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      bgcolor="#a6dac1"
      borderRadius={4}
    >
      <Grid item xs={4}>
        {" "}
        <Paper style={s.allPaper} elevation={2}>
          <Box bgcolor="white" borderRadius={1}>
            <Typography variant="subtitle1" style={s.allText}>
              {item.name}
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper style={s.allPaper} elevation={2}>
          {" "}
          <Box
            bgcolor="white"
            borderRadius={1}
            onClick={() => showImage(item.img)}
          >
            <Typography color="textPrimary" style={s.allText}>
              View IMG
            </Typography>
          </Box>
          {ImageModalComponent}
        </Paper>
      </Grid>
      <Grid item xs={3}>
        <Paper style={s.allPaper} elevation={2}>
          {" "}
          <Box
            bgcolor="white"
            borderRadius={1}
            onClick={() =>
              showSelection("Required Selection", item.requiredSelection)
            }
          >
            <Typography color="textPrimary" style={s.allText}>
              View Req
            </Typography>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={2}>
        <Paper style={s.allPaper} elevation={2}>
          {" "}
          <Box
            bgcolor="white"
            borderRadius={1}
            onClick={() =>
              showSelection("Optional Selection", item.optionalSelection)
            }
          >
            <Typography color="textPrimary" style={s.allText}>
              View Opt
            </Typography>
          </Box>
        </Paper>

        {SelectionComponent}
      </Grid>
      <Grid item xs={2}>
        <Paper style={s.allPaper} elevation={2}>
          <Box bgcolor="white" borderRadius={1}>
            <Typography color="textPrimary" style={s.allText}>
              ${item.price}
            </Typography>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={2}>
        <HighlightOffIcon
          onClick={() => {
            deleteItem();
          }}
        />
      </Grid>
    </Grid>
  );
}
function useImageModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const showModal = (imageData) => {
    setCurrentImage(imageData);
    setIsOpen(true);
  };

  const ModalComponent = (
    <ImageModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      imgData={currentImage}
    />
  );

  return [showModal, ModalComponent];
}
function ImageModal({ isOpen, onClose, imgData }) {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <img src={imgData} alt="Item" style={{ width: "100%" }} />
      </DialogContent>
    </Dialog>
  );
}
