
import { TextField, Paper } from "@mui/material/";
import {
  Button,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Grid,
} from "@mui/material/";

const GroupOrderCard = ({order}) => {

    return (
    <Card key={order.group_id} sx={{ maxWidth: 345, mb: 2 }}>
    <CardMedia
      component="img"
      height="140"
      image={order.merchantInfo.cardImg}
      alt="Card Image"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="div">
        {order.merchantInfo.businessName}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Participants: {order.groupInfo.numberOfParticipants}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Distance: {order.groupInfo.distanceToDestination}
      </Typography>
    </CardContent>
  </Card>);

}

export default GroupOrderCard 