import React, { useState, useEffect, useRef } from "react";
import { json, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Grid } from "@mui/material";
import {} from "../../Functions/APIs";
import {
  Button,
  TextField,
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material/";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import CartReceipt from "./CartReceipt";
import OrderTime from "./OrderTime";
import Name from "./Name";
import OptionPopup from "./OptionPopup";
const ItemTypes = {
  ORDER: "order",
};

function OrderManagerPage() {
  const [locationId, setLocationId] = useState("");
  const [password, setPassword] = useState("");

  const [validLogin, setValidLogin] = useState(false);
  const [ws, setWs] = useState(null);

  const [isModalOpenProcess, setIsModalOpenProcess] = useState(false);
  const [isModalOpenDriver, setIsModalOpenDriver] = useState(false);
  const [selected, setSelected] = useState({
    orderId: "123",
    newStatus: "Pre-Processing",
  });

  const [groupOrders, setGroupOrders] = useState([]);
  //console.log("GroupOrders array: ", JSON.stringify(groupOrders));
  //console.log("GroupOrders array length: ", groupOrders.length);
  const handleWebSocketMessage = (event) => {
    const data = JSON.parse(event.data);
    console.log("Received WebSocket message!", JSON.stringify(data));
    if (Array.isArray(data)) {
      setGroupOrders(data);
    } else if (data.GroupObject) {
      updateGroupOrders(data.GroupObject);
    }
  };
  const updateGroupOrders = (newGroupOrder) => {
    setGroupOrders((prevOrders) => {
      const existingIndex = prevOrders.findIndex(
        (order) => order.groupId === newGroupOrder.groupId
      );
      if (existingIndex !== -1) {
        const updatedOrders = [...prevOrders];
        updatedOrders[existingIndex] = newGroupOrder;
        return updatedOrders;
      } else {
        return [...prevOrders, newGroupOrder];
      }
    });
  };

  const getWebSocket = (locationId, passcode) => {
    return new Promise((resolve, reject) => {
      const wsUrl = `wss://erw0yocld9.execute-api.us-east-1.amazonaws.com/production/?locationId=${encodeURIComponent(
        locationId
      )}&passcode=${encodeURIComponent(passcode)}`;
      const webSocket = new WebSocket(wsUrl);

      webSocket.onopen = () => {
        console.log("WebSocket Connected!");
        webSocket.onmessage = handleWebSocketMessage; // Set up message handler
        setWs(webSocket); // Set the WebSocket in state
        setValidLogin(true);
        resolve(webSocket);
      };

      webSocket.onerror = (error) => {
        console.log("WebSocket error:", error);
        reject(error); // Reject the promise on error
      };

      webSocket.onclose = (event) => {
        if (!event.wasClean) {
          console.log("WebSocket Disconnected:", event);
          reject(event); // Reject the promise if the connection was not closed cleanly
        }
      };
    });
  };

  const handleWsConnect = async () => {
    console.log("LocationId:", locationId, "Password:", password);
    try {
      const webSocket = await getWebSocket(locationId, password);
      console.log("WebSocket instance:", webSocket);
    } catch (error) {
      console.log("WebSocket connection failed:", error);
    }
  };

  const sendMessage = (action, message) => {
    const request = {
      action: action,
      data: {
        message: message,
      },
    };
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.send(JSON.stringify(request));
    } else {
      console.log("WebSocket is not connected.");
    }
  };

  const moveOrder = useCallback((orderId, newStatus) => {
    console.log("NewStatus: ", newStatus, orderId);
    setSelected({ orderId: orderId, newStatus: newStatus });

    if (newStatus === "Processing") {
      setIsModalOpenProcess(true);
    }
    if (newStatus === "Waiting for driver") {
      setIsModalOpenDriver(true);
    }
  }, []);
  const handleAcceptOrder = () => {
    const { orderId, newStatus } = selected;
    const updatedGroupOrders = groupOrders.map((groupOrder) => ({
      ...groupOrder,
      customerArray: groupOrder.customerArray.map((order) =>
        order.orderId === orderId ? { ...order, orderStatus: newStatus } : order
      ),
    }));
    setGroupOrders(updatedGroupOrders);
    const modifiedOrder = updatedGroupOrders.find((groupOrder) =>
      groupOrder.customerArray.some((order) => order.orderId === orderId)
    );
    console.log("modified order: ", JSON.stringify(modifiedOrder));
    sendMessage(
      "orderDecision",
      JSON.stringify({
        decision: "accepted",
        data: { GroupOrder: modifiedOrder, orderId: orderId },
      })
    );
    setIsModalOpenProcess(false);
  };
  const handleRejectOrder = (orderId) => {
    console.log("rejected!");
    sendMessage(
      "orderDecision",
      JSON.stringify({
        decision: "rejected",
        data: {
          groupId: findGroupOrder(groupOrders, orderId).groupId,
          orderId: orderId,
        },
      })
    );
  };
  const handleFinishOrder = () => {};
  return (
    <div>
      <h1>Order Manager Page</h1>
      {validLogin === true ? (
        <div>
          {isModalOpenProcess === true ? (
            <OptionPopup
              title={"Move Order to 'Processing"}
              description={
                "Would you like to accept this order and begin cooking?"
              }
              onAccept={handleAcceptOrder}
              setIsModal={setIsModalOpenProcess}
            />
          ) : null}
          <Modal
            open={isModalOpenDriver}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Paper>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm Order Status Change
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to change the order status?
              </Typography>
              <Button
                onClick={() => {
                  handleFinishOrder();
                }}
              >
                Confirm
              </Button>
            </Paper>
          </Modal>
          <DndProvider backend={HTML5Backend}>
            <Box display="flex">
              <OrderColumn
                title="Incoming Orders"
                status="Pre-Acceptance"
                groupOrders={groupOrders}
                moveOrder={moveOrder}
                handleRejectOrder={handleRejectOrder}
              />
              <OrderColumn
                title="Processing Orders"
                status="Processing"
                groupOrders={groupOrders}
                moveOrder={moveOrder}
                handleRejectOrder={handleRejectOrder}
              />
              <OrderColumn
                title="Waiting for driver"
                status="Waiting for driver"
                groupOrders={groupOrders}
                moveOrder={moveOrder}
                handleRejectOrder={handleRejectOrder}
              />
            </Box>
          </DndProvider>
        </div>
      ) : (
        <div>
          {" "}
          <h2>Enter your restaurant location id:</h2>
          <Box marginTop={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Location Id"
              value={locationId}
              onChange={(e) => setLocationId(e.target.value)}
            />
          </Box>
          <h2>Enter your order manager password:</h2>
          <Box marginTop={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Button
            onClick={() => {
              handleWsConnect();
            }}
          >
            Enter
          </Button>
        </div>
      )}
    </div>
  );
}
const findGroupOrder = (groupOrders, orderId) => {
  const groupId = groupOrders.find((groupOrder) =>
    groupOrder.customerArray.some((order) => order.orderId === orderId)
  );
};
const OrderColumn = ({
  title,
  status,
  groupOrders,
  moveOrder,
  handleRejectOrder,
}) => {
  const [, drop] = useDrop(() => ({
    accept: ItemTypes.ORDER,
    drop: (item) => moveOrder(item.orderId, status),
  }));

  const driverColumn = groupOrders.filter((groupOrder) =>
    groupOrder.customerArray.some(
      (order) => order.orderStatus === "Waiting for driver"
    )
  );
  const filteredOrders = groupOrders
    .flatMap((groupOrder) =>
      groupOrder.customerArray.map((order) => ({
        ...order,
        driverId: groupOrder.driverId,
        calculatedColor: calculateColor(groupOrder.groupId),
      }))
    )
    .filter((order) => order.orderStatus === status);
  if (status === "Waiting for driver") {
    return (
      <Box flex={1} p={2} ref={drop}>
        <Typography variant="h6">{title}</Typography>
        <div>
          {driverColumn.map((groupOrder) => (
            <Paper
              style={{
                marginBottom: 8,
                backgroundColor: calculateColor(groupOrder.groupId),
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <p>Driver:</p>
                </Grid>{" "}
                <Grid item xs={6}>
                  <p>{groupOrder.driverId}</p>
                </Grid>
                {groupOrder.customerArray.map((order) => (
                  <>
                    <Grid item xs={6}>
                      <p>{order.name}</p>
                    </Grid>
                    <Grid item xs={6}>
                      {order.orderStatus === "Waiting for driver" ? (
                        <CheckIcon />
                      ) : (
                        <CloseIcon />
                      )}
                    </Grid>
                  </>
                ))}
              </Grid>
            </Paper>
          ))}
        </div>
      </Box>
    );
  }
  return (
    <Box flex={1} p={2} ref={drop}>
      <Typography variant="h6">{title}</Typography>
      {filteredOrders.map((order) => (
        <OrderItem
          key={order.orderId}
          order={order}
          rejectfxn={handleRejectOrder}
        />
      ))}
    </Box>
  );
};
const calculateColor = (groupId) => {
  const chunkSize = 10;
  let chunks = [];
  for (let i = 0; i < groupId.length; i += chunkSize) {
    chunks.push(groupId.substring(i, i + chunkSize));
  }

  let rgb = chunks.map(
    (chunk) =>
      chunk.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) % 255
  );

  // Ensure that we have three values for RGB
  while (rgb.length < 3) rgb.push(0);

  // Make colors paler by averaging with white (255)
  rgb = rgb.map((color) => Math.floor((color + 255) / 2));

  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};

const OrderItem = ({ order, rejectfxn }) => {
  const [viewReciept, setViewReciept] = useState(false);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ORDER,
    item: { orderId: order.orderId },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Paper
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        marginBottom: 8,
        backgroundColor: order.calculatedColor,
      }}
    >
      <Box p={2}>
        <Grid container spacing={1}>
          {order.orderStatus === "Pre-Acceptance" && (
            <>
              <Grid item xs={4}>
                <Name name={order.name} />
              </Grid>
              <Grid item xs={4}>
                <OrderTime unixTimestamp={order.createdTime} />
              </Grid>
              <Grid item xs={4}>
                <DeleteForeverIcon
                  onClick={() => {
                    rejectfxn(order.orderId);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CartReceipt cart={order.cart} />
              </Grid>
            </>
          )}
          {order.orderStatus === "Processing" && (
            <>
              <Grid item xs={6}>
                <Name name={order.name} />
              </Grid>
              <Grid item xs={6}>
                <OrderTime unixTimestamp={order.createdTime} />
              </Grid>{" "}
              <Grid item xs={6}>
                <p>Driver: </p>
              </Grid>
              <Grid item xs={6}>
                {order.driverId}
              </Grid>
              <Grid item xs={12}>
                Priority: {order.priority}
              </Grid>
              <Grid item xs={12}>
                {viewReciept && <CartReceipt cart={order.cart} />}
                <Button
                  onClick={() => {
                    setViewReciept(!viewReciept);
                  }}
                >
                  View Reciept
                </Button>
              </Grid>
            </>
          )}
          {order.orderStatus === "Waiting for driver" && (
            <Grid item xs={12}>
              <p>Waiting for driver</p>
            </Grid>
          )}
          {/* Render more order details here */}
        </Grid>
      </Box>
    </Paper>
  );
};
export default OrderManagerPage;
