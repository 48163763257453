import React, { useState } from "react";
import Amplify, { Auth } from "aws-amplify";
import { Paper, TextField, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
function EmailAuthPage({ emailProp, successfulVerification }) {
  const [email, setEmail] = useState(emailProp.businessEmail);
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const HandleVerification = async () => {
    try {
      await Auth.confirmSignUp(email, code);
      setMessage("Email verified successfully!");
      successfulVerification();
    } catch (error) {
      setMessage(`Error verifying email: ${error}`);
    }
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh", backgroundColor: "#f5f5f5" }}
    >
      <Grid item xs={10} sm={8} md={6}>
        <Paper style={{ padding: "20px", backgroundColor: "#e0e0e0" }}>
          <Typography variant="h6" align="center">
            Verify Your Account
          </Typography>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            label="Confirmation Code"
            variant="outlined"
            margin="normal"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={HandleVerification}
            style={{ margin: "20px 0" }}
          >
            Verify
          </Button>
          {message && <Typography align="center">{message}</Typography>}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default EmailAuthPage;
