import React from "react";
import { useState, useEffect } from "react";
// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, m } from "framer-motion";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Parallax } from "react-scroll-parallax";
import { AutoComplete } from "antd";

// Components
import Header, { HeaderNav, Menu } from "../Components/Header/Header";
import { fadeIn, fadeInLeft, zoomIn } from "../Functions/GlobalAnimations";
import Buttons from "../Components/Button/Buttons";
import CustomModal from "../Components/CustomModal";
import Clients from "../Components/Clients/Clients";
import { Input } from "../Components/Form/Form";
import MessageBox from "../Components/MessageBox/MessageBox";
import IconWithText from "../Components/IconWithText/IconWithText";
import PricingTable01 from "../Components/PricingTable/PricingTable01";
import FooterStyle06 from "../Components/Footers/FooterStyle06";
import { resetForm, sendEmail } from "../Functions/Utilities";
import ProcessStep from "../Components/ProcessStep/ProcessStep";
import SocialIcons from "../Components/SocialIcon/SocialIcons";
import TestimonialsCarousel09 from "../Components/TestimonialCarousel/TestimonialsCarousel09";
import Overlap from "../Components/Overlap/Overlap";
import SideButtons from "../Components/SideButtons";
import InViewPort from "../Components/InViewPort";

// Data
import { ClientData05 } from "../Components/Clients/ClientsData";
import { pricingTable01 } from "../Components/PricingTable/PricingTableData";
import { ProcessStepData04 } from "../Components/ProcessStep/ProcessStepData";
import { TestimonialsCarouselData9 } from "../Components/TestimonialCarousel/TestimonialsCarouselData";
import { IconWithTextData_09 } from "../Components/IconWithText/IconWithTextData";
//import styles from "../Assets/css/global.css";
//import styles from "../Assets/css/global.module.css";

const SocialIconsData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#828282",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
  {
    color: "#828282",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
];

const IconWithTextData = [
  {
    icon: "line-icon-Soup text-[40px] text-gradient bg-gradient-to-r from-[#65A483] via-[#7BB699] via-[#7BB699] vai-[#75C79E] to-[#5ECA94]",
    title: "Order the best food, at the cheapest prices",
    content: "food food food",
  },
  {
    icon: "line-icon-Shop-2 text-[40px] text-gradient bg-gradient-to-r from-[#65A483] via-[#7BB699] via-[#7BB699] vai-[#75C79E] to-[#5ECA94]",
    title: "Recieve larger orders for your restaurant",
    content: "Large order larger order.",
  },
  {
    icon: "line-icon-Financial text-[40px] text-gradient bg-gradient-to-r from-[#65A483] via-[#7BB699] via-[#7BB699] vai-[#75C79E] to-[#5ECA94]",
    title: "Earn more for every delivery you make",
    content: "driver wages driver wages",
  },
];
const combineClassNames = (styles) => {
  return Object.values(styles).join(" ");
};

const SeoAgencyPage = (props) => {
  let autocomplete;
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById("autocomplete"),
        {
          types: ["address"],
          componentRestrictions: { country: ["US"] },
          fields: ["place_id", "geometry", "name", "formatted_address"],
        }
      );
      autocomplete.addListener("place_changed", onPlaceChanged);
    }
  }, []);

  function onPlaceChanged() {
    var place = autocomplete.getPlace();
    if (!place.geometry) {
      document.getElementById("autocomplete").placeholder = "Enter a place";
    } else {
      console.log("Place Selected: " + JSON.stringify(place));
      navigate("/grouporder", {
        state: {
          lat: place.geometry.location.lat(), // Call the function to get the value
          lng: place.geometry.location.lng(), // Call the function to get the value
          addressStr: place.formatted_address,
        },
      });
    }
  }
  return (
    <div>
      <div style={props.style}>
        <SideButtons />

        {/* Header Start */}
        <Header topSpace={{ md: true }} type="reverse-scroll">
          <HeaderNav
            theme="light"
            expand="lg"
            className="py-[0px] px-[35px] lg:px-[15px] md:px-[15px] md:py-[20px] sm:px-0"
          >
            <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
              <Link
                aria-label="header logo"
                className="flex items-center"
                to="/"
              >
                <Navbar.Brand className="inline-block p-0 m-0">
                  <img
                    className="default-logo"
                    width="111"
                    height="111"
                    loading="lazy"
                    src="/assets/img/webp/GS-icon-1.png"
                    data-rjs="/assets/img/webp/logo-gradient-magenta-orange@2x.webp"
                    alt="logo"
                  />
                  <img
                    className="alt-logo"
                    width="111"
                    height="111"
                    loading="lazy"
                    src="/assets/img/webp/GS-icon-1.png"
                    data-rjs="/assets/img/webp/logo-gradient-magenta-orange@2x.webp"
                    alt="logo"
                  />
                  <img
                    className="mobile-logo"
                    width="111"
                    height="111"
                    loading="lazy"
                    src="/assets/img/webp/GS-icon-1.png"
                    data-rjs="/assets/img/webp/logo-gradient-magenta-orange@2x.webp"
                    alt="logo"
                  />
                </Navbar.Brand>
              </Link>
            </Col>
            <Navbar.Toggle className="order-last md:ml-[17px] md:px-[15px] sm:px-0">
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </Navbar.Toggle>
            <Navbar.Collapse className="justify-center col-auto col-lg-8">
              <Menu {...props} />
            </Navbar.Collapse>
            <Col
              lg={2}
              xs={"auto"}
              className="justify-end pe-0 flex items-center"
            >
              <SocialIcons
                theme="social-icon-style-01 block text-end"
                iconColor="dark"
                size="xs"
                data={SocialIconsData}
              />
            </Col>
          </HeaderNav>
        </Header>
        {/* Header End */}

        {/* Section Start */}
        <section
          className="py-[160px] overflow-hidden cover-background sm:py-[80px]"
          style={{
            backgroundImage: `url('/assets/img/webp/Bg-4.png')`,
          }}
        >
          <Container>
            <Row className="items-center h-[720px] lg:h-[500px] sm:h-[550px] xs:h-[750px] overflow-hidden">
              <Col className="sm:mb-[40px] overflow-hidden">
                <h3 className="heading-5 font-serif font-semibold text-[#374162] mb-[5px]">
                  Start saving now with
                </h3>
                <h2 className="heading-5 font-serif font-bold text-gradient bg-gradient-to-r from-[#67B38D] via-[#67B38D] to-[#67B38D] mb-[25px] tracking-[-1px]">
                  GroupSnack!
                </h2>
                <AutoComplete
                  id="autocomplete"
                  style={{ width: "65%" }}
                  options={options}
                  onSelect={onPlaceChanged}
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section className="pt-[25px] pb-[8%] border-b border-[#ededed] overflow-hidden sm:pt-[50px] sm:pb-32">
          <Container>
            <Row className="justify-center xs:mb-16">
              <Col
                lg={5}
                sm={7}
                className="text-center font-serif mb-[60px] md:mb-[35px] sm:mb-16 xs:mb-0"
              >
                <span className="font-semibold uppercase text-gradient bg-gradient-to-r from-[#4AAE7F] via-[#49BC86] to-[#61CD9A] block tracking-[1px] mb-[20px] sm:mb-[10px]">
                  Why should you choose us?
                </span>
                <h2 className="heading-5 mx-auto font-medium text-[#374162] w-[90%] xs:w-full">
                  Heres what we bring to the table...
                </h2>
              </Col>
            </Row>
            <ProcessStep
              grid="row-cols-1 row-cols-sm-2 row-cols-lg-4 gap-y-20"
              theme="process-step-style-04"
              data={ProcessStepData04}
              animation={fadeIn}
            />
          </Container>
        </section>
        {/* Section End */}

        {/* Lazy Load HTML */}
        <InViewPort>
          {/* Section Start */}
          <section className="py-[70px] overflow-hidden lg:py-[40px] sm:py-[20px]">
            <Container>
              <Row className="items-center justify-center">
                <Col xl={7} md={6} className="text-right">
                  <img
                    width="583px"
                    height="608px"
                    className="inline-block"
                    src="/assets/img/webp/group-eating-alottahands.jpeg"
                    alt=""
                  />
                </Col>
                <m.div
                  className="col-10 col-xl-4 col-lg-5 offset-lg-1 col-md-6 ps-lg-0 text-left md:text-start sm:text-center"
                  {...fadeIn}
                >
                  <span className="font-serif font-semibold text-gradient bg-gradient-to-r from-[#4AAE7F] via-[#49BC86] to-[#61CD9A] tracking-[1px] inline-block uppercase mb-[20px] sm:mb-[10px]">
                    But how does this all work?
                  </span>
                  <p className="mb-[40px]">
                    GroupSnack is a logisitics service. We help customers and
                    drivers order more efficiently. By ordering in groups,
                    customers are able to save a lot more on their service fees
                    and drivers are able to make a living wage. Apps like
                    doordash charge upwards of 80% of the original price in
                    fees, making delivery practically unaffordable. With every
                    additional member in a GroupSnack order, you can expect your
                    fees to be a fraction of what you would pay elsewhere.
                    Simply sign-in and join pending orders near you, or schedule
                    one in advanced for a chance to save even more!
                  </p>
                  <Buttons
                    ariaLabel="link for experience"
                    to="/home-seo-agency"
                    className="rounded-[4px] font-medium font-serif uppercase hover:text-darkgray btn-slide-filling-right bg-gradient-to-r from-[#5DC092] to-[#6de3a9]"
                    size="lg"
                    color="#fff"
                    themeColor="#fff"
                    title="Learn More"
                  />
                </m.div>
              </Row>
            </Container>
          </section>
          {/* Section End */}

          {/* Section Start*/}
          <m.section
            className="py-[20px] overflow-hidden lg:py-[20px] sm:py-[20px]"
            {...fadeIn}
          >
            <Container>
              <Row className="justify-center">
                <Col xs={12} md={8} lg={12}>
                  <PricingTable01
                    grid="row-cols-1 row-cols-lg-3 justify-center items-center"
                    theme="pricing-table-style-01"
                    data={pricingTable01}
                    themeColor="dark"
                  />
                </Col>
              </Row>
            </Container>
          </m.section>
          {/* Section End*/}

          {/* Section Start */}
          <FooterStyle06
            theme="light"
            className="bg-white text-[#828282] seo-agency-footer_06"
            logo="/assets/img/webp/logo-gradient-magenta-orange.webp"
          />
          {/* Section End */}
        </InViewPort>
      </div>
    </div>
  );
};

export default SeoAgencyPage;
