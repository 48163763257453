import React, { useState } from "react";
import { AiOutlineInfoCircle, AiTwotoneStar } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { RxDividerVertical } from "react-icons/rx";
import { ASSETS } from "../../Assets/path";

const RestaurantDetails = ({ name, rating, rating2, desc, distance, shop }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div>
      <div className="space-y-2 pr-5 px-3 pt-5">
        <h1 className="text-3xl md:text-4xl font-bold">{name}</h1>
        <div className="flex space-x-1 items-center text-sm text-[#767676]">
          <span className="text-gray-dark font-bold text-lg">{rating}</span>
          <span className="text-lg">
            <AiTwotoneStar fill="#E8C500" />
          </span>
          <span className="">{rating2} </span>{" "}
          <span>
            <RxDividerVertical fill="#D6D6D6" />
          </span>{" "}
          <span className="text-sm text-[#00838A] font-bold flex space-x-1">
            <img src={ASSETS.CAROUSEL.PAYMENT} alt="" /> DashPass
          </span>
          <span>
            <RxDividerVertical fill="#D6D6D6" />
          </span>
        </div>
        <div className="flex space-x-1 items-center text-sm text-[#767676]">
          {!shop ? (
            <span className="">{name}</span>
          ) : (
            <span className="">{shop}</span>
          )}
          <span>
            <RxDividerVertical fill="#D6D6D6" />
          </span>
          <span className="">{distance}</span>
          <span>
            <RxDividerVertical fill="#D6D6D6" />
          </span>
          <span>$$</span>
        </div>
        <div className="flex space-x-1 items-center text-sm text-[#767676]">
          <span>
            <BiTimeFive fill="#767676" />
          </span>
          <span className="text-[#00872F] font-semibold">Open Now</span>
          <span>•</span>
          <span>Closes at 8:40 PM</span>
        </div>
        <div className="flex space-x-1 items-center text-sm text-[#767676]">
          <span>Pricing and Fees</span>
          <span>
            <AiOutlineInfoCircle fill="#767676" />
          </span>
        </div>
        <p className="">
          {desc?.length > 20 ? (
            <span>
              {showMore ? desc : desc.substring(0, 200) + "..."}
              <span
                className="underline font-semibold cursor-pointer"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? " less" : " more"}
              </span>
            </span>
          ) : (
            desc
          )}
        </p>
      </div>
    </div>
  );
};

export default RestaurantDetails;
