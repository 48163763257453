import LoginTablet from './LoginTablet';

function LoginPage() {
    return (
        <div className="LoginPageContainer">
            <h2>LoginPage</h2>
            <LoginTablet/>
        </div>
    );
}
export default LoginPage