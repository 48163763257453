import "./App.css";

import React, { Suspense, useEffect, useState, lazy } from "react";
import { Amplify } from "aws-amplify";
import retina from "retinajs";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  useHistory,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useAuth, AuthProvider } from "./AuthContext";

import LoginPage from "./components2/LoginPage";
import RegisterPage from "./components2/RegisterPage";
import MerchantPage from "./components2/MerchantPage";
import MenuPage from "./components2/MenuPage";
import HomePage from "./components2/HomePage";

import { Main } from "./container/main";
import { Home } from "./container/home";
import OrderPage from "./container/order";

import GlobalContext from "./Context/Context";
import ScrollToTopButton from "./Components/ScrollToTop";
import NewHomePage from "./components2/NewHomePage";
import QueryPage from "./components2/Query/QueryPage";
import RestaurantsHome from "./components2/Restaurants/RestaurantsHome";
import MenuViewer from "./components2/MenuPage/MenuViewer";
import ShoppingCart from "./components2/MenuPage/ShoppingCart";
import CheckoutPage from "./components2/MenuPage/CheckoutPage";
import OrderStatusPage from "./components2/MenuPage/OrderStatusPage";
import OrderManagerPage from "./components2/OrderManager/OrderManagerPage";

//import "./Assets/css/global.css"
//Amplify.configure(awsconfig);
Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_qdpzW8owl",
    userPoolWebClientId: "3cvael6rmu4pu7655seemprbba",
  },
});

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    document.title = "GroupSnack";
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });
    const loadCSS = (file) => {
      const link = document.createElement("link");
      link.href = file;
      link.type = "text/css";
      link.rel = "stylesheet";
      document.head.appendChild(link);
    };
    if (
      location.pathname === "/home" ||
      location.pathname === "/order" ||
      location.pathname === "/restaurants" ||
      location.pathname === "/menu-viewer"
    ) {
      loadCSS("./index.css");
      const styleTag = document.getElementById("global-stylesheet");
      if (styleTag) {
        document.head.removeChild(styleTag);
      }
    } else {
      //require("./Assets/css/global.css");
      loadCSS("./index.css");
    }
  }, [location.pathname]);
  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App">
        <Routes>
          <Route path="/" element={<NewHomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/search" element={<Main />} />
          <Route path="/grouporder" element={<QueryPage />} />
          <Route path="/restaurants" element={<RestaurantsHome />} />
          <Route path="/menu-viewer" element={<MenuViewer />} />
          <Route path="/menu-viewer/" element={<MenuViewer />} />
          <Route path="/shopping-cart" element={<ShoppingCart />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/order-status" element={<OrderStatusPage />} />
          <Route path="/order-manager" element={<OrderManagerPage />} />

          <Route path="/order" element={<OrderPage />} />
          <Route path="/home" element={<Home />} />
          <Route path="/test" element={<HomePage />} />
          <Route
            path="/merchant"
            element={
              <AuthOnlyRoute>
                <MerchantPage />
              </AuthOnlyRoute>
            }
          />
          <Route
            path="/merchant/menus"
            element={
              <AuthOnlyRoute>
                <MenuPage />;
              </AuthOnlyRoute>
            }
          />
        </Routes>
      </div>
    </GlobalContext.Provider>
  );
}

function AuthOnlyRoute({ children }) {
  const isAuthorized = useAuth();
  if (isAuthorized === true) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
}
export default App;
