import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { Box, Paper } from "@mui/material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { MenuItem } from "./MenuItem";
import { AddMenuItem } from "./AddMenuItem";
export function MenuCategory({ category, updateCategory, deleteCategory }) {
  const [open, setOpen] = React.useState(false);
  const [changeText, setChangeText] = React.useState(false);
  const [categoryState, setCategoryState] = React.useState(category);
  const s = {
    divContainer: {
      width: "100%",
    },
    listContainer: {
      backgroundColor: "#94d3b4",
      width: "100%",
    },
    button: {
      width: "100%",
    },
    catText: {
      textAlign: "left",
      color: "Black",
      fontSize: 14,
      fontWeight: 800,
    },
    catLabel: {
      overflow: "hidden",
      "text-overflow": "ellipsis",
      width: "70px",
    },
    catGrid: {
      height: "45%",
      padding: 10,
      paddingLeft: 23,
    },
    catItem: {},
  };
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      const obj = document.getElementById("textField").value;
      const updatedCategory = {
        id: categoryState.id,
        categoryName: obj,
        items: categoryState.items,
      };
      setChangeText(false);
      updateCategory(updatedCategory);
      setCategoryState(updatedCategory);
    }
  };
  const handleNewItem = (item) => {
    const newItem = JSON.parse(JSON.stringify(item));

    const updatedCategory = {
      id: categoryState.id,
      categoryName: categoryState.categoryName,
      items: [...categoryState.items, newItem],
    };
    console.log(
      "ITEM: " +
        JSON.stringify(item) +
        " PRECAT: " +
        JSON.stringify(categoryState) +
        " POSTCAT: " +
        JSON.stringify(updatedCategory)
    );
    updateCategory(updatedCategory);
    setCategoryState(updatedCategory);
  };
  const deleteItemAtIndex = (index) => {
    const updatedItems = [...categoryState.items];

    updatedItems.splice(index, 1);

    const updatedCategory = {
      ...categoryState,
      items: updatedItems,
    };

    updateCategory(updatedCategory);

    setCategoryState(updatedCategory);
  };
  return (
    <div style={s.divContainer}>
      <List style={s.listContainer}>
        <Grid container spacing={1} columns={16} style={s.catGrid}>
          <Grid item xs={3}>
            <Box>
              <Paper>
                <p style={s.catLabel}>Category</p>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Paper onClick={() => setChangeText(true)}>
              {changeText ? (
                <input
                  id="textField"
                  style={{
                    width: "100%",
                    height: "100%",
                    outline: "none",
                    border: 0,
                    paddingLeft: 5,
                  }}
                  value={categoryState.categoryName}
                  onChange={(e) =>
                    setCategoryState({
                      ...categoryState,
                      categoryName: e.target.value,
                    })
                  }
                  onKeyDown={(e) => handleEnter(e)}
                />
              ) : (
                <label
                  style={{
                    backgroundColor: "white",
                    width: "max",
                    textAlign: "left",
                    paddingLeft: 5,
                    marginLeft: 0,
                    display: "block",
                  }}
                >
                  {categoryState.categoryName
                    ? categoryState.categoryName
                    : "Enter Category Name"}
                </label>
              )}
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper>
              <ListItemButton style={s.button} onClick={handleOpen}>
                <ListItemIcon>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemIcon>
              </ListItemButton>
            </Paper>
          </Grid>{" "}
          <Grid item xs={2}>
            <Box style={s.catItem}>
              <DeleteForeverSharpIcon
                onClick={() => {
                  deleteCategory();
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {categoryState.items.map((item, index) => {
              return (
                <MenuItem
                  index={index}
                  item={item}
                  deleteItem={(index) => deleteItemAtIndex(index)}
                />
              );
            })}
            <AddMenuItem handleNewItem={(item) => handleNewItem(item)} />
          </List>
        </Collapse>
      </List>
    </div>
  );
}
