import React from "react";
import { Popup } from "../../Components/popup";
import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import FoodOptions from "../../Components/details/foodOptions";
import { useEffect } from "react";

const defaultCartItem = {
  categoryName: "",
  itemName: "",
  currentPrice: 0.0,
  selectedOptions: [],
};

const AddFood = ({ onRequestClose, data, addToCart }) => {
  const [selectedOptions, setSelectedOptions] = useState(0);
  const [totalData, setData] = useState(0);
  const [cartItem, setCartItem] = useState({
    categoryName: data.menu,
    itemName: data.name,
    currentPrice: data.price,
    img: data.img,
    desc: data.desc,
    selectedOptions: [],
  });

  useEffect(() => {
    setData(selectedOptions + totalData);
    //console.log("Data: ", JSON.stringify(data));
    setCartItem((prevCartItem) => ({
      ...prevCartItem,
      categoryName: data.menu,
      itemName: data.name,
      currentPrice: data.price,
    }));
  }, [selectedOptions]);

  const addSelection = (selection) => {
    //console.log("This selection was added: ", JSON.stringify(selection));

    setCartItem((prevCartItem) => {
      // Find index of existing selection, if it exists
      const existingIndex = prevCartItem.selectedOptions.findIndex(
        (opt) => opt.id === selection.id
      );

      // Copy previous selected options
      let updatedOptions = [...prevCartItem.selectedOptions];

      // Update or add the selection
      if (existingIndex >= 0) {
        updatedOptions[existingIndex] = selection;
      } else {
        updatedOptions.push(selection);
      }

      // Calculate total price of selected options
      const selectionsTotalPrice = updatedOptions.reduce((total, opt) => {
        const optionTotal = opt.selection.reduce((sum, item) => {
          return sum + item.quantity * parseFloat(item.price || 0);
        }, 0);
        return total + optionTotal;
      }, 0);

      // Ensure data.price is a number
      const itemBasePrice = parseFloat(data.price) || 0;

      return {
        ...prevCartItem,
        selectedOptions: updatedOptions,
        currentPrice: itemBasePrice + selectionsTotalPrice,
      };
    });
  };
  function transformJson(data) {
    const transformOptions = (options, isRequired) => {
      return options.map((option) => {
        let attribute = "";

        if (option.min < 1 && option.max > option.items.length) {
          attribute = "plusMinus";
        } else if (option.min < 1) {
          attribute = "toggle"; // Toggle for min < 1
        } // No attribute needed for max == 1

        return {
          id: option.name, // or some other unique identifier
          title: option.name,
          min: option.min,
          max: option.max,
          select: getSelectText(option.min, option.max, isRequired),
          ...(attribute && { [attribute]: attribute }), // Only add the attribute if it's not blank
          checkbox: option.items.map((item) => ({
            id: item.name, // or some other unique identifier
            opt: item.name,
            price: item.modifier,
          })),
        };
      });
    };

    const getSelectText = (min, max, isRequired) => {
      if (isRequired) {
        return `Select ${min === max ? min : `${min} to ${max}`}`;
      }
      return `Select up to ${max} (Optional)`;
    };

    let transformedData = [];

    if (data.requiredSelection) {
      transformedData = transformedData.concat(
        transformOptions(data.requiredSelection, true)
      );
    }

    if (data.optionalSelection) {
      transformedData = transformedData.concat(
        transformOptions(data.optionalSelection, false)
      );
    }

    return transformedData;
  }
  const options = transformJson(data);
  //console.log("Item Data within popup: ", JSON.stringify(data));

  //console.log("transformed data: ", JSON.stringify(options));
  //console.log("cartItem: ", JSON.stringify(cartItem));
  const content = (
    <>
      <div className="p-4">
        <h1 className="xl:text-3xl text-2xl font-bold mb-5">{data.name}</h1>
        <span className="text-sm font-medium text-gray-base2">{data.desc}</span>
        <div className="my-5">
          <img src={data.img} alt="" className="w-full h-60" />
        </div>

        <div>
          {options?.map((item, index) => (
            <div key={index} className="py-3">
              <FoodOptions
                id={item?.id}
                title={item?.title}
                select={item?.select}
                toggle={item?.toggle}
                plusMinus={item?.plusMinus}
                checkbox={item?.checkbox}
                onToggleChange={addSelection}
                onRadioChange={addSelection}
                min={item?.min}
                max={item?.max}
              />
            </div>
          ))}

          <FoodOptions
            title="Preferences"
            status="(Optional)"
            price={<IoIosArrowForward />}
            text="Add special instructions"
            onToggleChange={addSelection}
            onRadioChange={addSelection}
            Preferences={true}
          />
        </div>
      </div>
    </>
  );
  return (
    <Popup
      data={data}
      cartItem={cartItem}
      heading={data.name}
      onRequestClose={onRequestClose}
      addToCart={addToCart}
      content={content}
    />
  );
};

export default AddFood;
