import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { getMenusForCustomer } from "../../Functions/APIs";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Navbar from "../../Components/navbar";
import RoundBtn from "../../Components/Button/roundBtn";
import { FaRegUser } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import RestaurantDetails from "../../Components/details";
import Switcher11 from "../../Components/Button/toggleBtn";
import { ASSETS } from "../../Assets/path";
import FeaturedItemCarousel from "../../container/order/featuredItemCarousel";
import PopularItemCarousel from "../../container/order/popularItems";
import { GoArrowUpRight } from "react-icons/go";
import { BsChevronDown } from "react-icons/bs";
import { MdOutlinePhone } from "react-icons/md";
import { BiTimeFive } from "react-icons/bi";
import { MEALS_LIST } from "../../utils/constant/index";
import Grid from "@mui/material/Grid";

//import "../../index.css";
//import "../../Assets/css/global.css"
const defaultCart = {
  currentPrice: 0.0,
  items: [],
};

const MenuViewer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState(null);
  const [convertedData, setConvertedData] = useState(null);
  const [shoppingCart, setShoppingCart] = useState(defaultCart);

  useEffect(() => {
    if (!sessionStorage.getItem("isReloaded")) {
      // Set the flag in sessionStorage
      sessionStorage.setItem("isReloaded", true);
    }
    const fetchData = async () => {
      try {
        console.log("location: ", location.state);
        const query = { merchant_id: location.state.merchant_id };
        const response = await getMenusForCustomer(query);
        const body = response.body;
        console.log("response: " + JSON.stringify(response));
        if (body && response.statusCode === "200") {
          setApiData(body); // Filter out null items
          setConvertedData(convertMenuStructure(body));
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (location.state.prevCart) {
      setShoppingCart(location.state.prevCart);
    }
    fetchData();
  }, []);
  const addToCart = (item) => {
    setShoppingCart((prevCart) => ({
      ...prevCart,
      currentPrice:
        parseFloat(prevCart.currentPrice) +
        parseFloat(item.currentPrice * item.quantity),
      items: [...prevCart.items, item],
    }));
  };
  const onViewCartClick = () => {
    navigate("/shopping-cart", {
      state: { ...location.state, initCart: shoppingCart },
    });
  };
  const convertMenuStructure = (originalData) => {
    const newStructure = {
      category: originalData.restaurantName, // Assuming restaurantName as category
      image: originalData.bannerImage, // Using bannerImage as the main image
      name: originalData.restaurantName,
      distance: "Placeholder for distance", // Distance not available in original data
      time: "Placeholder for time", // Time not available in original data
      rating: originalData.rating,
      rating2: `(${originalData.numRatings} ratings)`,
      logo: originalData.iconImage, // Using iconImage as logo
      desc: originalData.description,
      menu: [],
      dishes: [],
    };

    originalData.menu.categories.forEach((category) => {
      // Add category to the menu
      newStructure.menu.push({
        id: category.id,
        text: category.categoryName,
      });

      // Add dishes within this category
      category.items.forEach((item) => {
        newStructure.dishes.push({
          id: category.id,
          menu: category.categoryName,
          img: item.img,
          name: item.name,
          desc: item.description,
          price: item.price,
          requiredSelection: item.requiredSelection,
          optionalSelection: item.optionalSelection,
        });
      });
    });

    return newStructure;
  };

  console.log("Shopping cart: " + JSON.stringify(shoppingCart));
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    function splitAddress(address) {
      const parts = address.split(",", 2); // Split into two parts
      return {
        add1: parts[0],
        add2: parts.slice(1).join(","),
      };
    }
    const { add1, add2 } = splitAddress(apiData.addressStr);
    const IconText = [
      {
        id: 1,
        icon: <IoLocationOutline />,
        text: add1,
        detail: add2,
        arrow: <GoArrowUpRight />,
      },
      {
        id: 2,
        icon: <BiTimeFive />,
        text: "Open",
        detail: "Accepting DoorDash orders all day",
        arrow: <BsChevronDown />,
      },
      {
        id: 2,
        icon: <MdOutlinePhone />,
        text: apiData.phone_number,
        detail: "",
        arrow: <GoArrowUpRight />,
      },
    ];

    return (
      <div>
        <h1>done loading</h1>
        <Navbar order={true} />
        {/* address field  */}
        <div className="pt-16 xs:pt-20 items-center justify-center flex xs:px-2 md:px-5 lg:px-4 xl:px-0">
          <div className="bg-[#494949] xs:rounded-xl w-[55rem] md:w-[72rem] lg:w-[63rem] 2xl:w-[72rem] p-3 sm:p-5">
            <div className="flex justify-between mb-2">
              <span className="text-sm text-white font-semibold">
                Get it delivered to your door
              </span>
              <div className="hidden md:flex">
                <RoundBtn
                  text="Sign in for saved address"
                  bgcolor="#E7E7E7"
                  bgHover="#C4C4C4"
                  icon={
                    <FaRegUser
                      className="mr-2 text-sm font-bold"
                      fill="black"
                    />
                  }
                />
              </div>
            </div>
            <div className="text-left w-full border dark:border-none flex items-center rounded-lg bg-white p-2 mb-1">
              <span className="text-xl sm:text-2xl ml-2">
                <IoLocationOutline />
              </span>
              <input
                className="text-md w-full ml-2 border-none font-semibold outline-none placeholder:text-gray-text bg-transparent"
                placeholder="Enter Your Address"
              />
            </div>
            <span className="text-sm text-[#767676] font-normal line-clamp-none">
              Use your exact address to find all available stores and their
              delivery times
            </span>
          </div>
        </div>

        {/* image and logo  */}
        <div className="flex items-center justify-center xs:px-2 md:px-5 lg:px-4 xl:px-0 xs:pt-5">
          <div className="relative">
            <img
              src={apiData?.bannerImage}
              alt=""
              className="sm:rounded-xl rounded-sm object-cover md:px-0 h-[250px] w-[55rem] md:w-[72rem] lg:w-[63rem] 2xl:w-[72rem] md:h-64"
            />
            <img
              src={apiData?.iconImage}
              alt=""
              className="absolute left-5 -bottom-12 shadow-lg mb-2 rounded-full w-20 h-20 border-2 border-white"
            />
          </div>
        </div>
        {/* details and maps  */}
        <div className="pt-10 flex flex-col justify-center items-center">
          <div className="flex md:flex-row flex-col space-y-2 justify-between w-full px-2 md:px-3 xl:px-0 xl:w-9/12">
            {/* details  */}
            <RestaurantDetails
              name={apiData?.restaurantName}
              shop={"Restaurant"}
              rating={apiData?.rating}
              rating2={apiData?.numRatings}
              desc={""}
              distance={location.state?.distance}
            />

            {/* side div with buttons  */}
            <div className="p-2 md:p-0 space-y-3 xs:w-7/12 sm:w-full md:w-1/2 lg:w-auto">
              {/* toggle button  */}
              <div className="flex space-x-2 items-center">
                <Switcher11
                  text1="Delivery"
                  text2="Pickup"
                  width={100}
                  time1={apiData?.time}
                  time2={apiData?.time}
                />
                <div>
                  <div className="sm:flex hidden">
                    <RoundBtn
                      text="Group Order"
                      bgcolor="#E7E7E7"
                      icon={<img src={ASSETS.GROUP} alt="" className="mr-2" />}
                      width={130}
                      height={40}
                    />
                  </div>
                  <div className="rounded-full bg-[#E7E7E7] w-9 h-9 p-2 items-center sm:hidden flex">
                    <img src={ASSETS.USER} alt="" className="mr-2 " />
                  </div>
                </div>
              </div>
              {/* distance delivery time  */}
              <div className="grid grid-cols-2 grid-flow-col sm:w-1/2 lg:w-96 md:w-full border-2 rounded-lg border-[#E7E7E7] py-1 px-1 md:px-3 mt-2">
                <div className="flex">
                  <div className="bg-[#D7F5F5] flex flex-col rounded-lg py-1 lg:px-2 mr-1">
                    <span className="text-base text-center font-bold text-[#00838A]">
                      $0.00
                    </span>
                    <span className="text-sm text-center">
                      delivery fee, first order
                    </span>
                  </div>
                  <span className="border-[#E7E7E7] border-2 h-8 text-center mt-2"></span>
                </div>
                <div className="flex flex-col rounded-lg py-1">
                  <span className="text-base text-center font-bold">
                    16 min
                  </span>
                  <span className="text-sm text-center">delivery time</span>
                </div>
              </div>
            </div>
          </div>
          {/* map image  */}
          <div className="mt-6 flex flex-col mx-3 sm:flex-row border-2 rounded-lg border-[#E7E7E7] justify-start xs:w-1/2 sm:w-full xl:w-9/12">
            <img
              src="https://th.bing.com/th/id/OIP.3fPg0sTwYaEzYezsUxCNEwHaGm?pid=ImgDet&w=1536&h=1370&rs=1"
              alt=""
              className="lg:w-full lg:h-52 xl:h-52 sm:w-96 sm:h-52 md:w-1/2 md:h-56 rounded-lg xl:w-1/2"
            />
            <div className="flex flex-col md:w-1/2">
              {IconText?.map((item, index) => (
                <div key={index}>
                  <div className="flex justify-between space-x-2 p-3">
                    <div className="flex space-x-2">
                      <span className="mt-1 text-xl">{item.icon}</span>{" "}
                      <div className="flex flex-col">
                        <span className="text sm font-semibold">
                          {item.text}
                        </span>
                        <span className="text-xs">{item.detail}</span>
                      </div>
                    </div>
                    <span className="ml-10 text-lg">{item.arrow}</span>
                  </div>
                  <hr className={`text-[#E7E7E7] ml-10 p-1 mr-2`}></hr>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="pt-10 pb-5 px-4 xl:px-0 w-full xl:w-9/12">
            {/*<FeaturedItemCarousel heading="Featured Items" data={apiData} />*/}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <hr className="py-2 w-11/12 xl:w-9/12 text-[#E7E7E7]"></hr>
        </div>

        <div className="flex items-center justify-center">
          <div className="pt-10 pb-5 px-2 md:px-5 xl:px-0 w-full xl:w-9/12">
            {/*<PopularItemCarousel data={apiData} />*/}
            <PopularItemCarousel data={convertedData} addToCart={addToCart} />
          </div>
        </div>
        {shoppingCart.currentPrice > 0 && (
          <button onClick={onViewCartClick}>
            <CheckoutBar
              itemCount={shoppingCart.items.reduce(
                (total, item) => total + item.quantity,
                0
              )}
            />
          </button>
        )}
      </div>
    );
  }
};
const CheckoutBar = ({ itemCount }) => {
  const isMobile = window.innerWidth <= 500; // or use a more complex logic or a library like react-responsive

  if (!isMobile) {
    //return null;
  }

  return (
    <Grid
      container
      style={{
        position: "fixed",
        left: "50%", // Set to 50% of the viewport
        bottom: "15px",
        width: "80%",
        transform: "translateX(-50%)",
        backgroundColor: "#ff0000",
        color: "white",
        padding: "8px 0", // Adjust vertical padding to make the bar thinner
        borderRadius: "15px",
        borderTopRightRadius: "15px",
        justifyContent: "space-between", // This will align the children to the edges
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "bold",
        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 1000, // Ensure it's above other content
      }}
    >
      <Grid item xs={2} style={{ textAlign: "left", paddingLeft: "10px" }}>
        <ShoppingCartIcon />
      </Grid>
      <Grid item xs={8} style={{ textAlign: "center" }}>
        View Cart
      </Grid>
      <Grid item xs={2} style={{ textAlign: "right", paddingRight: "10px" }}>
        <span
          style={{
            backgroundColor: "white",
            color: "#ff0000",
            borderRadius: "50%",
            padding: "2px 6px",
            fontSize: "12px",
            minWidth: "20px",
            textAlign: "center",
          }}
        >
          {itemCount}
        </span>
      </Grid>
    </Grid>
  );
};

export default MenuViewer;
